import { Component, OnInit } from '@angular/core';
import { ServerService } from '../services/server.service';
import * as Feather from 'feather-icons';
import Swal from 'sweetalert2'
declare var iziToast:any;
declare var $:any;
import { Router,ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-paysuccess',
  templateUrl: './paysuccess.component.html',
  styleUrls: ['./paysuccess.component.css']
})
export class PaysuccessComponent implements OnInit {
param1: string;
param2: string;
param3: string;
invoice_no;
show_success = false;
show_failed = false;
  constructor(private route:ActivatedRoute,private router:Router,private serverService: ServerService) {

 this.route.queryParams.subscribe(params => {
        this.param1 = params['session_id'];
        this.param2 = params['PayerID'];
        this.param3 = params['response'];

        if(this.param2 == undefined || this.param3 == undefined || this.param3 == null || this.param2 == null){
          this.show_success = false;
          this.show_failed = true;
        }else{
          this.show_success = true;
          this.show_failed = false;
        }

        console.log(this.param1)
        console.log(this.param2)
        this.transactionsuccess(this.param3,this.param2)
    });

   }

  ngOnInit(): void {
    // var myRequest = new Request('');

    // var myHeaders = new Headers();
    // myHeaders.append('Content-Type', 'image/jpeg');
    
    // var myInit = { method: 'GET',
    //                headers: myHeaders,
    //                mode: 'cors',
    //                cache: 'default' };
    
    // var requests = new Request('/',myInit);


    // const myRequest = new Request('/', {method: 'POST', body: '{"foo": "bar"}'});
    // const mygetRequest = new Request('/', {method: 'GET'});
    // fetch(myRequest).then(function(response) {
    //   return response.blob();
    // }).then(function(response) {
    //   var objectURL = URL.createObjectURL(response);
    //   console.log(response);
    //   console.log(objectURL);
    //   // myImage.src = objectURL;
    // });
    
  //   var self =this;
  //   fetch(myRequest).then(response => {
  //     console.log('post');
  //   console.log(response);
  //   if (response.status === 200) {
  //     // self.OcbcTransactionSuccess(response)
  //     // return response.json();
  //   } else {
  //     throw new Error('Something went wrong on api server!');
  //   }
  // })
  // .then(response => {
  //   console.debug(response);
  //   // ...
  // }).catch(error => {
  //   console.error(error);
  // });

  //   fetch(mygetRequest).then(response => {
  //   console.log('get');
  //   console.log(response);
  //   if (response.status === 200) {
  //     // self.OcbcTransactionSuccess(response)
  //     // return response.json();
  //   } else {
  //     throw new Error('Something went wrong on api server!');
  //   }
  // })
  // .then(response => {
  //   console.debug(response);
  //   // ...
  // }).catch(error => {
  //   console.error(error);
  // });
  }


transactionsuccess(val,payerid){
  
Swal.fire('Please wait')
Swal.showLoading()

  let datas:any =new Object();
 let datasend:any = new Object();
  let access_token: any=localStorage.getItem('at');
  let customer_id: any=localStorage.getItem('en');
   
   
datas.action="transaction_success";
datas.response = val;
datas.payerids = payerid;
// datas.currency_code = this.datalist.Customer_data.currency_name;
// datas.payment_from ="portal_license_key";

 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="payment";
  datasend.api_type="web";
  datasend.element_data = datas;
  
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){
            Swal.close();
         this.invoice_no = response.invoice_no;

          // response.payment_order
          // response.billing_id
          // response.action
        //  $post_data = json_encode(array("api_user"=>$api_user, "api_pass"=>$api_pass, "access"=>"cms_site", "page_access"=>"cms_form_data","action_info"=>"angular_license_order", "form_data"=>$form_data));



      //   const forms = new FormData();

      //   forms.append('payment_order', 'yes');
      //   forms.append('billing_id', response.invoice_no);
      //   forms.append('action','license_key_generate_angular');
      //   forms.append('action_info','angular_license_order_purchase');
      //   var self = this;
      //   $.ajax({
      //     // url: "https://dev.cal4care.com/erp/data-services-check/index.php",
      //     url: "https://erp.cal4care.com/cms_new/api_cms/v1.0/index.php",
      //     type: 'POST',
      //     data: forms,
      //     processData: false,
      //     contentType: false,
      //     success: function (data) {
      //         console.log(data);

      //     }
      // });

      // ================ callin license key api =======================



    let licdatas:any =new Object();
    let licdatasend:any = new Object();
    licdatas.action="angular_license_order_purchase";
    licdatas.billing_id = response.bill_id;
    licdatas.payment_order = 'yes';
  // datas.currency_code = this.datalist.Customer_data.currency_name;
  // datas.payment_from ="portal_license_key";

    licdatasend.access_token=access_token;
    licdatasend.operation="curlDatas";
    licdatasend.moduleType="payment";
    licdatasend.api_type="web";
    licdatasend.element_data = licdatas;
      
      this.serverService.sendServer(licdatasend).subscribe((responses: any) => {

        console.log(responses);
           
      }, 
      (error)=>{
          console.log(error);
      });
    
          }
        else{
          Swal.close();

 
      }

      
      }, 
      (error)=>{
          console.log(error);
      });
}



OcbcTransactionSuccess(val){
  
  Swal.fire('Please wait')
  Swal.showLoading()
  
    let datas:any =new Object();
   let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
     
     
  datas.action="transaction_success_ocbc";
  datas.response = val;
  // datas.payerids = payerid;

  
   datasend.access_token=access_token;
    datasend.operation="curlDatas";
    datasend.moduleType="payment";
    datasend.api_type="web";
    datasend.element_data = datas;
    
        this.serverService.sendServer(datasend).subscribe((response: any) => {
  console.log(response)
          if(response.status=="true"){
              Swal.close();
           this.invoice_no = response.invoice_no;
  
  
      let licdatas:any =new Object();
      let licdatasend:any = new Object();
      licdatas.action="angular_license_order_purchase";
      licdatas.billing_id = response.bill_id;
      licdatas.payment_order = 'yes';
  
      licdatasend.access_token=access_token;
      licdatasend.operation="curlDatas";
      licdatasend.moduleType="payment";
      licdatasend.api_type="web";
      licdatasend.element_data = licdatas;
        
        this.serverService.sendServer(licdatasend).subscribe((responses: any) => {
  
          console.log(responses);
             
        }, 
        (error)=>{
            console.log(error);
        });
      
            }
          else{
            Swal.close();
  
   
        }
  
        
        }, 
        (error)=>{
            console.log(error);
        });
  }
  


}
