import { Component, OnInit } from '@angular/core';
import { ServerService } from '../services/server.service';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { interval, Subscription } from 'rxjs';
declare var iziToast: any;
import * as Feather from 'feather-icons';
declare var $: any;
import Swal from 'sweetalert2';

@Component({
  selector: 'app-profile-details',
  templateUrl: './profile-details.component.html',
  styleUrls: ['./profile-details.component.css']
})
export class ProfileDetailsComponent implements OnInit {
  getdata: FormGroup;
  profile_details: any = {};

  qrcode;
  // title = 'angular-qr-code-tutorial';
  subscription: Subscription;
  public qrdata: string;
  public level;
  public width: number;
  datas: any;
  constructor(private router: Router,public route: ActivatedRoute, private serverService: ServerService) { 
    this.route.queryParams.subscribe(params => {
      console.log(params);
      var id = atob(params.id);
      if(id=="login"){
        // this.router.navigate(['/profile-details']);
        // window.location.reload();
      }
      // alert(id)
      })
  }

  ngOnInit(): void {
    this.getdata = new FormGroup({
      'company_name': new FormControl(null, Validators.required),
      'phone_no': new FormControl(null),
      'google_Authenticator': new FormControl(null),
      'address1': new FormControl(null, Validators.required),
      'address2': new FormControl(null),
      'city': new FormControl(null),
      'state': new FormControl(null),
      'country': new FormControl(null),
      'phone_no2': new FormControl(null),
      'mobilePhone': new FormControl(null),
      'fax': new FormControl(null),
      'contact_person': new FormControl(null),
      'bank_account_name': new FormControl(null),
      'bank_account_no': new FormControl(null),
      'reset_password': new FormControl(null),
      'credit_limit': new FormControl(null),
      'bill_code': new FormControl(null),
      'cms_username_dispaly': new FormControl(null),
      'cms_vs_pbx_dispaly': new FormControl(null)


    });
    
    // this.aatrox_dashboard();
    this.getuser_profile()
    this.subscribes()
  }

  unsubscribe() {
    this.subscription.unsubscribe();
  }
  subscribes() {
    this.qrcodes()
    const source = interval(300000);
    this.subscription = source.subscribe(val =>
      this.qrcodes()
    );
  }

  ngAfterViewInit() {
    Feather.replace();
  }

  localUrl;
  file: File;
  showPreviewImage(event: any) {
    this.file = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.localUrl = event.target.result;
      }
      reader.readAsDataURL(event.target.files[0]);
    }
    this.uploadprofile()
  }



  qrcodes() {
    let customer_id: any = localStorage.getItem('en');

    this.level = "M"
    this.width = 128;
    var types = btoa('cms')
    const data = [{
      'type': types,
      'en': customer_id,
    }]

    this.qrdata = JSON.stringify(data);
    console.log(this.qrdata)
    var hh = JSON.parse(this.qrdata)
    console.log(hh)
  }

  getuser_profile() {

    let datas: any = new Object();
    let datasend: any = new Object();
    let access_token: any = localStorage.getItem('at');
    let customer_id: any = localStorage.getItem('en');


    datas.action = "get_user_profile";
    datas.customer_id = customer_id;



    datasend.access_token = access_token;
    datasend.operation = "curlDatas";
    datasend.moduleType = "login";
    datasend.api_type = "web";
    datasend.element_data = datas;
    this.serverService.sendServer(datasend).subscribe((response: any) => {
      console.log(response)
      if (response.status == "true") {
        window.location.reload();
        this.profile_details = response

        this.getdata.patchValue({

          company_name: this.profile_details.company_name,
          phone_no: "",
          google_Authenticator: "",
          address1: this.profile_details.address1,
          address2: this.profile_details.address2,
          city: this.profile_details.city,
          state: this.profile_details.state,
          country: this.profile_details.country,
          phone_no2: this.profile_details.phone_no,
          mobilePhone: this.profile_details.mobilePhone,
          fax: this.profile_details.fax,
          contact_person: this.profile_details.contact_person,
          bank_account_name: this.profile_details.bank_account_name,
          bank_account_no: this.profile_details.bank_account_no,
          reset_password: "",
          credit_limit: this.profile_details.credit_limit,
          bill_code: "",
          cms_username_dispaly: this.profile_details.cms_username_dispaly,
          cms_vs_pbx_dispaly: this.profile_details.cms_vs_pbx_dispaly

        });

        this.localUrl = this.profile_details.profile_image_url;

      }





    },
      (error) => {
        console.log(error);
      });

  }


  // profileupdate(datas){

  //   let data:any =new Object();
  //  let datasend:any = new Object();
  //     let access_token: any=localStorage.getItem('at');
  //     let customer_id: any=localStorage.getItem('en');


  // data.action="update_user_profile";
  // data.email_id = "";
  // data.finance_email_id = customer_id;
  // data.customer_id = customer_id;
  // data.customerCode = customer_id;
  // data.customerName = customer_id;
  // data.customerAddress1 = customer_id;
  // data.customerAddress2 = customer_id;
  // data.city = customer_id;
  // data.state = customer_id;
  // data.zipCode = customer_id;
  // data.country = customer_id;
  // data.mobilePhone = customer_id;
  // data.customerPhone = customer_id;
  // data.fax = customer_id;
  // data.cms_username_dispaly = customer_id;
  // data.cms_vs_pbx_dispaly = customer_id;
  // data.contactperson = "";



  //     this.getdata.patchValue({

  //   company_name : this.profile_details.company_name,
  //       phone_no : "",
  //       google_Authenticator :"",
  //       address1 : this.profile_details.address1,
  //         address2:this.profile_details.address2,
  //         city:this.profile_details.city,
  //         state:this.profile_details.state,
  //         country:this.profile_details.country,
  //         phone_no2:this.profile_details.phone_no,
  //         mobilePhone:this.profile_details.mobilePhone ,
  //         fax:this.profile_details.fax,
  //         contact_person:this.profile_details.contact_person,
  //         bank_account_name:this.profile_details.bank_account_name,
  //         bank_account_no:this.profile_details.bank_account_no,
  //         reset_password:"",
  //         credit_limit:this.profile_details.credit_limit,
  //         bill_code:"",
  //         cms_username_dispaly:this.profile_details.cms_username_dispaly,
  //         cms_vs_pbx_dispaly:this.profile_details.cms_vs_pbx_dispaly

  // });




  //  datasend.access_token=access_token;
  //   datasend.operation="curlDatas";
  //   datasend.moduleType="login";
  //   datasend.api_type="web";
  //   datasend.element_data = data;
  //       this.serverService.sendServer(datasend).subscribe((response: any) => {
  // console.log(response)
  //         if(response.status=="true"){


  //           }
  //         else{


  //         }




  //       }, 
  //       (error)=>{
  //           console.log(error);
  //       });

  // }
  validateAllFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFields(control);
      }
    });
  }


  finishFunction() {

    if (this.getdata.valid) {

      this.profileupdate()
    }
    else {

      this.validateAllFields(this.getdata);

    }

  }


  popupSweetalert(){

    Swal.fire({
      icon: 'info',
      title: 'Waiting For Approval',
      text: "In case if you need urgent Amendments please contact cms@cal4care.com",
    })
  }

  profileupdate() {

    let data: any = new Object();
    let datasend: any = new Object();
    let access_token: any = localStorage.getItem('at');
    let customer_id: any = localStorage.getItem('en');




    // this.getdata = new FormGroup({
    //     'company_name' : new FormControl(null,Validators.required),
    //     'phone_no' : new FormControl(null),
    //     'google_Authenticator' : new FormControl(null,Validators.required),
    //     'address1' : new FormControl(null,Validators.required),
    //       'address2':new FormControl(null,Validators.required),
    //       'city':new FormControl(null,Validators.required),
    //       'state':new FormControl(null),
    //       'country':new FormControl(null),
    //       'phone_no2':new FormControl(null),
    //       'mobilePhone':new FormControl(null),
    //       'fax':new FormControl(null),
    //       'contact_person':new FormControl(null),
    //       'bank_account_name':new FormControl(null),
    //       'bank_account_no':new FormControl(null),
    //       'reset_password':new FormControl(null),
    //       'credit_limit':new FormControl(null),
    //       'bill_code':new FormControl(null),
    //       'cms_username_dispaly':new FormControl(null),
    //       'cms_vs_pbx_dispaly':new FormControl(null)

    // var s = this;

    const forms = new FormData();
    forms.append('email_id', this.profile_details.email_id);
    forms.append('finance_email_id', this.profile_details.finance_email_id);
    forms.append('customer_id', this.profile_details.customer_id);
    forms.append('customerCode', this.profile_details.customerCode);
    forms.append('customerName', this.getdata.value.company_name);
    forms.append('customerAddress1', this.getdata.value.address1);
    forms.append('customerAddress2', this.getdata.value.address2);
    forms.append('city', this.getdata.value.city);
    forms.append('state', this.getdata.value.state);
    forms.append('country', this.getdata.value.country);
    forms.append('customerPhone', this.getdata.value.phone_no2);
    forms.append('mobilePhone', this.getdata.value.mobilePhone);
    forms.append('fax', this.getdata.value.fax);
    forms.append('contact_person', this.getdata.value.contact_person);
    forms.append('companyName', this.getdata.value.company_name);
    forms.append('credit_amt', this.getdata.value.credit_limit);
    forms.append('reset_password', this.getdata.value.reset_password);



    forms.append('action', 'update_user_profile');
    forms.append('operation', 'curlData');
    forms.append('access_token', access_token);
    forms.append('moduleType', 'login');
    forms.append('api_type', 'web');
    console.log(forms);
    var self = this;
    $.ajax({
      url: "https://erp.cal4care.com/cms_new/api_cms/v1.0/index_new.php",
      type: 'POST',
      data: forms,
      processData: false,  // tell jQuery not to process the data
      contentType: false,
      success: function (data) {
        console.log(data);
        var data = JSON.parse(data);
        if (data.status == "true") {
          // iziToast.success({
          //   message: "Please wait while approval",
          //   position: 'topRight'
          // });
          self.popupSweetalert();

        } else {
          iziToast.error({
            message: "Please Try Again Later",
            position: 'topRight'
          });
        }
        //  
      }
    });
  }


  uploadprofile() {


    var self = this;
    let access_token: any = localStorage.getItem('at');
    let customer_id: any = localStorage.getItem('en');
    const forms = new FormData();


    if (this.file != undefined) {
      forms.append('attachment', this.file);

    } else {
      forms.append('attachment', '');

    }


    forms.append('customer_id', atob(customer_id));
    forms.append('action', 'update_profile_image');
    forms.append('operation', 'curlData');
    forms.append('access_token', access_token);
    forms.append('moduleType', 'profile');
    forms.append('api_type', 'web');

    $.ajax({
      url: "https://erp.cal4care.com/cms_new/api_cms/v1.0/index_new.php",
      type: 'POST',
      data: forms,
      processData: false,  // tell jQuery not to process the data
      contentType: false,
      success: function (data) {
        console.log(data);
        //return false;
        self.countryflag();
        //  iziToast.success({
        //          message: "Ticket Created Sucessfully",
        //          position: 'topRight'
        //      });
      }
    });

  }

  countryflag() {

    let data: any = new Object();
    let datasend: any = new Object();
    let access_token: any = localStorage.getItem('at');
    let customer_id: any = localStorage.getItem('en');


    data.action = "contact_flag";
    data.customer_id = customer_id;
    data.customer_state = localStorage.getItem('customer_state');
    data.cms_user_id = localStorage.getItem('cms_user_id');

    datasend.access_token = access_token;
    datasend.operation = "curlDatas";
    datasend.moduleType = "login";
    datasend.api_type = "web";

    datasend.element_data = data;
    this.serverService.sendServer(datasend).subscribe((response: any) => {
      console.log(response)
      if (response.status == "true") {
        localStorage.setItem('profile_image', response.customer_details.profile_image);
        window.location.reload();
      }
      else {

      }
    },
      (error) => {
        console.log(error);
      });

  }








}
