import { Component, OnInit } from '@angular/core';
import { ServerService } from '../services/server.service';
import { Router } from '@angular/router';
import * as Feather from 'feather-icons';
@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {
 notifydata:any=[];
  constructor(private router:Router,private serverService: ServerService) { }

  ngOnInit(): void {
    this.notificationlist()
  }


notificationlist(){
    // var list_data= this.listDataInfo(data);

// console.log(this.serverService.ci)
  let datas:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
   
datas.action="notification_list";
datas.customer_id = customer_id;
// datas.limit = list_data.limit;
// datas.offset = list_data.offset;
// datas.search_text = list_data.search_text;


 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="notification";
  datasend.api_type="web";
  datasend.element_data = datas;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){
     this.notifydata = response.list;
   for (var i = 0; i < this.notifydata.length; i++) {
      if(this.notifydata[i].type == "referral")
        this.notifydata[i].image = "assets/img/notification/referral.png"
      if(this.notifydata[i].type == "payment_add")
        this.notifydata[i].image ="assets/img/notification/payment_add.png"
      if(this.notifydata[i].type == "renewal")
        this.notifydata[i].image ="assets/img/notification/renewal.png"
      if(this.notifydata[i].type == "invoice")
        this.notifydata[i].image ="assets/img/notification/invoice.png"
      if(this.notifydata[i].type == "payment_made")
        this.notifydata[i].image ="assets/img/notification/payment_made.png"
      if(this.notifydata[i].type == "support")
        this.notifydata[i].image ="assets/img/notification/support.png"
   }



          }
        else{
this.notifydata =[];
 
        }
   // this.offset_count = list_data.offset;
   //      this.paginationData = this.serverService.pagination({'offset':response.Offset, 'total':response.Total, 'page_limit' :this.pageLimit });
   //       console.log(this.paginationData);
   //              this.recordNotFound = this.gccdata.length == 0 ? true : false;


      
      }, 
      (error)=>{
          console.log(error);
      });

}




}
