import { Component, OnInit } from '@angular/core';
import { ServerService } from '../services/server.service';
import { Router } from '@angular/router';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
declare var iziToast: any;
declare var $:any;
import Swal from 'sweetalert2'
import * as Feather from 'feather-icons';
@Component({
  selector: 'app-buy3cx',
  templateUrl: './buy3cx.component.html',
  styleUrls: ['./buy3cx.component.css']
})
export class Buy3cxComponent implements OnInit {
  edition: any = [];
  getdata: FormGroup;
  getdataupgrademaintan: FormGroup;
  getmaintan: FormGroup;
  c3x_data: any = {};
  cartdata: any = {};
  customer_data: any = {};
  concur_license: any = [];
  message = '';
  upgradeshow = false;
  upgradeedition: any = [];
  upgrade_call: any = [];
  system_discount_3cx;
  reseller_dis_per;
  maintenance_data;
  maintananceshow = false;
  errormessage: any;
  price_without_hosting: any;
  insertAnnualCart: any;
  hosting_price: any;
  includehost_check = false;
  includehost_check_upgrade = false;
  readonlyBox = false;
  showhost = false;
  showeditionUpgrade = false;
  include_val:boolean = false;
  disply_maintenance_error = false;
  contact_details;
  constructor(private router: Router, private serverService: ServerService) { }

  ngOnInit(): void {


    setTimeout(() => {

      if (this.serverService.cxbuy == 0) {

        this.router.navigate(['/dashboard']);
      }
    }, 1000);

    this.onlineorder({})
    this.customers_data()
    this.getdata = new FormGroup({
      'category': new FormControl(null, Validators.required),
      'editionkey': new FormControl(null, Validators.required),
      'additional_years': new FormControl(null, Validators.required),
      'conc_license': new FormControl(null, Validators.required),
      'qty': new FormControl(null, Validators.required),
      'maintenance': new FormControl(null, Validators.required)

      //  'li_key' : new FormControl(null),
      // 'conc_license_upgrade':new FormControl(null),
      // 'editionkeyupgrade':new FormControl(null)


    });
    this.getdataupgrademaintan = new FormGroup({


      'li_key': new FormControl(null, Validators.required),
      'conc_license_upgrade': new FormControl(null, Validators.required),
      'editionkeyupgrade': new FormControl(null, Validators.required),
      // 'year': new FormControl(null, Validators.required),
      // 'additional_years': new FormControl(null, Validators.required)

    });

    this.getmaintan = new FormGroup({


      'li_key': new FormControl(null, Validators.required),
      'year': new FormControl(null, Validators.required),
      'maintance_years': new FormControl(null, Validators.required),


    });




    this.getdata.patchValue({
      category: '',
      editionkey: '',
      conc_license: '',
      additional_years: '',
      // conc_license_upgrade:'',
      qty: 1,
      maintenance: 0,

    });
    this.getdataupgrademaintan.patchValue({
      li_key: '',
      conc_license_upgrade: '',
      editionkeyupgrade: '',
      // year: '1'

    });

    this.getmaintan.patchValue({
      li_key: '',
      year: '1',
      maintance_years: '1',

    });


  }


  ngAfterViewInit() {
    Feather.replace();
  }

  cart(val) {
    console.log(this.getdata.value)
    console.log(this.getdataupgrademaintan.value)
console.log(val);
    if (val == "insert") {
      if (this.getdata.value.category == "" || this.getdata.value.category == "new") {
        if (this.getdata.valid) {
          this.getcart()
        } else {
          this.validateAllFields(this.getdata);
        }

      }
      else if (this.getdata.value.category == "upgrade") {
        console.log('upgrade test');
        if (this.getdataupgrademaintan.valid) {
          this.getcart()
        } else {
          this.validateAllFields(this.getdataupgrademaintan);
        }
      }
      else if (this.getdata.value.category == "maintenance") {
        if (this.getmaintan.valid) {
          this.getcart()
        } else {
          this.validateAllFields(this.getmaintan);
        }
      }



    } else {

      this.getcarts()
      // this.router.navigate(['/shopping-cart']);

    }
  }

  validateAllFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFields(control);
      }
    });
  }


  onlineorder(data) {
    let datas: any = new Object();
    let datasend: any = new Object();
    let access_token: any = localStorage.getItem('at');
    let customer_id: any = localStorage.getItem('en');


    datas.action = "online_order";
    datas.customer_id = customer_id;
    datas.product_sale_type = "new";

    datasend.access_token = access_token;
    datasend.operation = "curlDatas";
    datasend.moduleType = "login";
    datasend.api_type = "web";
    datasend.element_data = datas;
    this.serverService.sendServer(datasend).subscribe((response: any) => {
      console.log(response)
      if (response.status == "true") {
        this.edition = response.Edition;
        this.c3x_data = response.Customer_3cx_data;
        this.customer_data = response.Customer_data;

      }
      else {
        this.edition = [];
        this.c3x_data = {};
      }



    },
      (error) => {
        console.log(error);
      });

  }


  yearschange() {

    this.editionDataAPI();
  }

  editionchange() {

    console.log(this.getdata.value.editionkey);
    if (this.getdata.value.editionkey !== '3CXPSPROFENT') {

      this.getdata.patchValue({
        'additional_years': '0'
      });
      this.showhost = true;
      // this.includehost_check = true;
      // this.readonlyBox = true;
    } else {
      this.getdata.patchValue({
        'additional_years': ''
      });
      this.includehost_check = false;
      // this.showhost = false;
      // this.readonlyBox = false;
    }

    this.editionDataAPI();

  }

  hostEditionChange() {
    this.editionDataAPI();
  }
  hostEditionChangeUpgrade(evn:boolean) {
    if(evn){
      this.include_val = evn;
    }else{
      this.include_val = evn;
    }
    Swal.fire('Please wait')
    Swal.showLoading()
    let data:any = new Object();
    let data_list:any = new Object();
    let access_token: any = localStorage.getItem('at');
    let customer_id: any = localStorage.getItem('en');
    data.access_token = access_token;
    data.moduleType = "login";
    data.operation = "curlDatas";
    data.api_type = "web";
    data_list.action = "price_information_upgrade";
    data_list.customer_id = customer_id;
    data_list.product_sale_type = "upgrade";
    data_list.license_key = this.getdataupgrademaintan.value.li_key.trim();
    data_list.customer_currency = this.customer_data.currency_name;
    data_list.customer_currency_symbol = this.customer_data.symbol;
    data_list.currency_value = this.customer_data.currency_value;
    data_list.addHosting = ""+this.include_val+"";
    data.element_data = data_list;
    this.serverService.sendServer(data).subscribe((res:any)=>{
      if(res.status == "true"){
        Swal.close();
      }else{
        Swal.close();
      }
    })
    // this.priceinformation_upgrade();
  }
  upgradeyearschange() {
    this.priceinformation_upgrade();
  }


  editionDataAPI() {

    Swal.fire('Please wait')
    Swal.showLoading()
    let datas: any = new Object();
    let datasend: any = new Object();
    let access_token: any = localStorage.getItem('at');
    let customer_id: any = localStorage.getItem('en');

    datas.action = "edition_change";
    datas.customer_id = customer_id;
    datas.edition_key = this.getdata.value.editionkey;
    // datas.api_key = this.c3x_data.Apikey;
    datas.def_biller_id = this.contact_details.def_biller_id;
    // datas.system_discount_3cx = this.contact_details.def_biller_id;
    // datas.reseller_dis_per = this.contact_details.reseller_dis_per;
    datas.reseller_id = this.contact_details.reseller_id;
    datas.customer_currency = this.contact_details.currency;
    datas.customer_currency_symbol = this.contact_details.currency_symbol;
    // datas.currency_value = this.c3x_data.Currency_value;
    datas.system_discount_3cx = this.contact_details.system_discount_3cx;
    datas.reseller_dis_per = this.contact_details.reseller_dis_per;
    if (this.showhost == true) {
      datas.additionalYear = this.getdata.value.additional_years;
      datas.addHosting = String(this.includehost_check);
    }

    datasend.access_token = access_token;
    datasend.operation = "agents";
    datasend.moduleType = "agents";
    datasend.api_type = "web";
    datasend.element_data = datas;
    this.serverService.sendServer(datasend).subscribe((response: any) => {
      console.log(response)
      Swal.close();
      if (response.status == "true") {
        this.concur_license = response.Options

      }
      else {
        Swal.close();
        this.concur_license = [];
      }


    },
      (error) => {
        console.log(error);
      });
  }
  onchangecon_licenc(){
    // if (this.showhost == false) {
    //   Swal.fire('Please wait')
    //   Swal.showLoading()


    //   this.message = '';

    //   var array = this.getdata.value.conc_license.split(',');


    //   let datas: any = new Object();
    //   let datasend: any = new Object();
    //   let access_token: any = localStorage.getItem('at');
    //   let customer_id: any = localStorage.getItem('en');


    //   datas.action = "concurrent_license_change";
    //   datas.customer_id = customer_id;
    //   datas.edition_key = this.getdata.value.editionkey;
    //   datas.api_key = this.c3x_data.Apikey;
    //   datas.reseller_id = this.c3x_data.Resellerid;
    //   datas.customer_currency = this.c3x_data.Customer_currency;
    //   datas.customer_currency_symbol = this.c3x_data.Currency_symbol;
    //   datas.currency_value = this.c3x_data.Currency_value;
    //   datas.simultaneous_call = array[0];

    //   datasend.access_token = access_token;
    //   datasend.operation = "curlDatas";
    //   datasend.moduleType = "login";
    //   datasend.api_type = "web";
    //   datasend.element_data = datas;
    //   this.serverService.sendServer(datasend).subscribe((response: any) => {
    //     console.log(response)
    //     if (response) {
    //       this.cartdata = response;
    //       this.message = response.message;

    //       Swal.close();
    //     }
    //     else {
    //       // this.prepaidnotedata =[];

    //     }
    //   },
    //     (error) => {
    //       console.log(error);
    //     });

    // }else{

      var array = this.getdataupgrademaintan.value.conc_license_upgrade.split(',');
      this.insertAnnualCart = this.upgrade_call.filter((datas)=>{
        return datas.value == array[2];
      })
console.log(this.insertAnnualCart)
    // }


  }

  concurrentlicense_change() {
    if (this.showhost == false) {
      Swal.fire('Please wait')
      Swal.showLoading()


      this.message = '';

      var array = this.getdata.value.conc_license.split(',');


      let datas: any = new Object();
      let datasend: any = new Object();
      let access_token: any = localStorage.getItem('at');
      let customer_id: any = localStorage.getItem('en');


      datas.action = "concurrent_license_change";
      datas.customer_id = customer_id;
      datas.edition_key = this.getdata.value.editionkey;
      datas.api_key = this.c3x_data.Apikey;
      datas.reseller_id = this.c3x_data.Resellerid;
      datas.customer_currency = this.c3x_data.Customer_currency;
      datas.customer_currency_symbol = this.c3x_data.Currency_symbol;
      datas.currency_value = this.c3x_data.Currency_value;
      datas.simultaneous_call = array[0];

      datasend.access_token = access_token;
      datasend.operation = "curlDatas";
      datasend.moduleType = "login";
      datasend.api_type = "web";
      datasend.element_data = datas;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
        console.log(response)
        if (response) {
          this.cartdata = response;
          this.message = response.message;

          Swal.close();
        }
        else {
          // this.prepaidnotedata =[];

        }
      },
        (error) => {
          console.log(error);
        });

    }else{

      var array = this.getdata.value.conc_license.split(',');
      this.insertAnnualCart = this.concur_license.filter((datas)=>{
        return datas.value == array[2];
      })
console.log(this.insertAnnualCart)
    }


  }



  insertcart() {


    console.log(this.getdata.value);

    console.log(this.getdataupgrademaintan.value)


    var array = this.getdata.value.conc_license.split(',');
    console.log(this.getdata.value.conc_license)
    console.log(array[2])

    var arrayupgrade = this.getdataupgrademaintan.value.conc_license_upgrade.split(',');
    console.log(arrayupgrade)

    var editionarrays = this.getdataupgrademaintan.value.editionkeyupgrade.split(',');
    console.log(editionarrays)


    let datas: any = new Object();
    let datasend: any = new Object();
    var hosting_price: any = localStorage.getItem('hosting_price');
    var price_wh: any = localStorage.getItem('price_wh');
    let access_token: any = localStorage.getItem('at');
    let customer_id: any = localStorage.getItem('en');
    if (this.getdata.value.category == 'upgrade') {
      datas.action = "insert_cart";
      datas.customer_id = customer_id;
      datas.product_sale_type = "upgrade";
      datas.quantity = "1";
      datas.price_without_hosting = price_wh;
      datas.simultaneous_call = arrayupgrade[2];
      datas.license_key = this.getdataupgrademaintan.value.li_key.trim();
      // datas.edition_key = this.getdataupgrademaintan.value.editionkeyupgrade;
      datas.edition_key = editionarrays[0];
      // datas.dicountPercentage = array[2];
      datas.dicountPercentage = arrayupgrade[1];
      
      
      if(this.showeditionUpgrade == false){
        console.log('23423')
        datas.maintenance = "0";
        datas.temp_price = arrayupgrade[0];
        datas.actual_price = arrayupgrade[0];
        datas.perpetual = "1";
        datas.edition = editionarrays[1];
        datas.temp_maintenace_price = "0";
        // datas.hosting_price = hosting_price;
        datas.hosting_price = this.insertAnnualCart[0].hosting_price;
      }else{
        console.log('2rsdvsdvsd')
       datas.maintenance = this.getdataupgrademaintan.value.additional_years;
      datas.temp_price = this.insertAnnualCart[0].price_value;
      datas.actual_price = this.insertAnnualCart[0].price_value;
      datas.perpetual = '1';
      datas.edition = this.insertAnnualCart[0].edition;
      datas.temp_maintenace_price = '0';
      datas.hosting_price = this.insertAnnualCart[0].hosting_price;
      datas.hosting_discount = this.insertAnnualCart[0].hosting_discount;
      datas.net_hosting_price = this.insertAnnualCart[0].net_hosting_price;
    } 

console.log(datas)
    }
    else if (this.getdata.value.category == 'maintenance') {
      datas.action = "insert_cart";
      datas.customer_id = customer_id;
      datas.product_sale_type = "maintenance";
      datas.quantity = this.getmaintan.value.maintance_years;
      datas.maintenance = this.getmaintan.value.year;
      datas.simultaneous_call = this.maintenance_data.simultaneousCalls;
      datas.license_key = this.getmaintan.value.li_key.trim();
      datas.edition_key = this.maintenance_data.Edition;
      datas.dicountPercentage = this.maintenance_data.discount;
      datas.temp_price = this.maintenance_data.temp_price;
      datas.actual_price = this.maintenance_data.actual_price;
      datas.perpetual = this.maintenance_data.Perpetual;
      datas.edition = this.maintenance_data.edition;
      datas.temp_maintenace_price = this.maintenance_data.temp_maintenace_price;
      datas.hosting_price = hosting_price;
      datas.price_without_hosting = price_wh;
    }
    else {
      datas.action = "insert_cart";
      datas.customer_id = customer_id;
      datas.product_sale_type = "new";
      datas.quantity = this.getdata.value.qty;
      datas.simultaneous_call = array[2];
      datas.license_key = "";
      datas.edition_key = this.getdata.value.editionkey;
      datas.dicountPercentage = array[2];
      if(this.showhost == false){
        
        datas.maintenance = this.getdata.value.maintenance;
        datas.temp_price = this.cartdata.temp_price;
        datas.actual_price = this.cartdata.actual_price;
        datas.perpetual = this.cartdata.perpetual;
        datas.edition = this.cartdata.edition;
        datas.temp_maintenace_price = this.cartdata.temp_maintenace_price;
        datas.hosting_price = hosting_price;
      }else {
        
        datas.maintenance = this.getdata.value.additional_years;
        datas.temp_price = this.insertAnnualCart[0].price_value;
        datas.actual_price = this.insertAnnualCart[0].price_value;
        datas.perpetual = '0'
        datas.edition = this.insertAnnualCart[0].edition;
        datas.temp_maintenace_price = 'null'
        datas.hosting_price = this.insertAnnualCart[0].hosting_price;
        datas.hosting_discount = this.insertAnnualCart[0].hosting_discount;
        datas.net_hosting_price = this.insertAnnualCart[0].net_hosting_price;

      }
      datas.price_without_hosting = price_wh;
    }
    datasend.access_token = access_token;
    datasend.operation = "agents";
    datasend.moduleType = "agents";
    datasend.api_type = "web";
    datasend.element_data = datas;
    console.log(datasend);


// return false;
    this.serverService.sendServer(datasend).subscribe((response: any) => {
      console.log(response)
      if (response.result.data == 1) {

        if (this.getdata.value.category == 'new') {
          iziToast.success({
            message: "Product Successfully added to your cart",
            position: 'topRight'

          });
          this.router.navigate(['/shopping-cart']);

        } else if (this.getdata.value.category == 'upgrade') {
          iziToast.success({
            message: "Product Upgradation added to your cart",
            position: 'topRight'
          });
          this.router.navigate(['/shopping-cart']);

        } else {
          iziToast.success({
            message: "Product maintenance added to your cart",
            position: 'topRight'
          });
          this.router.navigate(['/shopping-cart']);

        }

      } else {
        iziToast.error({
          message: "Please try again later",
          position: 'topRight'
        });
      }

    },
      (error) => {
        console.log(error);
      });

  }


  priceinformation_upgrade() {

    console.log(this.getdataupgrademaintan.value.li_key.trim());

    if (this.getdata.value.category == "upgrade") {
      if (this.getdataupgrademaintan.value.li_key == "" || this.getdataupgrademaintan.value.li_key == undefined) {
        iziToast.error({
          message: "Please Enter License Key",
          position: 'topRight'

        });
        return false
      }

      Swal.fire('Please wait')
      Swal.showLoading()

      let datas: any = new Object();
      let datasend: any = new Object();
      let access_token: any = localStorage.getItem('at');
      let customer_id: any = localStorage.getItem('en');


      datas.action = "price_information_upgrades";
      datas.customer_id = customer_id;
      datas.product_sale_type = "upgrade";
      datas.license_key = this.getdataupgrademaintan.value.li_key.trim();
      // datas.license_key = this.getdataupgrademaintan.value.li_key.trim();
      datas.customer_currency = this.contact_details.currency;
      datas.customer_currency_symbol = this.contact_details.currency_symbol;
      datas.system_discount_3cx = this.contact_details.system_discount_3cx;
      datas.reseller_dis_per = this.contact_details.reseller_dis_per;
      datas.def_biller_id = this.contact_details.def_biller_id;
      datas.reseller_id = this.contact_details.reseller_id;

      datasend.access_token = access_token;
      datasend.operation = "agents";
      datasend.moduleType = "agents";
      datasend.api_type = "web";
      datasend.element_data = datas;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
        console.log(response)
        if (response.status == "true") {
          Swal.close();
          this.upgradeshow = true;
          this.maintananceshow = false;
          if(response.Simultaneouscall[0].hosting_price == '0.00'){
            this.showeditionUpgrade = true;
          }else{
            this.showeditionUpgrade = false;
          }
          this.upgradeedition = response.Edition;
          this.upgrade_call = response.Simultaneouscall;
          //      this.getdataupgrademaintan.patchValue({
          //   editionkeyupgrade:this.upgradeedition[0].value,

          // });

        }
        else {
          this.errormessage = response.meassage;
          console.log(this.errormessage)
          Swal.close();
          this.upgradeedition = [];
          this.upgrade_call = [];

        }
      },
        (error) => {
          console.log(error);
        });

    } else if (this.getdata.value.category == "maintenance") {

      if (this.getmaintan.value.li_key == "" || this.getmaintan.value.li_key == undefined) {
        iziToast.error({
          message: "Please Enter License Key",
          position: 'topRight'

        });
        return false
      }
      Swal.fire('Please wait')
      Swal.showLoading()
      console.log(this.getmaintan.value.li_key)
      // iziToast.error({
      //        message: "Please Enter License Key",
      //        position: 'topRight'

      //    });
      let datas: any = new Object();
      let datasend: any = new Object();
      let access_token: any = localStorage.getItem('at');
      let customer_id: any = localStorage.getItem('en');


      datas.action = "anual_renew_maintenance";
      datas.customer_id = customer_id;
      datas.product_sale_type = "maintenance";
      datas.license_key = this.getmaintan.value.li_key.trim();
      datas.years = this.getmaintan.value.maintance_years;
      // datas.customer_currency = this.customer_data.currency_name;
      // datas.customer_currency_symbol = this.customer_data.symbol;
      // datas.currency_value = this.customer_data.currency_value;
      datas.customer_currency = this.contact_details.currency;
      datas.customer_currency_symbol = this.contact_details.currency_symbol;
      datas.system_discount_3cx = this.contact_details.system_discount_3cx;
      datas.reseller_dis_per = this.contact_details.reseller_dis_per;
      datas.def_biller_id = this.contact_details.def_biller_id;
      datas.reseller_id = this.contact_details.reseller_id;

      datasend.access_token = access_token;
      datasend.operation = "agents";
      datasend.moduleType = "agents";
      datasend.api_type = "web";
      datasend.element_data = datas;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
        console.log(response)
        if (response.status == "true") {
          Swal.close();
          if($("#product_sale_type").val()=="maintenance"&&response.price_data == 'SGD 0.00'){
            this.disply_maintenance_error = true;
          }else{
            this.disply_maintenance_error = false;
          }
          this.maintenance_data = response
          this.maintananceshow = true;
          this.upgradeshow = false;
          this.price_without_hosting = response.price_without_hosting;
          this.hosting_price = response.hosting_price;
          console.log(this.price_without_hosting.price_data);
          console.log(this.maintenance_data.price_data);
          console.log(this.hosting_price);
          localStorage.setItem('price_wh', this.price_without_hosting);
          localStorage.setItem('hosting_price', this.hosting_price);
          //      this.upgradeedition = response.Edition;
          //      this.upgrade_call = response.Simultaneouscall;
          //      this.getdataupgrademaintan.patchValue({
          //   editionkeyupgrade:this.upgradeedition[0].value,

          // });

        }
        else {
          Swal.close();
          // this.upgradeedition =[];
          // this.upgrade_call =[];

        }
      },
        (error) => {
          console.log(error);
        });
    }



  }

  simultaneouscall_change() {
    this
    var arrays = this.getdata.value.conc_license_upgrade.split(',');

    let datas: any = new Object();
    let datasend: any = new Object();
    let access_token: any = localStorage.getItem('at');
    let customer_id: any = localStorage.getItem('en');


    datas.action = "simultaneous_call_change";
    datas.customer_id = customer_id;
    datas.product_sale_type = "upgrade";
    datas.license_key = this.c3x_data.Apikey.trim();
    datas.edition = this.c3x_data.Resellerid;
    datas.simultaneous_call = this.c3x_data.Customer_currency;
    datas.actual_price = arrays[0];
    datas.temp_price = arrays[0];
    datas.perpetual = "1";
    datas.maintenance = "0";
    datas.quantity = "1";
    datas.temp_maintenace_price = "0";
    datas.customer_currency = this.c3x_data.Customer_currency;
    datas.customer_currency_symbol = this.c3x_data.Currency_symbol;
    datas.currency_value = this.c3x_data.Currency_value;

    datasend.access_token = access_token;
    datasend.operation = "curlDatas";
    datasend.moduleType = "login";
    datasend.api_type = "web";
    datasend.element_data = datas;
    this.serverService.sendServer(datasend).subscribe((response: any) => {
      console.log(response)
      // if(response.status=="true"){
      this.message = response.message
      this.cartdata = response

      // }
      //         else{
      // this.prepaidnotedata =[];

      //         }  
    },
      (error) => {
        console.log(error);
      });

  }



  customers_data() {

    let data: any = new Object();
    let datasend: any = new Object();
    let access_token: any = localStorage.getItem('at');
    let customer_id: any = localStorage.getItem('en');


    data.action = "contact_flag";
    data.customer_id = customer_id;
    data.customer_state = localStorage.getItem('customer_state');
    data.cms_user_id = localStorage.getItem('cms_user_id');

    datasend.access_token = access_token;
    datasend.operation = "curlDatas";
    datasend.moduleType = "login";
    datasend.api_type = "web";

    datasend.element_data = data;
    this.serverService.sendServer(datasend).subscribe((response: any) => {
      console.log(response)
      if (response.status == "true") {
        this.contact_details = response;
        this.system_discount_3cx = response.customer_details.system_discount_3cx;
        this.reseller_dis_per = response.customer_details.reseller_dis_per;

      }
      else {

      }
    },
      (error) => {
        console.log(error);
      });

  }

  getcart() {
    // Swal.fire('Please wait')
    // Swal.showLoading()
    let datas: any = new Object();
    let datasend: any = new Object();
    let access_token: any = localStorage.getItem('at');
    let customer_id: any = localStorage.getItem('en');


    datas.action = "getcartlist";
    datas.customer_id = customer_id;

    datasend.access_token = access_token;
    datasend.operation = "agents";
    datasend.moduleType = "agents";
    datasend.api_type = "web";
    datasend.element_data = datas;
    this.serverService.sendServer(datasend).subscribe((response: any) => {
      console.log(response)
      // return false;
      if (response.status == "true") {

        // this.cartdata = response.options
        console.log(response.options.length);
        if (response.options.length <= 5) {
          this.insertcart()

        } else {
          iziToast.error({
            message: "Cart limit exceeded",
            position: 'topRight'
          });
        }

      }
      else {
        this.insertcart()

      }

    },
      (error) => {
        console.log(error);
      });

  }


  getcarts() {
    Swal.fire('Please wait')
    Swal.showLoading()
    let datas: any = new Object();
    let datasend: any = new Object();
    let access_token: any = localStorage.getItem('at');
    let customer_id: any = localStorage.getItem('en');


    datas.action = "getcartlist";
    datas.customer_id = customer_id;

    datasend.access_token = access_token;
    datasend.operation = "agents";
    datasend.moduleType = "agents";
    datasend.api_type = "web";
    datasend.element_data = datas;
    this.serverService.sendServer(datasend).subscribe((response: any) => {
      console.log(response)
      if (response.status == "true") {
        Swal.close()
        // this.cartdata = response.options
        if (response.options.length != 0) {
          this.router.navigate(['/shopping-cart']);

        }
        //   if(response.options.length <= 5){
        //   this.insertcart()

        // }
        else {

          iziToast.error({
            message: "Please Add any Product in your cart",
            position: 'topRight'
          });
        }

      }
      else {
        Swal.close()

        iziToast.error({
          message: "Please Add any Product in your cart",
          position: 'topRight'
        });

      }

    },
      (error) => {
        console.log(error);
      });

  }

  // public noWhitespaceValidator(control: FormControl) {
  //   const isWhitespace = (control.value || '').trim().length === 0;
  //   const isValid = !isWhitespace;
  //   return isValid ? null : { 'whitespace': true };
  // }


  onchangeEdition() {

    if (this.getdataupgrademaintan.value.li_key == "" || this.getdataupgrademaintan.value.li_key == undefined) {
      iziToast.error({
        message: "Please Enter License Key",
        position: 'topRight'

      });
      return false
    }

    console.log(this.getdataupgrademaintan.value.editionkeyupgrade);

    let editionsplit = this.getdataupgrademaintan.value.editionkeyupgrade.split(",");

    console.log(editionsplit[1]);


    Swal.fire('Please wait')
    Swal.showLoading()

    let datas: any = new Object();
    let datasend: any = new Object();
    let access_token: any = localStorage.getItem('at');
    let customer_id: any = localStorage.getItem('en');


    datas.action = "price_information_upgrade_cbo";
    datas.customer_id = customer_id;
    datas.product_sale_type = "upgrade";
    datas.edition_type = editionsplit[1];
    datas.license_key = this.getdataupgrademaintan.value.li_key.trim();
    // datas.customer_currency = this.customer_data.currency_name;
    // datas.customer_currency_symbol = this.customer_data.symbol;
    // datas.currency_value = this.customer_data.currency_value;
    datas.def_biller_id = this.contact_details.def_biller_id;
    datas.reseller_id = this.contact_details.reseller_id;
    datas.customer_currency = this.contact_details.currency;
    datas.customer_currency_symbol = this.contact_details.currency_symbol;
    datas.system_discount_3cx = this.contact_details.system_discount_3cx;
    datas.addHosting = ""+this.include_val+"";
    datasend.access_token = access_token;
    datasend.operation = "agents";
    datasend.moduleType = "agents";
    datasend.api_type = "web";
    datasend.element_data = datas;
    this.serverService.sendServer(datasend).subscribe((response: any) => {

      if (response.status == "true") {
        Swal.close();
        this.upgradeshow = true;
        this.maintananceshow = false;
        // this.upgradeedition = response.Edition;
        this.upgrade_call = response.Simultaneouscall;
        //      this.getdataupgrademaintan.patchValue({
        //   editionkeyupgrade:this.upgradeedition[0].value,

        // });

      }
      else {
        this.errormessage = response.meassage;
        console.log(this.errormessage)
        Swal.close();
        this.upgradeedition = [];
        this.upgrade_call = [];

      }
    },
      (error) => {
        console.log(error);
      });



  }



}
