
 <section class="section">
<ul class="breadcrumb breadcrumb-style ">
<li class="breadcrumb-item">
<h4 class="page-title m-b-0">Notification</h4>
</li>
<li class="breadcrumb-item">
<a routerLink="/dashboard">
<i data-feather="home"></i></a>
</li>
<li class="breadcrumb-item active">Notification</li>
</ul>
<div class="section-body">
<div class="row">
<div class="col-12">
<div class="card card-primary">
<div class="card-header">
<h4>Notification</h4>

<div class="card-header-action">
<a  data-toggle="collapse"  class="btn btn-icon btn-info" href="#tableCollapse">
<i class="fas fa-minus"></i></a>
</div>

</div>
<div class="collapse show" id="tableCollapse">
<div class="card-body">
<div class="table-responsive">
<table class="table table-striped"  >







<thead>
<tr>

<th>S.no</th>
<th>Type</th>
<th>Message</th>
<th>Action</th>

</tr>
</thead>
<tbody>
<tr *ngFor ="let l of notifydata;let i = index;">

<td>{{i+1}}</td>
<!-- src="{{l.image}}"  -->
<!-- onerror="this.src='assets/images/avatar.jpg'"  -->
<td>
	<img src="{{l.image}}"  onerror="this.src='assets/img/notification/invoice.png'"    alt="image"  width="35">&nbsp;
{{l.type}}</td>

  <td>
   {{l.message}}
  </td>


<td>
<div class="badge badge-success badge-shadow pointer">&nbsp;View&nbsp;</div>
</td>



</tr>






</tbody>

 <!-- <tr *ngIf="recordNotFound == true">
                 <td colspan="12">No Record Found</td>
               </tr> -->



</table>
</div>

<!-- <div class="card-footer text-right" *ngIf="recordNotFound == false">

              <nav class="d-inline-block">
                <ul class="pagination mb-0" *ngIf="paginationData.info == 'show'">

                  <li class="page-item">
                    <a [class]="paginationData.backtab=='show' ? 'page-link' :'page-link disabled'" href="javascript:void(0)" (click)="paginationData.backtab=='show' ? gcclist({'search_text':agent_search.value,'offset':paginationData.start}) : false"><i class="fas fa-angle-left"></i></a>
                  </li>
                  <li class="page-item" *ngIf="paginationData.backtab =='show'">
                    <a class="page-link" href="javascript:void(0)" (click)="gcclist({'search_text':agent_search.value,'offset':paginationData.back})"><i class="fas fa-angle-double-left"></i></a>
                  </li>


                  <li [class]="pagination.load ==false ? 'page-item active' :'page-item'" *ngFor="let pagination of paginationData.data;let i=index">
                    <a class="page-link" href="javascript:void(0)" (click)=" pagination.load ==true ? gcclist({'search_text':agent_search.value,'offset':pagination.offset_count}) : false">{{pagination.btn_length}} <span class="sr-only" *ngIf="pagination.load ==false">(current)</span></a>
                  </li>

                  <li class="page-item" *ngIf="paginationData.nexttab =='show'">
                    <a class="page-link" href="javascript:void(0)" (click)="gcclist({'search_text':agent_search.value,'offset':paginationData.next})"><i class="fas fa-angle-double-right"></i></a>
                  </li>

                  <li class="page-item">
                    <a [class]="paginationData.nexttab =='show' ? 'page-link' : 'page-link disabled'" href="javascript:void(0)" (click)="paginationData.nexttab =='show' ? gcclist({'search_text':agent_search.value,'offset':paginationData.end}) : false"><i class="fas fa-angle-right"></i></a>
                  </li>
                </ul>
              </nav>
            </div> -->




</div>
</div>
</div>
</div>
</div>
</div>
</section>