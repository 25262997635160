import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProfileDetailsComponent } from './profile-details/profile-details.component';
import { LicenseComponent } from './license/license.component';
import { Buy3cxComponent } from './buy3cx/buy3cx.component';
import { SupportTicketDashboardComponent } from './support-ticket-dashboard/support-ticket-dashboard.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './login/logout.component';
import { ForgotPwdComponent } from './forgot-pwd/forgot-pwd.component';
import { ShoppingCartComponent } from './shopping-cart/shopping-cart.component';
import { CmsUsernewComponent } from './cms-usernew/cms-usernew.component';
import { ExtensionsComponent } from './extensions/extensions.component';
import { NewextensionComponent } from './newextension/newextension.component';
import { ReadmoreComponent } from './readmore/readmore.component';
import { ShoppingcartdetailsComponent } from './shoppingcartdetails/shoppingcartdetails.component';
import { NotificationComponent } from './notification/notification.component';
import { CostcenterComponent } from './costcenter/costcenter.component';
import { CostcentereditComponent } from './costcenteredit/costcenteredit.component';
import { PaysuccessComponent } from './paysuccess/paysuccess.component';
import { CheckpageComponent } from './checkpage/checkpage.component';
import { LogincmsComponent } from './logincms/logincms.component';
import { OcbcSuccessComponent } from './ocbc-success/ocbc-success.component';
import { CreditsPaymentComponent } from './credits-payment/credits-payment.component';
import { PaysuccessTopupComponent } from './paysuccess-topup/paysuccess-topup.component';
import { ReferralComponent } from './referral/referral.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { DashboardComponent } from './dashboard/dashboard.component';


const routes: Routes = [
    {
        path : '', component: ProfileDetailsComponent
    },{
        path : 'invoice', component : InvoiceComponent
    },{
        path : 'dashboard',component:DashboardComponent
    },
{
        path :'call_topup_payment', component: PaysuccessTopupComponent
    },{
        path : 'referal',component:ReferralComponent
    },
     {
        path :'profile-details', component: ProfileDetailsComponent
    },
     {
        path :'license', component: LicenseComponent
    },
    {
        path :'buy3cx', component: Buy3cxComponent
    },
      {
        path :'support-ticket-dashboard', component: SupportTicketDashboardComponent
    },
    {
        path :'shopping-cart', component: ShoppingCartComponent
    },
 {
        path :'cmsusernew', component: CmsUsernewComponent
    },
    {
        path :'extensions', component: ExtensionsComponent
    },
  {
        path :'newextension', component: NewextensionComponent
    },
    {
        path :'login', component: LoginComponent
    },
    {
        path :'forgot-pwd', component: ForgotPwdComponent
    },{
        path :'logout', component: LogoutComponent
    },
    {
        path :'blogdetail', component: ReadmoreComponent
    },
    {
        path :'shoppingcartdetails', component: ShoppingcartdetailsComponent
    }, 
     {
        path :'notification', component: NotificationComponent
    },
      {
        path :'costcenter', component: CostcenterComponent
    },
      {
        path :'costcenteredit', component: CostcentereditComponent
    },
      {
        path :'paysuccess', component: PaysuccessComponent
    },
      {
        path :'ocbc_success', component: OcbcSuccessComponent
    },
       {
        path :'checkpage', component: CheckpageComponent
    },
       {
        path :'login_cmsnew', component: LogincmsComponent
    },{
        path :'credit-payments', component: CreditsPaymentComponent
    }
    
    
    
    
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
