import { Component, OnInit } from '@angular/core';
import { ServerService } from '../services/server.service';
import { Router } from '@angular/router';
import * as Feather from 'feather-icons';
@Component({
  selector: 'app-license',
  templateUrl: './license.component.html',
  styleUrls: ['./license.component.css']
})
export class LicenseComponent implements OnInit {
recordNotFound = false;
 pageLimit = 10;
 paginationData:any ={"info":"hide"};
 offset_count = 0;

 licensedetail:any=[];
  constructor(private router:Router,private serverService: ServerService) { }

  ngOnInit(): void {

    
setTimeout (() => {
   
     if(this.serverService.license == 0){
  
 this.router.navigate(['/dashboard']);
}                           
      }, 2000);

this.get_license_list({});
//  this.licenselist({})
console.log(btoa("4406")); 
// console.log(btoa("username:temppass")); 
 // console.log(atob("dXNlcm5hbWU6dGVtcHBhc3M="));
  }
  doc(bill_id){
    let customer_id: any=localStorage.getItem('en');
    let access_token: any=localStorage.getItem('at');
    let appi_req:any='{"access_token":"'+access_token+'","operation":"curlDatas","moduleType":"login","api_type":"web","element_data":{"action":"license_pdfview","customer_id":"'+customer_id+'","billid":"'+bill_id+'"}}';
    this.serverService.sendServer(appi_req).subscribe((response:any)=>{
      console.log(response)
    })
  }
ngAfterViewInit() {
    Feather.replace();
  }
  get_license_list(data){
    var list_data= this.listDataInfo(data);
    let customer_id: any=localStorage.getItem('en');
    let access_token: any=localStorage.getItem('at');
    let license_data:any = new Object();
    let license_list:any = new Object();
    license_data.action="license_list";
    license_data.customer_id=customer_id;
    license_data.limit=list_data.limit;
    license_data.offset=list_data.offset;
    license_list.access_token=access_token;
    license_list.operation="curlDatas";
    license_list.moduleType="login";
    license_list.api_type="web";
    license_list.element_data = license_data;
    this.serverService.sendServer(license_list).subscribe((res:any)=>{
      console.log(res)
      if(res.status=="true"){
        this.licensedetail = res.License_List
      
             }
           else{
   this.licensedetail =[];
    
           }
      this.offset_count = list_data.offset;
           this.paginationData = this.serverService.pagination({'offset':res.Offset, 'total':res.Total, 'page_limit' :this.pageLimit });
            console.log(this.paginationData);
                   this.recordNotFound = this.licensedetail.length == 0 ? true : false;
   
   
         
         }, 
         (error)=>{
             console.log(error);
         });

  }
// licenselist(data){
//     var list_data= this.listDataInfo(data);

// // console.log(this.serverService.ci)
//   let invoicedata:any =new Object();
//  let invoicedatasend:any = new Object();
//     let access_token: any=localStorage.getItem('at');
//     let customer_id: any=localStorage.getItem('en');
   
   
// invoicedata.action="license_list";
// invoicedata.customer_id = customer_id;
// invoicedata.limit = list_data.limit;
// invoicedata.offset = list_data.offset;
// invoicedata.search_text=list_data.search_text;

//  invoicedatasend.access_token=access_token;
//   invoicedatasend.operation="curlDatas";
//   invoicedatasend.moduleType="login";
//   invoicedatasend.api_type="web";
//   invoicedatasend.element_data = invoicedata;
//       this.serverService.sendServer(invoicedatasend).subscribe((response: any) => {
// console.log(response)
//         if(response.status=="true"){
//      this.licensedetail = response.License_List
   
//           }
//         else{
// this.licensedetail =[];
 
//         }
//    this.offset_count = list_data.offset;
//         this.paginationData = this.serverService.pagination({'offset':response.Offset, 'total':response.Total, 'page_limit' :this.pageLimit });
//          console.log(this.paginationData);
//                 this.recordNotFound = this.licensedetail.length == 0 ? true : false;


      
//       }, 
//       (error)=>{
//           console.log(error);
//       });

// }


listDataInfo(list_data){
console.log(list_data)
  list_data.search_text = list_data.search_text == undefined ? "" : list_data.search_text;
  // list_data.order_by_name = list_data.order_by_name == undefined ? "user.user_id" : list_data.order_by_name;
  // list_data.order_by_type = list_data.order_by_type == undefined ? "desc" : list_data.order_by_type;
  list_data.limit = list_data.limit == undefined ? this.pageLimit : list_data.limit;
  list_data.offset = list_data.offset == undefined ? 0 : list_data.offset;
  return list_data;
} 

getsearch(searchValue: string): void {  
  console.log(searchValue);
  // this.licenselist({'search_text':searchValue})
}








}
