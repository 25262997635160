/**
*
* You can write your JS code here, DO NOT touch the default style file
* because it will make it harder for you to update.
*
*/

"use strict";

$(function () {
$(".footer-help-desk").hide();
$(".footer-support-call").click(function(){
$(".footer-help-desk").toggle('medium');
});
$(".helpdesk-close").click(function(){
$(".footer-help-desk").hide('medium');
});
feather.replace();
jQuery('.tabStartClick').click();


$('.dropdown-menu').on('click', function(event) {
event.stopPropagation();
});


 chart6();
  function chart6() {
  var options = {
    chart: {
      height: 350,
      type: "area",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    series: [
      {
        name: "Support SG",
        data: [31, 25, 28, 51, 42, 48, 65],
      },
         {
        name: "Support MY",
        data: [11, 12, 26, 32, 34, 52, 41],
      },
      {
        name: "Sales",
        data: [25, 42, 45, 25, 48, 14, 32],
      },
       {
        name: "Development",
        data: [24, 15, 15, 36, 31, 28, 29],
      },
    ],

    xaxis: {
      type: "month",
      categories: [
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "Sep",
      ],
      labels: {
        style: {
          colors: "#8e8da4",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          color: "#8e8da4",
        },
      },
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
  };

  var chart = new ApexCharts(document.querySelector("#chart6"), options);

  chart.render();
}




});


$(function () {
CKEDITOR.replace( 'dealRegistrationEditor' );
CKEDITOR.replace( 'ck-editor' );
CKEDITOR.on( 'instanceReady', function( evt )
{
var editor = evt.editor;

editor.on('change', function (e) { 
var contentSpace = editor.ui.space('contents');
var ckeditorFrameCollection = contentSpace.$.getElementsByTagName('iframe');
var ckeditorFrame = ckeditorFrameCollection[0];
var innerDoc = ckeditorFrame.contentDocument;
var innerDocTextAreaHeight = $(innerDoc.body).height();
console.log(innerDocTextAreaHeight);
});
});
});



$("[data-checkboxes]").each(function () {
  var me = $(this),
    group = me.data("checkboxes"),
    role = me.data("checkbox-role");

  me.change(function () {
    var all = $(
        '[data-checkboxes="' + group + '"]:not([data-checkbox-role="dad"])'
      ),
      checked = $(
        '[data-checkboxes="' +
          group +
          '"]:not([data-checkbox-role="dad"]):checked'
      ),
      dad = $('[data-checkboxes="' + group + '"][data-checkbox-role="dad"]'),
      total = all.length,
      checked_length = checked.length;

    if (role == "dad") {
      if (me.is(":checked")) {
        all.prop("checked", true);
      } else {
        all.prop("checked", false);
      }
    } else {
      if (checked_length >= total) {
        dad.prop("checked", true);
      } else {
        dad.prop("checked", false);
      }
    }
  });
});

$(function () {
$("#table-1").dataTable({
  columnDefs: [{ sortable: false, targets: [2, 3] }],
});
$("#dataTable").dataTable({
  columnDefs: [{ sortable: false, targets: [0] }],
  order: [[1, "asc"]], //column indexes is zero based
});
$("#tableExport").DataTable({
  dom: "Bfrtip",
  buttons: ["copy", "csv", "excel", "pdf", "print"],
});
$("#save-stage").DataTable({
  scrollX: true,
  stateSave: true,
});

});