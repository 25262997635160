<section class="section">
  <ul class="breadcrumb breadcrumb-style ">
    <li class="breadcrumb-item">
      <h4 class="page-title m-b-0">Online Ordering</h4>
    </li>
    <!-- <li class="breadcrumb-item">
<a routerLink="/dashboard">
<i data-feather="home"></i></a>
</li>
<li class="breadcrumb-item active">
Select to order
</li> -->
  </ul>
  <div class="section-body">
    <div class="row">
      <div class="col-md-12 col-lg-12 col-xl-12">
        <div class="card card-success">
          <div class="card-header">
            <h4>Select to order</h4>
            <div class="card-header-action">
              <a data-toggle="collapse" class="btn btn-icon btn-success" href="#referralAmount-collapse">
                <i class="fas fa-minus"></i></a>
            </div>
          </div>
          <div class="collapse show" id="referralAmount-collapse">
            <div class="card-body">


              <form [formGroup]="getdata">



                <div class="row">
                  <div class="col-md-4 text-right">
                    <h6 class="pt-2" style="font-size: 14px;">I would like to</h6>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <select id="product_sale_type" name="product_sale_type" class="optional form-control"
                        formControlName="category">
                        <option value="" selected="selected">Please choose category</option>
                        <option value="new">Purchase a new license</option>
                        <option value="upgrade">Upgrade an existing license</option>
                        <option value="maintenance">Maintenance(Annual/Perpetual)</option>
                      </select>
                      <div class="error-message message-small"
                        *ngIf="!getdata.get('category').valid && getdata.get('category').touched">
                        Please choose category
                      </div>
                    </div>
                  </div>
                </div>








                <div class="row" *ngIf="getdata.value.category == '' || getdata.value.category == 'new'">
                  <div class="col-md-4 text-right">
                    <h6 class="pt-2" style="font-size: 14px;">Editions</h6>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <select id="edition_key" name="edition_key" class="optional form-control"
                        formControlName="editionkey" (change)="editionchange()">
                        <option value="" selected="selected"> Choose edition</option>
                        <option value="3CXPSPROFSPLA"> 3CX Phone System Professional - Annual</option>
                        <option value="3CXPSPROFENTSPLA"> 3CX Phone System Enterprise Edition - Annual</option>
                        <!-- <option *ngFor="let l of edition" value="{{l.value}}">{{l.option_value}}</option> -->
                      </select>
                      <div class="error-message message-small"
                        *ngIf="!getdata.get('editionkey').valid && getdata.get('editionkey').touched">
                        Please choose edition
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="row" *ngIf="getdata.value.category == '' || getdata.value.category == 'new'">
                  <div class="col-md-4 text-right">
                    <h6 class="pt-2" style="font-size: 14px;">Editions</h6>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <select id="edition_key" name="edition_key" class="optional form-control"
                        formControlName="editionkey" (change)="editionchange()">
                        <option value="" selected="selected"> Choose edition</option>
                        <option *ngFor="let l of edition" value="{{l.value}}">{{l.option_value}}</option>
                      </select>
                      <div class="error-message message-small"
                        *ngIf="!getdata.get('editionkey').valid && getdata.get('editionkey').touched">
                        Please choose edition
                      </div>
                    </div>
                  </div>
                </div> -->




                <div class="row" *ngIf="getdata.value.category == 'new' && showhost == true">
                  <div class="col-md-4 text-right">
                    <!-- <h6 class="pt-2" style="font-size: 14px;">Include Hosted By 3CX</h6> -->
                  </div>
                  <div class="col-md-4">

                    <div class="form-group">
                      <div class="form-check form-switch">
                        <!-- <input class="form-check-input checkboxes" type="checkbox" id="checkbox-host" (change)="hostEditionChange()" [(ngModel)]="includehost_check" [ngModelOptions]="{standalone: true}" [disabled]="readonlyBox"> -->
                        <input class="form-check-input checkboxes" type="checkbox" id="checkbox-host"
                          (change)="hostEditionChange()" [(ngModel)]="includehost_check"
                          [ngModelOptions]="{standalone: true}">
                        <label class="form-check-label checkbox-label" for="checkbox-host">Include Hosted By 3CX</label>
                      </div>
                    </div>

                  </div>
                </div>


                <div class="row" *ngIf="getdata.value.category == 'new' && showhost == true">
                  <div class="col-md-4 text-right">
                    <h6 class="pt-2" style="font-size: 14px;">Additional Years</h6>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <select id="additional_years" name="additional_years" class="optional form-control"
                        formControlName="additional_years" (change)="yearschange()">
                        <option value="" selected="selected"> Choose Years</option>
                        <option value="0"> 0 years</option>
                        <option value="1"> 1 years</option>
                        <option value="2"> 2 years</option>
                        <option value="3"> 3 years</option>
                        <option value="4"> 4 years</option>
                        <option value="5"> 5 years</option>
                      </select>
                      <div class="error-message message-small"
                        *ngIf="!getdata.get('additional_years').valid && getdata.get('additional_years').touched">
                        Please choose years
                      </div>
                    </div>
                  </div>
                </div>


                <div class="row" *ngIf="getdata.value.category == '' || getdata.value.category == 'new'">
                  <div class="col-md-4 text-right">
                    <h6 class="pt-2" style="font-size: 14px;">Concurrent License</h6>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <select id="edition_key" name="edition_key" class="optional form-control"
                        formControlName="conc_license" (change)="concurrentlicense_change()">
                        <option selected="selected" value=""> Choose Concurrent License</option>
                        <option *ngFor="let l of concur_license" value="{{l.prices}},{{l.discount}},{{l.value}}">
                          {{l.option_value}}</option>
                      </select>
                      <div class="error-message message-small"
                        *ngIf="!getdata.get('conc_license').valid && getdata.get('conc_license').touched">
                        Please choose concurrent license
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row" *ngIf="getdata.value.category == '' || getdata.value.category == 'new'">
                  <div class="col-md-4 text-right">
                    <h6 class="pt-2" style="font-size: 14px;">Quantity</h6>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <input type="text" class="form-control" id="quantity" name="quantity" value="1"
                        formControlName="qty">
                      <div class="error-message message-small"
                        *ngIf="!getdata.get('qty').valid && getdata.get('qty').touched">
                        Please enter quantity
                      </div>
                    </div>
                  </div>
                </div>


                <div class="row"
                  *ngIf="getdata.value.category == '' || getdata.value.category == 'new' && showhost == false">
                  <div class="col-md-4 text-right">
                    <h6 class="pt-2" style="font-size: 14px;">Add Maintenances</h6>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <input type="text" class="form-control" id="maintenance" name="maintenance" value="0"
                        formControlName="maintenance">
                      <div class="error-message message-small" *ngIf="message != ''">
                        {{message}}
                      </div>
                      <div class="error-message message-small"
                        *ngIf="!getdata.get('maintenance').valid && getdata.get('maintenance').touched">
                        Please enter maintenance
                      </div>
                    </div>
                  </div>
                </div>


                <div class="submit-btn-group text-center mb-2"
                  *ngIf="getdata.value.category == '' || getdata.value.category == 'new'">

                  <a (click)="cart('view')" class="btn btn-warning">View Cart</a>

                  <a (click)="cart('insert')" class="btn btn-success">Add to Cart</a>

                </div>





              </form>



              <form [formGroup]="getdataupgrademaintan" *ngIf="getdata.value.category == 'upgrade'">


                <div class="row">
                  <div class="col-md-4 text-right">
                    <h6 class="pt-2" style="font-size: 14px;">License Key</h6>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <input type="text" class="form-control" id="quantity" name="quantity" value="1"
                        formControlName="li_key">
                      <div class="error-message message-small"
                        *ngIf="!getdataupgrademaintan.get('li_key').valid && getdataupgrademaintan.get('li_key').touched">
                        Please enter license key
                      </div>

                      <div class="error-message message-small" *ngIf="this.errormessage !=''">
                        {{this.errormessage}}
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4 pt-1">
                    <button class="btn btn-info" (click)="priceinformation_upgrade()">Verify</button>
                  </div>

                </div>



                <div class="row" *ngIf="showeditionUpgrade == true">
                  <div class="col-md-4 text-right">
                  </div>
                  <div class="col-md-4">

                    <div class="form-group">
                      <div class="form-check form-switch">
                        <input class="form-check-input checkboxes" type="checkbox" id="update-checkbox-host"
                          (change)="hostEditionChangeUpgrade($event.target.checked)" [(ngModel)]="includehost_check_upgrade"
                          [ngModelOptions]="{standalone: true}">
                        <label class="form-check-label checkbox-label" for="update-checkbox-host">Include Hosted By 3CX</label>
                      </div>
                    </div>

                  </div>
                </div>


                <!-- <div class="row" *ngIf="showeditionUpgrade == true">
                  <div class="col-md-4 text-right">
                    <h6 class="pt-2" style="font-size: 14px;">Additional Years</h6>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <select id="additional_years" name="additional_years" class="optional form-control"
                        formControlName="additional_years" (change)="upgradeyearschange()">
                        <option value="" selected="selected"> Choose Years</option>
                        <option value="0"> 0 years</option>
                        <option value="1"> 1 years</option>
                        <option value="2"> 2 years</option>
                        <option value="3"> 3 years</option>
                        <option value="4"> 4 years</option>
                        <option value="5"> 5 years</option>
                      </select>
                      <div class="error-message message-small"
                        *ngIf="!getdataupgrademaintan.get('additional_years').valid && getdataupgrademaintan.get('additional_years').touched">
                        Please choose years
                      </div>
                    </div>
                  </div>
                </div> -->

                <!-- upgrade edition dropdown -->
                <div class="row" *ngIf="upgradeshow">
                  <div class="col-md-4 text-right">
                    <h6 class="pt-2" style="font-size: 14px;">Edition</h6>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <select id="edition_key" name="edition_key" class="optional form-control"
                        formControlName="editionkeyupgrade" (change)="onchangeEdition()">
                        <option selected="selected" value=""> Choose edition upgrade</option>
                        <option *ngFor="let l of upgradeedition" value="{{l.value}},{{l.edition}}">{{l.edition}}
                        </option>
                      </select>
                      <div class="error-message message-small"
                        *ngIf="!getdataupgrademaintan.get('editionkeyupgrade').valid && getdataupgrademaintan.get('editionkeyupgrade').touched">
                        Please choose edition
                      </div>
                    </div>
                  </div>
                </div>


                <!-- upgrade Concurrent License -->
                <div class="row" *ngIf="upgradeshow">
                  <div class="col-md-4 text-right">
                    <h6 class="pt-2" style="font-size: 14px;">Concurrent License</h6>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <select id="edition_key" name="edition_key" class="optional form-control"
                        formControlName="conc_license_upgrade" (change)="onchangecon_licenc()">
                        <option selected="selected" value=""> Choose Concurrent License</option>
                        <option *ngFor="let l of upgrade_call"
                          value="{{l.price_value}},{{l.discount}},{{l.value}},{{l.prices_value}}">{{l.option_value}}
                        </option>
                      </select>
                      <div class="error-message message-small"
                        *ngIf="!getdataupgrademaintan.get('conc_license_upgrade').valid && getdataupgrademaintan.get('conc_license_upgrade').touched">
                        Please choose concurrent license
                      </div>
                    </div>
                  </div>
                </div>




                <div class="submit-btn-group text-center mb-2" *ngIf="upgradeshow">

                  <a (click)="cart('view')" class="btn btn-warning">View Cart</a>

                  <a (click)="cart('insert')" class="btn btn-success">Add to Cart</a>

                </div>



              </form>



              <form [formGroup]="getmaintan" *ngIf="getdata.value.category == 'maintenance'">


                <div class="row">
                  <div class="col-md-4 text-right">
                    <h6 class="pt-2" style="font-size: 14px;"  >License Key</h6>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <input type="text" class="form-control" id="quantity" name="quantity" value="1"
                        formControlName="li_key">
                      <div class="error-message message-small"
                        *ngIf="!getmaintan.get('li_key').valid && getmaintan.get('li_key').touched">
                        Please enter license key
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4 pt-1">
                    <button class="btn btn-info" (click)="priceinformation_upgrade()">Verify</button>
                  </div>

                </div>

                <div class="row" *ngIf="getdata.value.category == 'maintenance'">
                  <div class="col-md-4 text-right">
                    <h6 class="pt-2" style="font-size: 14px;">Years</h6>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <select id="maintance_years" name="maintance_years" class="optional form-control" 
                        formControlName="maintance_years" >
                        <option value="1"> 1 years</option>
                        <option value="2"> 2 years</option>
                        <option value="3"> 3 years</option>
                        <option value="4"> 4 years</option>
                        <option value="5"> 5 years</option>
                      </select>
                      <div class="error-message message-small"
                        *ngIf="!getmaintan.get('maintance_years').valid && getmaintan.get('maintance_years').touched">
                        Please choose years
                      </div>
                    </div>
                  </div>
                </div>




                <div class="row" *ngIf="maintananceshow">
                  <div class="col-md-4 text-right">
                    <h6 class="pt-2" style="font-size: 14px;">Price</h6>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      {{maintenance_data.price_data}}


                    </div>
                  </div>

                </div>

                <!-- <div class="row" *ngIf="maintananceshow">
                  <div class="col-md-4 text-right">
                    <h6 class="pt-2" style="font-size: 14px;">Year</h6>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <input type="text" class="form-control" id="year" name="year" value="1" formControlName="year">
                      <div class="error-message message-small"
                        *ngIf="!getmaintan.get('year').valid && getmaintan.get('year').touched">
                        Please enter year
                      </div>
                    </div>
                  </div>

                </div> -->

                <p style="color: red; text-align: center;" *ngIf="maintananceshow&&disply_maintenance_error==true">* You are not required to renew the free 3CX Standard edition, it will be renewed automatically. You may however upgrade your subscription’s edition or sim call capacity.</p>
                <div class="submit-btn-group text-center mb-2" *ngIf="maintananceshow&&disply_maintenance_error==false">

                  <a (click)="cart('view')" class="btn btn-warning">View Cart</a>

                  <a (click)="cart('insert')" class="btn btn-success">Add to Cart</a>

                </div>



              </form>






            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>