import { Component, OnInit } from '@angular/core';
import { ServerService } from '../services/server.service';
import * as Feather from 'feather-icons';
import { Router } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2'
declare var $:any;
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
cur:any={};
curdate;
countryflagdata:any="";
userperms:any=[];
pn:any=[];
newseventsmodallist:any=[];

notifydata:any=[];
datas:any;
headernoti_count:any=0;
loads=false;
show_allowed_my_ip = false;
profile;
cus_permission;
currency_symbol;
  constructor(private router:Router,public serverService: ServerService) {

//  this.currencyview()
//   	    this.countryflag()
//         this.permissions()
        
       
   }

  ngOnInit(): void {
       this.currency_symbol = localStorage.getItem("currency_symbol");
        this.serverService.cn=localStorage.getItem('cn');
        this.serverService.prof_img = localStorage.getItem('prof_img');
        var profiles = localStorage.getItem('profile_image');
        if(profiles != '' && profiles !=null && profiles !=undefined && profiles !='undefined' && profiles !='null'){
        this.profile = "https://erp.cal4care.com/cms_new/api_cms/v1.0/profile_image/"+profiles;
        }
        // this.notificationlist()
        this.menu_per();
  	   
  }

  menu_per(){
    this.cus_permission = localStorage.getItem('customer_per').split(',');
    for(var i = 0;i<this.cus_permission.length;i++){
      if (this.cus_permission[i] == '101')
      this.serverService.invoice = 1;
    if (this.cus_permission[i] == '102'){
      this.serverService.prepaidnote = 1;
       this.serverService.creditnote = 1;   
    }
    if (this.cus_permission[i] == '103')
      this.serverService.license = 1;
    if (this.cus_permission[i] == '104')
      this.serverService.projects = 1;
    if (this.cus_permission[i] == '105')
      this.serverService.phones = 1;
    if (this.cus_permission[i] == '107')
      this.serverService.leads = 1;
    if (this.cus_permission[i] == '131')
      this.serverService.reseller = 1;
    if (this.cus_permission[i] == '109')
      this.serverService.callhistory = 1;    
    if (this.cus_permission[i] == '118')
      this.serverService.cxbuy = 1;
    if (this.cus_permission[i] == '134')
      this.serverService.iddpricelist = 1;
    if (this.cus_permission[i] == '138')
      this.serverService.call4tellicense = 1;
    if (this.cus_permission[i] == '157')
      this.serverService.supportticket = 1;
    if (this.cus_permission[i] == '110')
      this.serverService.users = 1;
    if (this.cus_permission[i] == '158')
      this.serverService.licencerenewal = 1;
    if (this.cus_permission[i] == '159')
      this.serverService.dealregistration = 1;
    if (this.cus_permission[i] == '160')
      this.serverService.gccfirewall = 1;
    }
    console.log(this.cus_permission)
  }
reads(val){

 
  console.log(val)
  if(val.type == "referral")
    this.router.navigate(['/referral']);
  if(val.type == "payment_add")
    this.router.navigate(['/buy3cx']);
  if(val.type == "renewal")
    this.router.navigate(['/renewal']);
  if(val.type == "invoice")
    this.router.navigate(['/invoice']);
  if(val.type == "payment_made")
    this.router.navigate(['/buy3cx']);
  if(val.type == "support")
    this.router.navigate(['/support-ticket']);

}
fullScreenBtn(){
  $("body").toggleClass("sidebar-mini");
}

read(val){
    this.updatenotify(val)
  
}
logoutapp(){
  // localStorage.removeItem("access_token");
          // localStorage.removeItem("user_name");
          // localStorage.removeItem("user_type");
          // localStorage.clear();
  // this.router.navigate(['/logout']);
}
updatenotification(){

}
setvalue(){
  // $('#full-width-modal').modal('show');
    let datas:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
   
datas.action="notification_list";
datas.customer_id = customer_id;


 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="notification";
  datasend.api_type="web";
  datasend.element_data = datas;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){
          // Swal.close()
     this.serverService.notifycount = response.count;
   
// localStorage.setItem('nt', btoa(response.count));

          }
        else{
          // Swal.close()
        this.notifydata =[];
 
        }

    }, 
      (error)=>{
          console.log(error);
      });

    }


  openmodal(){
    $('#addNewPopups').modal('show');
  }
ngAfterViewInit() {
    Feather.replace();
  }


gooldcms(){
this.loads= true;
 setTimeout (() => {
   
   let customer_id: any=localStorage.getItem('en');
 this.datas =uuidv4();
var cust_id=btoa(customer_id)
this.loads= false;
// var sendurl ='https://cmsdev1.cal4care.com/cms_old/loginProcess_old_conv.php?Aghwj=M'+cust_id+'=&bmVrb3Q='+this.datas+'';
var sendurl ='https://erp.cal4care.com/cms_old/loginProcess_old_conv.php?Aghwj=M'+cust_id+'=&bmVrb3Q='+this.datas+'';

// var sendurl ='https://erp.cal4care.com/cms_old/loginProcess_old_conv.php?Aghwj=M'+cust_id+'=&bmVrb3Q='+this.datas+'';
console.log(sendurl)
 // https://erp.cal4care.com/cms_new/cms_data_redirect.php?action=login_finger_chk&customer_email='+this.emailid+''
window.open(sendurl, "_self");

      }, 2000);

}


currencyview(){

  let data:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
   
data.action="currency_view";
 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="login";
  datasend.api_type="web";
  datasend.element_data = data;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){
   this.cur = response.currency_array_values;
   this.curdate = response.curr_date;
   console.log(this.cur.SGD)
          }
        else{
 
        }      
      }, 
      (error)=>{
          console.log(error);
      });

}




countryflag(){

  let data:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
   
data.action="contact_flag";
data.customer_id=customer_id;
data.customer_state= localStorage.getItem('customer_state');
data.cms_user_id= localStorage.getItem('cms_user_id');

 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="login";
  datasend.api_type="web";

  datasend.element_data = data;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){
 this.countryflagdata = response.cms_menu_data;
// this.permscallhistory = response.customer_details;
          }
        else{
 
        }      
      }, 
      (error)=>{
          console.log(error);
      });

}



permissions(){
this.pn =[];
this.userperms =[];

// this.serverService.invoice=0;
// this.serverService.prepaidnote=0;
// this.serverService.creditnote = 0;
// this.serverService.license = 0;
// this.serverService.projects = 0;
// this.serverService.phones =0;
// this.serverService.leads =0;
// this.serverService.reseller =0;
// this.serverService.callhistory =0;
// this.serverService.cxbuy =0;
// this.serverService.iddpricelist =0;
// this.serverService.call4tellicense =0;
// this.serverService.supportticket =0;
// this.serverService.users =0;
// this.serverService.callbalance =0;
// this.serverService.users =0;
// this.serverService.referral =0;
// this.serverService.licencerenewal =0;
// this.serverService.dealregistration =0;
// this.serverService.callhistorypbx ="0";
// this.serverService.callhistoryvspbx ="0";
// this.serverService.gccfirewall =0;
// this.serverService.notifycount =0;
// this.serverService.callhistoryvspbx ="0";

// public callbalance =0;
// public referral =0;
// public licencerenewal =0;
// public dealregistration =0;
// public callhistorypbx ="0";
// public callhistoryvspbx ="0";
// public gccfirewall = 0;
// public notifycount = 0;
// public invoice = 0;
// public prepaidnote = 0;
// public creditnote = 0;
// public license = 0;
// public projects = 0;
// public phones =0;
// public leads =0;
// public reseller =0;
// public callhistory =0;
// public cxbuy =0;
// public iddpricelist =0;
// public call4tellicense =0;
// public supportticket =0;
// public users =0;
// public callbalance =0;
// public referral =0;
// public licencerenewal =0;
// public dealregistration =0;
// public callhistorypbx ="0";
// public callhistoryvspbx ="0";
// public gccfirewall = 0;
// public notifycount = 0;








  let data:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
   
data.action="contact_flag";
data.customer_id=customer_id;
data.customer_state= localStorage.getItem('customer_state');
data.cms_user_id= localStorage.getItem('cms_user_id');

 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="login";
  datasend.api_type="web";

  datasend.element_data = data;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){
 // this.countryflagdata = response.cms_menu_data;
    // this.userperms = localStorage.getItem('pn').split(',')
    //   for (var i = 0; i < this.userperms.length; i++) {
    //   this.pn.push(atob(this.userperms[i]))
    // }
this.serverService.callhistorypbx =  response.customer_details.cms_username_dispaly;
this.serverService.callhistoryvspbx =  response.customer_details.cms_vs_pbx_dispaly;

this.userperms = response.customer_details.cus_permission.split(',');

    console.log(this.userperms)
    for (var i = 0; i < this.userperms.length; i++) {

this.pn.push(btoa(this.userperms[i]))

    }

    if(response.customer_details.dc_ip_menu > 0){
      this.show_allowed_my_ip = true;
    }else{
      this.show_allowed_my_ip = false;
    }

localStorage.setItem('pn',this.pn); 
localStorage.setItem('dept',response.customer_details.cms_default_department); 
localStorage.setItem('dept_name',response.customer_details.cms_department_name); 

     for (var i = 0; i < this.userperms.length; i++) {
    
      if (this.userperms[i] == '101')
        this.serverService.invoice = 1;
      if (this.userperms[i] == '102'){
        this.serverService.prepaidnote = 1;
         this.serverService.creditnote = 1;   
      }
      if (this.userperms[i] == '103')
        this.serverService.license = 1;
      if (this.userperms[i] == '104')
        this.serverService.projects = 1;
      if (this.userperms[i] == '105')
        this.serverService.phones = 1;
      if (this.userperms[i] == '107')
        this.serverService.leads = 1;
      if (this.userperms[i] == '131')
        this.serverService.reseller = 1;
      if (this.userperms[i] == '109')
        this.serverService.callhistory = 1;    
      if (this.userperms[i] == '118')
        this.serverService.cxbuy = 1;
      if (this.userperms[i] == '134')
        this.serverService.iddpricelist = 1;
      if (this.userperms[i] == '138')
        this.serverService.call4tellicense = 1;
      if (this.userperms[i] == '157')
        this.serverService.supportticket = 1;
      if (this.userperms[i] == '110')
        this.serverService.users = 1;
      if (this.userperms[i] == '158')
        this.serverService.licencerenewal = 1;
      if (this.userperms[i] == '159')
        this.serverService.dealregistration = 1;
      if (this.userperms[i] == '160')
        this.serverService.gccfirewall = 1;
    }
 console.log(this.serverService.invoice)
    console.log(this.serverService.users)
    console.log(this.serverService.leads)
    console.log(this.serverService.license)

          }
        else{
 
        }      
      }, 
      (error)=>{
          console.log(error);
      });

}

notifyshown(){
  $('#notifymodal').modal('show');
  
    // this.notificationlist()
  

}
notificationlist(){

     // Swal.fire('Loading')
// Swal.showLoading()
  let datas:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
   
datas.action="notification_list";
datas.customer_id = customer_id;


 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="notification";
  datasend.api_type="web";
  datasend.element_data = datas;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){
          Swal.close()
     this.headernoti_count = response.count;
     this.notifydata = response.list;
   for (var i = 0; i < this.notifydata.length; i++) {
      if(this.notifydata[i].type == "referral")
        this.notifydata[i].image = "assets/img/notification/referral.png"
      if(this.notifydata[i].type == "payment_add")
        this.notifydata[i].image ="assets/img/notification/payment_add.png"
      if(this.notifydata[i].type == "renewal")
        this.notifydata[i].image ="assets/img/notification/renewal.png"
      if(this.notifydata[i].type == "invoice")
        this.notifydata[i].image ="assets/img/notification/invoice.png"
      if(this.notifydata[i].type == "payment_made")
        this.notifydata[i].image ="assets/img/notification/payment_made.png"
      if(this.notifydata[i].type == "support")
        this.notifydata[i].image ="assets/img/notification/support.png"
   }



          }
        else{
          Swal.close()
        this.notifydata =[];
 
        }

    }, 
      (error)=>{
          console.log(error);
      });

}
         
updatenotify(val){
 Swal.fire('Opening')
Swal.showLoading()
  let data:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
 datasend.access_token=access_token;
   
   
data.action="notification_update";
 data.customer_id=customer_id;
 data.id=val.id;

  datasend.operation="curlDatas";
  datasend.moduleType="notification";
  datasend.api_type="web";
  datasend.element_data = data;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){
           Swal.close()
           $('#notifymodal').modal('hide');
   this.reads(val)
          }
        else{
           Swal.close()
    this.reads(val)
        }      
      }, 
      (error)=>{
          console.log(error);
      });

}












}
