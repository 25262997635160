import { Component, OnInit } from '@angular/core';
import { ServerService } from '../services/server.service';
import { Router } from '@angular/router';
declare var iziToast:any;
import * as Feather from 'feather-icons';
import Swal from 'sweetalert2'
@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.css']
})
export class ShoppingCartComponent implements OnInit {
cartdata:any=[];
total_price;
total_tax;
grand_total;
tax_percent;
dataslist:any;
edit = false;
reseller_id;
showing = false;
  constructor(private router:Router,private serverService: ServerService) { }

  ngOnInit(): void {
    this.getcart()
  }
ngAfterViewInit() {
    Feather.replace();
  }

  editid(){
    this.edit = true;



  }

resellerid_change(id){
    // var list_data= this.listDataInfo(data);

// console.log(this.serverService.ci)
  let datas:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
   
datas.action="reseller_id_change";
datas.customer_id = customer_id;
datas.new_reseller_id = this.reseller_id;



 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="payment";
  datasend.api_type="web";
  datasend.element_data = datas;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){
    
        iziToast.success({
          message: "Reseller ID changed",
          position: 'topRight'
      });
         // this.getcart()
          }
        else{
  iziToast.error({
          message: "Please try again later",
          position: 'topRight'
      });
 
      }

      
      }, 
      (error)=>{
          console.log(error);
      });

}



procedd(){
var s = this;
  iziToast.question({
    timeout: 20000,
    close: false,
    overlay: true,
    displayMode: 'once',
    id: 'question',
    zindex: 999,
    title: 'Confirm',
    message: 'Are you sure? The above reseller id is your 3cx reseller id?',
    position: 'center',
    buttons: [
        ['<button><b>YES</b></button>', function (instance, toast) {
 
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
 console.log('yes')

   s.proceedtobuy()
 

        }, true],
        ['<button>NO</button>', function (instance, toast) {
 
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
  console.log('no')

        }],
    ]

});

}









removecart(data){
    // var list_data= this.listDataInfo(data);

//  console.log(data[0])
//  console.log(data)
//  console.log(data.cart_deatils_id)
  let datas:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
   
datas.action="remove_cart";
datas.select_chk = data.cart_deatils_id;
datas.customer_id = customer_id;




// invoicedata.limit = list_data.limit;
// invoicedata.offset = list_data.offset;
 datasend.access_token=access_token;
  datasend.operation="agents";
  datasend.moduleType="agents";
  datasend.api_type="web";
  datasend.element_data = datas;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){
    
        iziToast.success({
          message: "Product removed from your cart",
          position: 'topRight'
      });
         this.getcart()
          }
        else{
  iziToast.error({
          message: "Please try again later",
          position: 'topRight'
      });
 
      }

      
      }, 
      (error)=>{
          console.log(error);
      });

}

proceedtobuy(){
  if(this.cartdata.length != 0){
   this.router.navigate(['/shoppingcartdetails']);

  }else{
      iziToast.error({
          message: "Please add any product in your cart",
          position: 'topRight'
      });
  }
}



getcart(){
  Swal.fire('Please wait')
Swal.showLoading()
  let datas:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
   
datas.action="getcartlist";
datas.customer_id = customer_id;

 datasend.access_token=access_token;
  datasend.operation="agents";
  datasend.moduleType="agents";
  datasend.api_type="web";
  datasend.element_data = datas;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){
          this.showing = true
     this.cartdata = response.options;
     console.log(this.cartdata)
this.total_price = response.total_price_all
this.total_tax = response.total_tax_value
this.grand_total = response.grand_total_value
this.dataslist = response;
this.tax_percent = response.tax_percent;
this.reseller_id = response.Customer_3cx_data.Resellerid;
localStorage.setItem('xtt', response.tax_percent);


      Swal.close();
          }
        else{
this.cartdata =[];
this.total_tax  ="0.00" 
this.dataslist ="";
    Swal.close();
        }
//    this.offset_count = list_data.offset;
//         this.paginationData = this.serverService.pagination({'offset':response.Offset, 'total':response.Total, 'page_limit' :this.pageLimit });
//          console.log(this.paginationData);
//                 this.recordNotFound = this.prepaidnotedata.length == 0 ? true : false;


      
      }, 
      (error)=>{
          console.log(error);
      });

}








}
