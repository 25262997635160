import { Component, OnInit } from '@angular/core';
import { ServerService } from '../services/server.service';
import { HttpParams, HttpClient } from '@angular/common/http';
import * as Feather from 'feather-icons';
import Swal from 'sweetalert2'
declare var iziToast:any;
declare var $:any;
import { Router,ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-ocbc-success',
  templateUrl: './ocbc-success.component.html',
  styleUrls: ['./ocbc-success.component.css']
})
export class OcbcSuccessComponent implements OnInit {
  invoice_no;
  param1;
  param2;
  show_failed = false;
  show_success = false;
  constructor(private route:ActivatedRoute,private router:Router,private serverService: ServerService,private http:HttpClient) { 
    let decode1;
    let decode2;

    this.route.queryParams.subscribe(params => {
      decode1= params['response'];
      decode2  = params['txn_status'];
          console.log(decode1);
          console.log(decode2);

        this.param2 = decode2;

    });

    // this.param1 = atob(atob(decode1));
    // http://localhost:4200/#/ocbc_success?response=NTMzMS9wb3J0YWxfbGljZW5zZV9rZXkvNDYwNjkvU0dELzE5Mjg3Ly9vY2JjX3Nn&txn_status=Umc9PQ==

    if(this.param2 != '' && this.param2 != undefined && this.param2 != null){
     this.param2 = atob(atob(decode2));
    }
    console.log(this.param2);

    if(this.param2 == 'S'){
        this.show_failed = false;
        this.show_success = true;
      this.OcbcTransactionSuccess(decode1);

     }else{
      this.show_failed = true;
      this.show_success = false;
     }
    // console.log(this.param1);
    // console.log(this.param2);

  }

  ngOnInit(): void {

    // let params = new HttpParams();

//     console.log('params');
//     console.log(params);
//  // var myRequest = new Request('');

//     // var myHeaders = new Headers();
//     // myHeaders.append('Content-Type', 'image/jpeg');
    
//     // var myInit = { method: 'GET',
//     //                headers: myHeaders,
//     //                mode: 'cors',
//     //                cache: 'default' };
    
//     // var requests = new Request('/',myInit);

//     // https://epayment.ocbc.com/BPG/admin/payment/PaymentWindow.jsp
//     // https://epayment.ocbc.com/BPG/admin/payment/PaymentWindowInterface.jsp
//     const myRequest = new Request('https://epayment.ocbc.com/BPG/admin/payment/PaymentWindow.jsp', {method: 'POST', headers: {
//       'Content-Type': 'application/json',
//       'Accept': 'application/json'
//     }, body: '{"foo": "bar"}'});

//     // fetch(myRequest).then(function(response) {
//     //   return response.blob();
//     // }).then(function(response) {
//     //   var objectURL = URL.createObjectURL(response);
//     //   console.log(response);
//     //   console.log(objectURL);
//     //   // myImage.src = objectURL;
//     // });
    
//   //   var self =this;
//     fetch(myRequest).then(response => {
//       console.log('post');
//     console.log(response);
//     if (response.status === 200) {
//       // self.OcbcTransactionSuccess(response)
//       // return response.json();
//     } else {
//       throw new Error('Something went wrong on api server!');
//     }
//   })
//   .then(response => {
//     console.debug(response);
//     // ...
//   }).catch(error => {
//     console.error(error);
//   });

//   let headers = new Headers();

//   headers.append('Content-Type', 'application/json');
//   headers.append('Accept', 'application/json');

//   const mygetRequest = new Request('https://epayment.ocbc.com/BPG/admin/payment/PaymentWindow.jsp', {method: 'GET',   headers: headers});
  
//   fetch(mygetRequest).then(response => {
//     console.log('get');
//     console.log(response);
//     if (response.status === 200) {
//       // self.OcbcTransactionSuccess(response)
//       // return response.json();
//     } else {
//       throw new Error('Something went wrong on api server!');
//     }
//   })
//   .then(response => {
//     console.debug(response);
//     // ...
//   }).catch(error => {
//     console.error(error);
//   });




//   this.http.get<any>('/').subscribe(data => {
//     console.log('get1')
//     console.log(data);
//   })        
  
//   this.http.get<any>('https://epayment.ocbc.com/BPG/admin/payment/PaymentWindow.jsp').subscribe(data => {
//     console.log('get2')
//       console.log(data);
// })        
  

  }



  OcbcTransactionSuccess(val){
  
    Swal.fire('Please wait')
    Swal.showLoading()
    
      let datas:any =new Object();
     let datasend:any = new Object();
      let access_token: any=localStorage.getItem('at');
      let customer_id: any=localStorage.getItem('en');
       
       
    datas.action="transaction_success_ocbc";
    datas.response = val;
    // datas.payerids = payerid;
  
    
     datasend.access_token=access_token;
      datasend.operation="curlDatas";
      datasend.moduleType="payment";
      datasend.api_type="web";
      datasend.element_data = datas;
      
          this.serverService.sendServer(datasend).subscribe((response: any) => {
    console.log(response)
            if(response.status=="true"){
                Swal.close();
             this.invoice_no = response.invoice_no;
    
    
        let licdatas:any =new Object();
        let licdatasend:any = new Object();
        licdatas.action="angular_license_order_purchase";
        licdatas.billing_id = response.bill_id;
        licdatas.payment_order = 'yes';
    
        licdatasend.access_token=access_token;
        licdatasend.operation="curlDatas";
        licdatasend.moduleType="payment";
        licdatasend.api_type="web";
        licdatasend.element_data = licdatas;
          
          this.serverService.sendServer(licdatasend).subscribe((responses: any) => {
    
            console.log(responses);
               
          }, 
          (error)=>{
              console.log(error);
          });
        
              }
            else{
              Swal.close();
    
     
          }
    
          
          }, 
          (error)=>{
              console.log(error);
          });
    }


}
