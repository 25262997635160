<section class="section">
<ul class="breadcrumb breadcrumb-style ">
<li class="breadcrumb-item">
<h4 class="page-title m-b-0">Extension Name Details</h4>
</li>
<li class="breadcrumb-item">
<a routerLink="/dashboard">
<i data-feather="home"></i></a>
</li>
<li class="breadcrumb-item active">Extension Name Details</li>
</ul>
<div class="section-body">
<div class="row">
<div class="col-12">
<div class="card card-primary">
<div class="card-header">
<h4>Extension Name Details</h4>
<div class="card-header-action">
<a data-toggle="collapse" class="btn btn-icon btn-info" href="#tableCollapse">
<i class="fas fa-minus"></i></a>
</div>
</div>
<div class="collapse show" id="tableCollapse">
<div class="card-body">
 <div class="form-group row mb-4">
                  <div class="col-sm-12 col-md-7">
                  <button type="button"  class="btn btn-primary" (click)="newextension()">
        <i class="fas fa-plus-circle"></i>
                  Add Extn Name</button>
                  </div>
               </div>

<div class="table-responsive">
<table class="table table-striped" >
  <!-- id="tableExport" -->
<thead>
<tr>
<th>Extn No</th>
<th>Extn Name</th>
<th>Action</th>
</tr>
</thead>
<tbody>


<tr  *ngFor ="let l of allextensions;let i = index;">
<td>{{l.extn_no}}</td>
<td>{{l.extn_name}}</td>

<td class="action-btn-group">

<a (click)="editextension(l.extn_name_id)" data-toggle="tooltip" title="Edit" class="btn btn-warning">
<i class="far fa-edit"></i></a>



<a  (click)="deleteaction(l)" data-toggle="tooltip" title="Delete" class="btn btn-danger">
<i class="far fa-trash-alt"></i>
</a>
</td>
</tr>






</tbody>

<!-- 
 <tr *ngIf="recordNotFound == true">
                 <td colspan="12">No Record Found</td>
               </tr>
 -->

</table>
</div>


<!-- <div class="card-footer text-right" *ngIf="recordNotFound == false">
  
              <nav class="d-inline-block">
                <ul class="pagination mb-0" *ngIf="paginationData.info == 'show'">

                  <li class="page-item">
                    <a [class]="paginationData.backtab=='show' ? 'page-link' :'page-link disabled'" href="javascript:void(0)" (click)="paginationData.backtab=='show' ? licenselist({'search_text':'','offset':paginationData.start}) : false"><i class="fas fa-angle-left"></i></a>
                  </li>
                  <li class="page-item" *ngIf="paginationData.backtab =='show'">
                    <a class="page-link" href="javascript:void(0)" (click)="licenselist({'search_text':'','offset':paginationData.back})"><i class="fas fa-angle-double-left"></i></a>
                  </li>


                  <li [class]="pagination.load ==false ? 'page-item active' :'page-item'" *ngFor="let pagination of paginationData.data;let i=index">
                    <a class="page-link" href="javascript:void(0)" (click)=" pagination.load ==true ? licenselist({'search_text':'','offset':pagination.offset_count}) : false">{{pagination.btn_length}} <span class="sr-only" *ngIf="pagination.load ==false">(current)</span></a>
                  </li>

                  <li class="page-item" *ngIf="paginationData.nexttab =='show'">
                    <a class="page-link" href="javascript:void(0)" (click)="licenselist({'search_text':'','offset':paginationData.next})"><i class="fas fa-angle-double-right"></i></a>
                  </li>

                  <li class="page-item">
                    <a [class]="paginationData.nexttab =='show' ? 'page-link' : 'page-link disabled'" href="javascript:void(0)" (click)="paginationData.nexttab =='show' ? licenselist({'search_text':'','offset':paginationData.end}) : false"><i class="fas fa-angle-right"></i></a>
                  </li>
                </ul>
              </nav>
            </div>  -->





</div>
</div>
</div>
</div>
</div>
</div>
</section>