import { Component, OnInit } from '@angular/core';
import { ServerService } from '../services/server.service';
import { Router, ActivatedRoute } from '@angular/router';

declare var iziToast: any;
declare var $: any;
import Swal from 'sweetalert2'


@Component({
  selector: 'app-credits-payment',
  templateUrl: './credits-payment.component.html',
  styleUrls: ['./credits-payment.component.css']
})
export class CreditsPaymentComponent implements OnInit {
  page: any;
  selectpayment;
  paymentarray: any = [];
  transactioncharge = "0.00";
  cartdata;
  item_number;
  datalist;
  total_amount;
  discount_price;

  topup_name;
  topup_qty;
  topup_amount;
  currency;
  payment_type;
  topup_tax_amt;
  topup_tax_per;
  topup_tax_Total;
  invoice_id;
  credit_data;
  topup_customer_name;
  constructor(private route: ActivatedRoute, private router: Router, private serverService: ServerService) {

    this.route.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation().extras.state) {

        this.page = this.router.getCurrentNavigation().extras.state.page;

        console.log(this.page);
      }
    });

  }

  ngOnInit(): void {
    this.topup_list();
    this.get_currency();
  }

topup_list(){
  let access_token: any = localStorage.getItem('at');
  let customer_id: any = localStorage.getItem('en');
  let data:any = new Object(); 
  let datas:any = new Object();

  data.action = "topup_list";
  data.customer_id = customer_id;
  datas.access_token = access_token;
  datas.operation = "curlDatas";
  datas.moduleType = "login";
  datas.api_type = "web";
  datas.element_data = data;
  console.log(datas)
  this.serverService.sendServer(datas).subscribe((res:any)=>{
    console.log(res)
    if(res.status=="true"){
      this.topup_name = res[0].topup_name;
      this.topup_qty = res[0].qty;
      this.topup_customer_name = res.tax_details.customer_name;
      this.topup_amount = res[0].topup_amount;
      this.topup_tax_amt = res.tax_details.tax_amt;
      this.topup_tax_per = res.tax_details.percent_val;
      this.topup_tax_Total = res.tax_details.net_amt;
      console.log(this.topup_qty)
      console.log(this.total_amount)
    }
  })
}
get_currency(){
  let access_token: any = localStorage.getItem('at');
  let customer_id: any = localStorage.getItem('en');
  let data:any = new Object(); 
  let datas:any = new Object();

  data.action = "topup_payment_gateway_currency";
  data.customer_id = customer_id;
  datas.access_token = access_token;
  datas.operation = "curlDatas";
  datas.moduleType = "login";
  datas.api_type = "web";
  datas.element_data = data;
  console.log(datas)
  this.serverService.sendServer(datas).subscribe((res:any)=>{
    console.log(res)
    if(res.status=="true"){
      this.currency = res.currency_name;
      var payment_type = res.payment_gateway[0];
      console.log(this.payment_type)
      this.payment_type = payment_type.split(','); 
      console.log(this.payment_type)
      for (var i = 0; i < this.payment_type.length; i++) {


        this.paymentarray.push({
          name: "",
          showvalue: this.payment_type[i]
        }
        )

      }


      console.log(this.paymentarray);
      for (var i = 0; i < this.paymentarray.length; i++) {

        this.paymentarray[i].show = true;
        console.log(this.paymentarray[i].showvalue)
        if (this.paymentarray[i].showvalue == "paypal_credit_debit") {
          this.paymentarray[i].name = "Credit or Debit Card (PayPal)"
          this.paymentarray[i].value = "payment_type_paypal_checkout"

        } else if (this.paymentarray[i].showvalue == "ocbc_sg_payment") {
          this.paymentarray[i].name = "Credit Card / Debit Card"
          this.paymentarray[i].value = "payment_type_ocbc_sg_payment"

        } else if (this.paymentarray[i].showvalue == "stripe_pay") {
          this.paymentarray[i].name = "Stripe"
          this.paymentarray[i].value = "payment_type_stripe_pay"
        } else if (this.paymentarray[i].showvalue == "payoffline") {
          this.paymentarray[i].name = "Pay Offline"
          this.paymentarray[i].value = "payment_type_offline"
        }
        else if (this.paymentarray[i].showvalue == "ocbc_my_payment") {
          this.paymentarray[i].name = "Credit Card / Debit Card"
          this.paymentarray[i].value = "payment_type_ocbc_my_payment"

        }




      }
    }
  })
}
transactioncharges(val){
  
  this.selectpayment = val;
  console.log(this.selectpayment)
  
  for (var i = 0; i < this.paymentarray.length; i++) {
    if(this.paymentarray[i].value == val){
    this.paymentarray[i].show = true;
  
    }
    else{
    this.paymentarray[i].show = false;
  
    }
  }
  
  
    let datas:any =new Object();
   let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
     
     
  datas.action="transaction_charges";
  datas.customer_id = customer_id;
  datas.currency_name = this.currency;
  datas.selected_rdo =val;
  datas.total_amount = this.topup_tax_Total;
  
   datasend.access_token=access_token;
    datasend.operation="curlDatas";
    datasend.moduleType="payment";
    datasend.api_type="web";
    datasend.element_data = datas;
    Swal.fire('Please wait')
  Swal.showLoading()
        this.serverService.sendServer(datasend).subscribe((response: any) => {
  console.log(response)
          if(response.status=="true"){
            Swal.close();
            this.transactioncharge =response.tansaction_charges;
            this.total_amount = response.total_amount;
            // if(this.couponcode != "" && this.couponcode != undefined){
            //   this.couponcode_verify()
            // }
      
            }
          else{
            Swal.close();
  
   
        }
  
        
        }, 
        (error)=>{
            console.log(error);
        });
  }
submitpaymentangular(){
 if(this.selectpayment == '' || this.selectpayment == undefined){
      iziToast.error({
          message: "Please Select any payment",
          position: 'topRight'
      });
      return false;
  }


  let datas:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
//    if(this.couponcode == '' || this.couponcode == undefined ){
// datas.coupon_code = '';
// }else{
//   datas.coupon_code = this.couponcode;
// }

   
datas.action="cms_topup_invoice_generate";
datas.customer_id = customer_id;
// datas.customer_discount =this.datalist.Customer_3cx_data.Reseller_dis_per;
datas.select_pay =this.selectpayment;
// datas.coupon_code = this.couponcode;

 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="login";
  datasend.api_type="web";
  datasend.element_data = datas;
  Swal.fire('Please wait')
Swal.showLoading()
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response && response.status=="true"){
         Swal.close();
     // 
this.invoice_id = response.invoice_id;
this.credit_data = response.credit_data;

console.log(this.selectpayment)
setTimeout (() => {
                   if (this.selectpayment  == 'payment_type_stripe_pay') {
 this.addpaymentresponsedata(response.invoice_id,"stripe")
                            } else if (this.selectpayment  == 'payment_type_paypal_checkout') {
 this.addpaymentresponsedata(response.invoice_id,"paypal")
                          }  else if (this.selectpayment  == 'payment_type_ocbc_sg_payment') {
                        this.addpaymentresponsedata(response.invoice_id,"ocbc_sg")
                         } else if (this.selectpayment  == 'payment_type_ocbc_my_payment') {
                          this.addpaymentresponsedata(response.invoice_id,"ocbc_myr")
                          }
                          else if (this.selectpayment == 'payment_type_offline') {
                              $('#licenseKeyGenerate').submit();
                          }

 }, 2000);

 // setTimeout (() => {
   

 // Swal.close();



// if (this.selectpayment == 'payment_type_offline') {

//                                 $('#licenseKeyGenerate').submit();
//                             }

                  
//                    else if (this.selectpayment  == 'payment_type_stripe_pay') {

//                                 $('#stripePay_frm').submit();
//                             }
                                    
//                              else if (this.selectpayment  == 'payment_type_ocbc_sg_payment') {

//                                 $('#ocbc_sg').submit();

//                             }

//           else if (this.selectpayment  == 'payment_type_ocbc_my_payment') {

//                                 $('#ocbc_my').submit();

//                             }
                    

                
//                              else if (this.selectpayment  == 'payment_type_paypal_checkout') {


//                                 $('#payPaylPayment').submit();
//                             }

                           

               
//       }, 2000);


          }
        else{
          // Swal.close();
  iziToast.error({
          message: "Please try again later",
          position: 'topRight'
      });
 
      }

      
      }, 
      (error)=>{
          console.log(error);
      });
}
  // transactioncharges(val) {

  //   this.selectpayment = val;
  //   console.log(this.selectpayment)

  //   for (var i = 0; i < this.paymentarray.length; i++) {
  //     if (this.paymentarray[i].value == val) {
  //       this.paymentarray[i].show = true;

  //     }
  //     else {
  //       this.paymentarray[i].show = false;

  //     }
  //   }


  //   let datas: any = new Object();
  //   let datasend: any = new Object();
  //   let access_token: any = localStorage.getItem('at');
  //   let customer_id: any = localStorage.getItem('en');


  //   datas.action = "transaction_charges";
  //   datas.customer_id = customer_id;
  //   datas.currency_name = "";   // NEED TO GIVE THE API BY PN 
  //   datas.selected_rdo = val;
  //   datas.total_amount = "";

  //   datasend.access_token = access_token;
  //   datasend.operation = "curlDatas";
  //   datasend.moduleType = "payment";
  //   datasend.api_type = "web";
  //   datasend.element_data = datas;
  //   Swal.fire('Please wait')
  //   Swal.showLoading()
  //   this.serverService.sendServer(datasend).subscribe((response: any) => {
  //     console.log(response)
  //     if (response.status == "true") {
  //       Swal.close();

  //       this.transactioncharge = response.tansaction_charges;

  //       // if(this.couponcode != "" && this.couponcode != undefined){
  //       //   this.couponcode_verify()
  //       // }

  //     }
  //     else {
  //       Swal.close();


  //     }


  //   },
  //     (error) => {
  //       console.log(error);
  //     });
  // }

  addpaymentresponsedata(val,name){
  
    // Swal.fire('Please wait')
    // Swal.showLoading()
    
      let datas:any =new Object();
     let datasend:any = new Object();
      let access_token: any=localStorage.getItem('at');
      let customer_id: any=localStorage.getItem('en');
       
       
    datas.action="add_payment_response_data";
    datas.order_id = val;
    datas.currency_code = this.currency;
    datas.payment_from ="portal_call_topup";
    datas.gateway =name;
    
     datasend.access_token=access_token;
      datasend.operation="curlDatas";
      datasend.moduleType="payment";
      datasend.api_type="web";
      datasend.element_data = datas;
      
          this.serverService.sendServer(datasend).subscribe((response: any) => {
    console.log(response)
    
            if(response.status=="true"){
              // this.payment_response_id = response.payment_response_id;
              // this.payment_invoice_no = response.invoice_no;
              // this.payment_invoice_no 
              // Swal.close();
              // this.transactioncharge =response.tansaction_charges;
              // this.total_amount = response.total_amount;
              // if(this.couponcode != "" && this.couponcode != undefined){
              //   this.couponcode_verify()
              // }
        this.globalpayment(response,name)
              }
            else{
              // Swal.close();
    
     
          }
    
          
          }, 
          (error)=>{
              console.log(error);
          });
    }

    globalpayment(val,name){

      let datas:any =new Object();
     let datasend:any = new Object();
        let access_token: any=localStorage.getItem('at');
        let customer_id: any=localStorage.getItem('en');
       
       
    datas.action="global_payment";
    datas.from_site = "FromCMS";
    datas.gateway = name;
    datas.customer_id = customer_id;
    datas.payment_from = "portal_call_topup";
    
    
    datas.invoice_id = val.invoice_no;
    datas.payment_response_id = val.payment_response_id;
    datas.credit_data = this.credit_data ;
    datas.amount = val.amount;
    datas.currency = val.currency_code;
    datas.acc_num = val.client_id;
    datas.user_key_pass = val.user_key_pass;
    datas.merchat_id = val.merchat_id;
    datas.session_id = val.session_id;
    datas.secret_key = val.secret_key;
    datas.customerAddress1 = val.customerAddress1;
    datas.customerAddress2 = val.customerAddress2;
    datas.customerName = val.customerName;
    datas.transaction_date = val.transaction_date;
    // datas.name = this.datalist.Customer_data.customerName;
    datas.name = this.topup_customer_name;
    datas.description = "Invoice From Cal4care";
    // datas.success_url = "https://erp.cal4care.com/cms_new/test_success.php";
    // datas.cancel_url = "https://erp.cal4care.com/cms_new/test_success.php";
    // datas.success_url = "http://umbrellapro.xyz/cms_angular/#/paysuccess";
    // datas.cancel_url = "http://umbrellapro.xyz/cms_angular/#/paysuccess";
    console.log(name)
    if(name =='ocbc_sg' || name == 'ocbc_myr'){
    // datas.success_url = "https://erp.cal4care.com/cms_new/#/ocbc_success";
    // datas.cancel_url = "https://erp.cal4care.com/cms_new/#/ocbc_success";
     datas.success_url = "https://erp.cal4care.com/cms_new/success_msg.php";
     datas.cancel_url = "https://erp.cal4care.com/cms_new/success_msg.php";
    }else{
    datas.success_url = "https://erp.cal4care.com/cms_new/#/call_topup_payment";
    datas.cancel_url = "https://erp.cal4care.com/cms_new/#/call_topup_payment";
    // datas.success_url = "http://localhost:4200//#/call_topup_payment";
    // datas.cancel_url = "http://localhost:4200//#/call_topup_payment";
    }
    // http://umbrellapro.xyz/cms_angular/#/login
     datasend.access_token=access_token;
      datasend.operation="global_payment_curl";
      datasend.moduleType="payment";
      datasend.api_type="web";
      datasend.element_data = datas;
    
          this.serverService.sendServer(datasend).subscribe((response: any) => {
            if(response.result && response.result.status==true){
              console.log(response);
         // this.allextensions = response.CMS_extension
      Swal.close();
    
      //  window.open("https://www.youraddress.com","_self")
    //   const interval = window.setInterval(() => {
    //     window.open(response.result.data,"_self");
    // }, 3000); 
      // window.open(response.result.data,"_self");
       setTimeout (() => {
     window.open(response.result.data,"_self");
           }, 3000);
              }
              else{
    Swal.close();
              }
          
          }, 
          (error)=>{
              console.log(error);
          });
    }
  getvalue() {
    for (var i = 0; i < this.paymentarray.length; i++) {
      this.paymentarray[i].show = true;
    }
    console.log(this.selectpayment)
  }



  // getcartData() {
  //   Swal.fire('Please wait')
  //   Swal.showLoading()
  //   let datas: any = new Object();
  //   let datasend: any = new Object();
  //   let access_token: any = localStorage.getItem('at');
  //   let customer_id: any = localStorage.getItem('en');


  //   datas.action = "";
  //   datas.customer_id = customer_id;

  //   datasend.access_token = access_token;
  //   datasend.operation = "curlDatas";
  //   datasend.moduleType = "login";
  //   datasend.api_type = "web";
  //   datasend.element_data = datas;
  //   this.serverService.sendServer(datasend).subscribe((response: any) => {
  //     console.log(response)
  //     if (response.status == "true") {
  //       Swal.close();

  //       this.cartdata = response.options;
  //       this.item_number = this.cartdata.length;
  //       this.datalist = response;

  //       this.total_amount = this.datalist.grand_total_value
  //       this.discount_price = this.datalist.total_discount_value


  //       for (var i = 0; i < this.datalist.payment_gateway.length; i++) {


  //         this.paymentarray.push({
  //           name: "",
  //           showvalue: this.datalist.payment_gateway[i]
  //         }
  //         )

  //       }

  //       // =========== ABOVE API SHOULD GIVE THE VALUE FOR THE PAYMENT BY PN

  //       for (var i = 0; i < this.paymentarray.length; i++) {

  //         this.paymentarray[i].show = true;
  //         if (this.paymentarray[i].showvalue == "paypal_credit_debit") {
  //           this.paymentarray[i].name = "Credit or Debit Card (PayPal)"
  //           this.paymentarray[i].value = "payment_type_paypal_checkout"

  //         } else if (this.paymentarray[i].showvalue == "ocbc_sg_payment") {
  //           this.paymentarray[i].name = "Credit Card / Debit Card"
  //           this.paymentarray[i].value = "payment_type_ocbc_sg_payment"

  //         } else if (this.paymentarray[i].showvalue == "stripe_pay") {
  //           this.paymentarray[i].name = "Stripe"
  //           this.paymentarray[i].value = "payment_type_stripe_pay"
  //         } else if (this.paymentarray[i].showvalue == "payoffline") {
  //           this.paymentarray[i].name = "Pay Offline"
  //           this.paymentarray[i].value = "payment_type_offline"
  //         }
  //         else if (this.paymentarray[i].showvalue == "ocbc_my_payment") {
  //           this.paymentarray[i].name = "Credit Card / Debit Card"
  //           this.paymentarray[i].value = "payment_type_ocbc_my_payment"

  //         }




  //       }

  //     }


  //   },
  //     (error) => {
  //       console.log(error);
  //     });

  // }


}
