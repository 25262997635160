import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ServerService } from '../services/server.service';
declare var iziToast:any;
import Swal from 'sweetalert2'
declare var $:any;
import * as Feather from 'feather-icons';
@Component({
  selector: 'app-deal-register-form',
  templateUrl: './deal-register-form.component.html',
  styleUrls: ['./deal-register-form.component.css']
})
export class DealRegisterFormComponent implements OnInit {
information: FormGroup;

  constructor(private serverService: ServerService) { }

  ngOnInit(): void {

 this.information = new FormGroup({
      'partner_email' : new FormControl(null,[
    Validators.required,
    Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),
      'partner_name' : new FormControl(null,Validators.required),
      'prod_cloud1' : new FormControl(null),
      'prod_cloud2' : new FormControl(null),
      'prod_cloud3' : new FormControl(null),
      
      'phone_number' : new FormControl(null,Validators.required),

      'deal_size' : new FormControl(null,Validators.required),
   
      'end_user_name' : new FormControl(null,Validators.required),


      'end_user_email' : new FormControl(null),
      'end_user_phone_no' : new FormControl(null,Validators.required),

      
      'expected_deal_closer' : new FormControl(null),

      'about_deal' : new FormControl(null),

     });

 this.information.patchValue({
  prod_cloud1:false,
  prod_cloud2:false,
 prod_cloud3 :false
});

  }

validateAllFields(formGroup: FormGroup) {         
        Object.keys(formGroup.controls).forEach(field => {  
            const control = formGroup.get(field);            
            if (control instanceof FormControl) {             
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {        
                this.validateAllFields(control);  
            }
        });
    }

closing(){
   $('#addNewPopups').modal('hide');
   $('body').removeClass('modal-open');
   $('.modal-backdrop').remove();
}
ngAfterViewInit() {
    Feather.replace();
  }

finishFunction(){
  if(this.information.value.prod_cloud1 == false && this.information.value.prod_cloud2 == false && this.information.value.prod_cloud3 == false){
console.log("error")
 iziToast.error({
          message: "Please select one product",
          position: 'bottomLeft'
      });
return false
  }


  console.log(this.information.value)
   if(this.information.valid){
   
     this.dealregister()
   }
   else {

        this.validateAllFields(this.information); 
        }
  
}



  dealregister(){

console.log(this.information.value)
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
        let Formss:any = this.information.value;
        let api_req:any = new Object();
        Formss.customer_id = customer_id;
          Formss.action="add_deal_registration";

         api_req.access_token=access_token;
          api_req.operation="curlDatas";
          api_req.moduleType="login";
          api_req.api_type="web";
          api_req.element_data=Formss;
         
 if(this.information.value.prod_cloud1 == true){
 Formss.prod_cloud = "Cloud"
  }else{
 Formss.prod_cloud = ""
  }

  if(this.information.value.prod_cloud2 == true){
 Formss.prod_3cx = "3cx"
  }else{
 Formss.prod_3cx = ""
  }

   if(this.information.value.prod_cloud3 == true){
 Formss.prod_mrvoip = "mrvoip"
  }else{
 Formss.prod_mrvoip = ""
  }


 if(this.information.value.prod_cloud3 == null){
 Formss.prod_mrvoip = "mrvoip"
  }else{
 Formss.prod_mrvoip = ""
  }

console.log(api_req)
 Swal.fire('Please wait')
Swal.showLoading()
  this.serverService.sendServer(api_req).subscribe((response:any) => {
console.log(response)
   if(response.status == "true" ){
       $('#addNewPopups').modal('hide');
 Swal.close();
  iziToast.success({
          message: "Register Successfully",
          position: 'topRight'
      });

        }
       else{
 Swal.close();
       iziToast.error({
          message: "Please Try Again Later",
          position: 'topRight'
      });
                        }

                    }, 
                    (error)=>{
                        console.log(error);
                    });
    

}



}
