import { Component, OnInit } from '@angular/core';
import { interval, Subscription } from 'rxjs';
declare const $: any;
import * as Feather from 'feather-icons';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})

export class FooterComponent implements OnInit {

  public qrdata: string ="https://play.google.com/store/apps/details?id=com.erpcustomer" ;
  public qrdata2: string ="https://apps.apple.com/us/app/cal4care-cms-customer/id1521608417";
  public level ="M";
  public level2 ="M";

  public widthios: number=230;
  public widthandroid: number=230;
  public widths: number;
  public qrdatauser: string;
  subscription: Subscription;
 tab: number = 1;
 year;
  constructor() { }

  ngOnInit(): void {
this.subscribes();

  }
ngAfterViewInit() {
    Feather.replace();
  }
unsubscribe(){
this.subscription.unsubscribe();
}
subscribes(){
 this.qrcodes()
const source = interval(300000);
this.subscription = source.subscribe(val => 
  this.qrcodes()
  );
 
}

qrcodes(){
    let customer_id: any=localStorage.getItem('en');

this.level = "M"
 this.widths=230;
 var types =btoa('cms')
 const data = [{
    'type': types,
    'en': customer_id,
  }]
  var date = new Date();
  this.year = date.getFullYear();
  console.log(this.year)
  this.qrdatauser = JSON.stringify(data);
  console.log(this.qrdata)
  var hh =  JSON.parse(this.qrdata)
  console.log(hh)
  
   }





getandroid(){

 $('#androidapp').modal({
   show:true,
   backdrop:false
});
$('#androidapp').modal('show');

}

getios(){
  
   $('#iosapp').modal({
   show:true,
   backdrop:false
});
$('#iosapp').modal('show');

}

 openForm() {
  document.getElementById("myForm").style.display = "block";
}

closeForm() {
  document.getElementById("myForm").style.display = "none";
}

 openForm2() {
  document.getElementById("myForm2").style.display = "block";
}

closeForm2() {
  document.getElementById("myForm2").style.display = "none";
}


}
