<section class="section">
<ul class="breadcrumb breadcrumb-style ">
<li class="breadcrumb-item">
<h4 class="page-title m-b-0">Extension</h4>
</li>
<li class="breadcrumb-item">
<a routerLink="/dashboard">
<i data-feather="home"></i></a>
</li>
<!-- <li class="breadcrumb-item active">Search call history</li> -->
</ul>
<div class="section-body">
<div class="row">
<div class="col-12">
<div class="card card-primary">
<div class="card-header">

<h4 *ngIf="page =='add'">Add Extension Name</h4>
<h4 *ngIf="page == 'edit'">User Edit</h4>

<div class="card-header-action">
<a  data-toggle="collapse" class="btn btn-icon btn-info" href="#tableCollapse">
<i class="fas fa-minus"></i></a>
</div>

</div>
<div class="collapse show" id="tableCollapse">
<div class="card-body">
<div class="min-form-width">

<form [formGroup]="getdata">

<div class="form-group">
<label>Extension No</label>
<select class="form-control" formControlName="extensionselect">

<option value="">All</option>

<option  *ngFor="let l of extnsiondata" value="{{l.value}}">{{l.value}}</option>
</select>
<div class="error-message message-small" *ngIf="!getdata.get('extensionselect').valid && getdata.get('extensionselect').touched">
Please Select Extension No
</div>
</div>



<div class="form-group">
<label >Extension Name *</label>
<!-- <div class="input-group"> -->
	<input type="text" class="form-control" formControlName="ex_name" >
	<div class="error-message message-small" *ngIf="!getdata.get('ex_name').valid && getdata.get('ex_name').touched">
Please enter Extension Name
  </div>
<!-- </div> -->
</div>



<div class="fl mb-4">
<a *ngIf="page == 'add'" (click)="addextension()"  class="btn btn-success" >Save</a>

<a *ngIf="page == 'edit'" (click)="update()"  class="btn btn-success" >Update</a>
</div>
</form>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</section>