<section class="section payment-section">
    <h4 class="page-title m-b-0">Credits Payment Details</h4>

    <div class="section-body">
        <div class="row">
            <div class="col-12">
                <div class="card card-primary">
                    <div class="card-header">
                        <h4> Credits Payment Details</h4>
                        <div class="card-header-action">
                            <a data-toggle="collapse" class="btn btn-icon btn-info" href="#tableCollapse">
                                <i class="fas fa-minus"></i></a>
                        </div>
                    </div>
                    <div class="collapse show" id="tableCollapse">
                        <div class="card-body">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                               

                                        <!-- <th>Code</th> -->
                                        <!-- <th></th> -->
                                        <th>Name</th>
                                        <!-- <th></th> -->
                                        <!-- <th>Price(  )</th> -->
                                        <th>Qty</th>
                                        <th></th>
                                        <th>Amount ( {{currency}} )</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <!-- <tr>

                                        <td style="text-align: center !important;"> </td>
                                        <td> </td>
                                        <td style="text-align: right !important;"> </td>
                                        <td style="text-align: center !important;"> </td>
                                        <td style="text-align: center !important;">
                                            </td>
                                        <td style="text-align: right !important;"><b> </b></td>


                                    </tr> -->



                                    <tr>
                                        <!-- <td></td> -->
                                        <td style="text-align: center !important;">{{topup_name}}</td>
                                        <!-- <td></td> -->
                                        <td style="text-align: center !important;">{{topup_qty}}</td>
                                        <td colspan="1" style="text-align: right !important"><strong
                                                style="font-weight:500"></strong></td>
                                        <td style="text-align: right !important;"> {{topup_amount}} </td>
                                    </tr>
                                    <tr>
                                        <!-- <td></td> -->
                                        <td style="text-align: center !important;"></td>
                                        <!-- <td></td> -->
                                        <td style="text-align: center !important;"></td>
                                        <td colspan="1" style="text-align: right !important"><strong
                                                style="font-weight:500">Net Total</strong></td>
                                        <td style="text-align: right !important;"> {{topup_amount}} </td>
                                    </tr>
                                    <!-- <tr>
                                        <td></td>
                                        <td></td>
                                        <td colspan="1" style="text-align: right !important"><strong
                                                style="font-weight:500" >Discount
                                                Price( %)</strong>
                                            <strong style="font-weight:500" >Discount
                                                Price</strong>
                                        </td>

                                        <td style="text-align: right !important;"> </td>
                                    </tr> -->
                                    <tr>
                                        <td></td>
                                        <!-- <td></td> -->
                                        <td></td>
                                        <td colspan="1" style="text-align: right !important"><strong
                                                style="font-weight:500">Tax Value<span
                                                    >({{topup_tax_per}} %)</span></strong>
                                        </td>
                                        <td style="text-align: right !important;">{{topup_tax_amt}} </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <!-- <td></td> -->
                                        <td></td>
                                        <td colspan="1" style="text-align: right !important"><strong
                                                style="font-weight:500">Transaction Charge</strong></td>
                                        <td style="text-align: right !important;">{{transactioncharge}} </td>
                                    </tr>
                                    <tr *ngIf="this.total_amount==''||this.total_amount==undefined||this.total_amount=='undefined'">
                                        <td></td>
                                        <!-- <td></td> -->
                                        <td></td>
                                        <td colspan="1" style="text-align: right !important"><strong
                                                style="font-weight:500">Grand Total</strong></td>
                                        <td style="text-align: right !important;"> {{topup_amount}}</td>
                                    </tr>
                                    <tr *ngIf="this.total_amount!=''&&this.total_amount!=undefined&&this.total_amount!='undefined'">
                                        <td></td>
                                        <!-- <td></td> -->
                                        <td></td>
                                        <td colspan="1" style="text-align: right !important"><strong
                                                style="font-weight:500">Grand Total</strong></td>
                                        <td style="text-align: right !important;"> {{total_amount}}</td>
                                    </tr>
                                </tbody>
                            </table>

                            <!-- <div class="row">
                                <div class="col-12 col-lg-12">
                                    <div class="payment-card-section">
                                        <div class="card">
                                            <div class="card-head">
                                                <h4>Choose payment method</h4>
                                            </div>
                                            <div *ngFor="let l of paymentarray">
                                                <div  class="payment_card">
                                                    <label>
                                                        <input class="mrg-right5" type="radio"
                                                            [(ngModel)]="selectpayment"
                                                            (click)="transactioncharges(l.value)" name="payment_type"
                                                            id="payment_type_paypal_checkout"
                                                            value="{{l.value}}">{{l.name}}
                                                        <img *ngIf="l.showvalue == 'paypal_credit_debit'"
                                                            src="assets/img/cards/paypal_2.png">
                                                        <img *ngIf="l.showvalue == 'ocbc_sg_payment'"
                                                            src="assets/img/cards/ocbc.png">
                                                        <img *ngIf="l.showvalue == 'ocbc_my_payment'"
                                                            src="assets/img/cards/ocbc.png">
                                                        <img *ngIf="l.showvalue == 'stripe_pay'"
                                                            src="assets/img/cards/powered-by-stripe.png">                                              
                                                    </label>
                                                </div>
                                            </div>



                                            <input type="button" size="10" class="btn btn-warning" value="Reset"
                                                id="reset_button" name="reset_button" (click)="getvalue()">
                                        </div>
                                    </div>
                                </div>
                            </div> -->

                            <div class="row">
                                <div class="col-12 col-lg-12">
                                    <div class="payment-card-section">
                                        <div class="card">
                                            <div class="card-head">
                                                <h4>Choose payment method</h4>
                                            </div>
                                            <div *ngFor="let l of paymentarray" >
                                                <div *ngIf="l.show" class="payment_card">
                                            <label    >
                                                <input  class="mrg-right5" type="radio" [(ngModel)]="selectpayment" (click)="transactioncharges(l.value)"  name="payment_type" id="payment_type_paypal_checkout" value="{{l.value}}" >{{l.name}}
                                        <img *ngIf="l.showvalue == 'paypal_credit_debit'" src="assets/img/cards/paypal_2.png">
                                        <img *ngIf="l.showvalue == 'ocbc_sg_payment'"  src="assets/img/cards/ocbc.png">
                                         <img *ngIf="l.showvalue == 'ocbc_my_payment'"  src="assets/img/cards/ocbc.png">
                                        <img *ngIf="l.showvalue == 'stripe_pay'"  src="assets/img/cards/powered-by-stripe.png">
                                        <img *ngIf="l.showvalue == 'payoffline'"  src="assets/img/cards/offline23.png">
                                            </label>
                                        </div>
                                        </div>


                                     <!--    <label class="payment_card">
                        <input type="radio" name="payment_type" id="payment_type_paypal_checkout" value="payment_type_ocbc_sg_payment" > Credit Card / Debit Card
                                                <img src="assets/img/cards/ocbc.png">
                                            </label> -->
                                           <!--  <label class="payment_card">
                                                <input type="radio" name="payment_type" id="payment_type_paypal_checkout" value="payment_type_stripe_pay" > Stripe
                                                <img src="assets/img/cards/powered-by-stripe.png">
                                            </label> -->
                                            <input type="button" size="10" class="btn btn-warning" value="Reset"  id="reset_button" name="reset_button" (click)="getvalue()">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="submit-btn-group text-center mb-2">
                                <a class="btn btn-warning back-btn" routerLink="/shopping-cart">Back</a>
                                <a class="btn btn-success" (click)="submitpaymentangular()">Submit</a>
                            </div>



                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>