import { Component, OnInit } from '@angular/core';
import { ServerService } from '../services/server.service';
import { Router } from '@angular/router';
import * as Feather from 'feather-icons';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
declare var iziToast:any;
declare var $:any;
import Swal from 'sweetalert2'
import { v4 as uuidv4 } from 'uuid';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  ref_data;
  currency;
  invoice_amount;
  license_count;
    constructor(private router:Router,public serverService: ServerService) {}
  
    ngOnInit(): void {
      if(localStorage.getItem("reload")=='1'){
        localStorage.setItem("reload",'0');
        location.reload();
      }
    this.aatrox_dashboard();
     
    }
    aatrox_dashboard(){
      Swal.fire('Loading Dashboard')
Swal.showLoading()
      let data:any =new Object();
      let datas:any = new Object();
         let access_token: any=localStorage.getItem('at');
         let customer_id: any=localStorage.getItem('en');
      datas.operation = "curlDatas";
      datas.moduleType = "login";
      datas.api_type = "web";
      datas.access_token = access_token;
      data.action = "contact_flag";
      data.customer_id = customer_id;
      data.customer_state = "";
      data.cms_user_id = "";
      datas.element_data = data;
      this.serverService.sendServer(datas).subscribe((res:any)=>{
        if(res.status == "true"){
          Swal.close()
          this.currency = res.currency;
          this.invoice_amount = res.invoice_amount;
          this.license_count = res.license_count;
          this.ref_data = res.referral;
          console.log(this.ref_data)
        }else{
          Swal.close()
        }
      })
    }
  }
  