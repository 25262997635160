<section class="section">
<ul class="breadcrumb breadcrumb-style ">
<li class="breadcrumb-item">
<h4 class="page-title m-b-0">Add Cost Center</h4>
</li>
<li class="breadcrumb-item">
<a routerLink="/dashboard">
<i data-feather="home"></i></a>
</li>
<li class="breadcrumb-item active">Add Cost Center</li>
</ul>
<div class="section-body">
<div class="row">
<div class="col-12">
<div class="card card-primary">
<div class="card-header">
<h4>Add Cost Center</h4>

<div class="card-header-action">
<a data-toggle="collapse" class="btn btn-icon btn-info" href="#tableCollapse">
<i class="fas fa-minus"></i></a>
</div>

</div>
<div class="collapse show" id="tableCollapse">
<div class="card-body">





 <div class="min-form-width" style="max-width: 700px;"> 
<!-- <form [formGroup]="getdata"> -->

<div class="form-group">
<label>Group Name</label>
  <input type="text" class="form-control" id="partner-name"  [(ngModel)]="groupname">
<!-- <div class="error-message message-small" *ngIf="!getdata.get('resellcustomer').valid && getdata.get('resellcustomer').touched">
Please Select Reseller Customer
</div> -->
</div>







<div class="form-group mb-4">
<label>Username</label>
<div style="display: flex;">
<select multiple class="form-control" style="margin-right: 20px;
    height: 300px;" [(ngModel)]="usernameleft">


<option  *ngFor="let l of username" value="{{l}}" >{{l}}</option>
</select>
<div >
	<img src="assets/img/arrows/singler.svg" alt="" style="height: 20px !important;
    margin-bottom: 20px;margin-top: 80px;border: 2px solid;
    padding: 2px;
    border-radius: 15%;" class="pointer" (click)="moveright()">
<img src="assets/img/arrows/singlel.svg" alt="" style="height: 20px !important;margin-bottom: 20px;border: 2px solid;
    padding: 2px;
    border-radius: 15%;" class="pointer" (click)="moveleft()">
<img src="assets/img/arrows/doubler.svg" alt="" style="height: 20px !important;
    margin-bottom: 20px;border: 2px solid;
    padding: 2px;
    border-radius: 15%;" class="pointer" (click)="moverightall()">
<img src="assets/img/arrows/doublel.svg" alt="" style="height: 20px !important;
    margin-bottom: 20px;border: 2px solid;
    padding: 2px;
    border-radius: 15%;"  class="pointer" (click)="moveleftall()">

</div>


<select multiple class="form-control" style="margin-right: 20px;height: 300px;" [(ngModel)]="usernameright">


<option  *ngFor="let l of selectedusername" value="{{l}}" >{{l}}</option>
</select>
</div>


</div>


<!-- <div class="form-group mb-4">
<label>Username</label>

<div class="error-message message-small" *ngIf="!getdata.get('resellcustomer').valid && getdata.get('resellcustomer').touched">
Please Select Reseller Customer
</div>
</div> -->














<div class="fl mb-4">


<a *ngIf='page == "edit"' (click)="updatecost_sender()"  class="btn btn-success" >Submit</a>
<a *ngIf='page == "add"' (click)="add_cost_sender()"  class="btn btn-success" >Submit</a>

</div>
<!-- </form> -->
</div>








</div>
</div>



</div>
</div>
</div>
</div>
</section>