<!---------------------------------
Footer Help Desk
---------------------------------->
<input type="hidden" id="call_history_id" value="0">
<input type="hidden" id="call_customer_key" value="0">
<input type="hidden" id="call_incoming_number" value="">
<input type="hidden" id="outgoing_call_end_trigger" (click)="outgoingCallEnd()">
<input type="hidden" autocomplete="off" id="html5vid">
<input type="hidden" autocomplete="off" id="transferto">
<input type="hidden" autocomplete="off" id="jsepjsep">
<input type="hidden" autocomplete="off" id="incResutl">
<input type="hidden" id="endCallByJs" (click)="outgoingCallEnd()">
<input type="hidden" id="newCallNMumberCamp" >
<input type="hidden" autocomplete="off" id="sip_urld">
<input type="hidden" autocomplete="off" id="outcall_number">
<audio id='incommingCalltone' src='assets/img/incomingcall.mp3' ></audio>
<audio id='ringingTone' src='assets/img/ringbacktone-bckup.mp3' ></audio>
<div id="accept_calls" style="display: none;"></div>

<div class="container" style="display: none;">
    <div class="row"  >
        <div class="col-md-12">
            <div class="page-header">
                <h1>
                </h1>
            </div>
            <div class="container" id="details">
                <div class="row">
                    <div class="col-md-12">
                        
                    </div>
                </div>
            </div>
            <div class="container hide" id="sipcall">
                <div class="row">
                    <div class="col-md-12">
                      
                        </div>
                        <div class="col-md-6 container hide" id="phone" >
                            <div class="input-group margin-bottom-sm">
                                <span class="input-group-addon"><i class="fa fa-phone fa-fw"></i></span>
                                <input class="form-control" type="hidden" autocomplete="off" id="peer" onkeypress="return checkEnter(this, event);" />
                            </div>
                        </div>
                    </div>
                
                <div id="videos" class="hide">
                    <div class="col-md-6">
                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h3 class="panel-title">You</h3>
                            </div>
                            <div class="panel-body" id="videoleft"></div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h3 class="panel-title">Remote UA</h3>
                            </div>
                            <div class="panel-body" id="videoright"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <hr>
    <div class="footer">
    </div>
</div>

<div class="footer-help-desk" (click)="closefunctions()">
	<div class="helpdesk-close"><a href="javascript:;" id="close-helpdesk"><img src="assets/img/custom-images/close.svg"></a></div>
	<ul  class="list-group footer-click-to-call">
		<li class="list-group-item">
			<a href="javascript:;" (click)="webMakeCall(822)">
				<div class="help-desk-icon">
					<span class="footer-helpdesk-white"><img src="assets/img/custom-images/footer-icon/phone-w.svg"></span>
					<span class="footer-helpdesk-blue"><img src="assets/img/custom-images/footer-icon/phone-blue.svg"></span>
				</div>
				<div class="helpdesk-label"><span class="triangle-right"></span> License Support</div>
			</a>
		</li>
		<li class="list-group-item">
			<a href="javascript:;" (click)="webMakeCall(350)">
				<div class="help-desk-icon">
					<span class="footer-helpdesk-white"><img src="assets/img/custom-images/footer-icon/cloud-pbx-w.svg"></span>
					<span class="footer-helpdesk-blue"><img src="assets/img/custom-images/footer-icon/cloud-pbx-blue.svg"></span>
				</div>
				<div class="helpdesk-label"><span class="triangle-right"></span> Cloud PBX / SIP Trunk</div>
			</a>
		</li>
		<li class="list-group-item">
			<a href="javascript:;" (click)="webMakeCall(809)">
				<div class="help-desk-icon">
					<span class="footer-helpdesk-white"><img src="assets/img/custom-images/footer-icon/money-w.svg"></span>
					<span class="footer-helpdesk-blue"><img src="assets/img/custom-images/footer-icon/money-blue.svg"></span>
				</div>
				<div class="helpdesk-label"><span class="triangle-right"></span> Finance Support</div>
			</a>
		</li> 
		<li class="list-group-item">
			<a href="javascript:;" (click)="webMakeCall(828)">
				<div class="help-desk-icon">
					<span class="footer-helpdesk-white"><img src="assets/img/custom-images/footer-icon/sales-w.svg"></span>
					<span class="footer-helpdesk-blue"><img src="assets/img/custom-images/footer-icon/sales-blue.svg"></span>
				</div>
				<div class="helpdesk-label"><span class="triangle-right"></span> Pre Sales</div>
			</a>
		</li> 
	</ul>
	<ul class="hangup_call_btn" id="showWhenInCall" style="display: none;margin-bottom: 0px;margin-top: 5px;">
		<a href="javascript:void(0);" (click)="outgoingCallEnd()"> <span class="sidebar-item-icon"><span class="blue-icon" style="font-size: 16px;
    color: red;"><img src="assets/img/custom-images/phone-call.svg" width="25">Hang Call</span>
		</span></a>
		
	</ul>
</div>
<!---------------------------------
Footer Help Desk End
---------------------------------->