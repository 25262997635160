import {Injectable} from '@angular/core';
import { Component, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders,HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs'
import { Router } from '@angular/router';
declare var iziToast:any;
import * as CryptoJS from 'crypto-js';
@Injectable({
  providedIn: 'root'
})
export class ServerService {
show:Subject<any> = new Subject();
public ci;
public cn;

public invoice = 0;
public prepaidnote = 0;
public creditnote = 0;
public license = 0;
public projects = 0;
public phones =0;
public leads =0;
public reseller =0;
public callhistory =0;
public cxbuy =0;
public iddpricelist =0;
public call4tellicense =0;
public supportticket =0;
public users =0;
public callbalance =0;
public referral =0;
public licencerenewal =0;
public dealregistration =0;
public callhistorypbx ="0";
public callhistoryvspbx ="0";
public gccfirewall = 0;
public notifycount = 0;
public prof_img;

  constructor(private http:HttpClient) { }

  sendServer(postData:any[]){
	const httpOptions = {
	  headers: new HttpHeaders({
	    'Content-Type':  'application/json'
	  })
      // 
	};
  // return this.http.post("https://cmsuat.cal4care.com/api_cms/v1.0/index.php", postData,httpOptions);
  // return this.http.post("https://erp.cal4care.com/cms_new/api_cms/v1.0/index.php", postData,httpOptions);
  return this.http.post("https://aatrox.mconnectapps.com/api_cms/v1.0/index.php", postData,httpOptions);
    } 
 sendServerpath(postData:any[]){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
      // 
    };
  return this.http.post("api_cms/v1.0/index.php", postData,httpOptions);
    } 

// devcms.cal4care.com / httpdocs / api_cms / v1.0 / index.php
 pagination(list_info){
 	console.log(list_info)
        var start,eu,next,back,limit,total_count,offset:number,last_val,last_final_val,current,pagination,btn_length;
        limit = list_info.page_limit;
        total_count = list_info.total;
        offset = +list_info.offset;
        start = 0 + offset;
        eu = start-0;
        if(total_count<start+1 && total_count>1){

            eu = start-limit;
            start = eu;
        }
        current = eu + limit;
        back = eu - limit;
        next = eu + limit;
        last_val = Math.ceil(total_count/limit);
        last_final_val = (last_val-1)*limit;
        pagination = {"info":"hide"};
        if(total_count > limit){
            pagination.info = "show";
            pagination.start = 0;

            if(back >= 0){
                pagination.back = back;
                pagination.backtab = "show";
            }
            else{
                pagination.backtab = "hide";
            }
        
            btn_length = 1;
            pagination.data = []
            for(var offset_count = 0;offset_count < total_count;offset_count=offset_count+limit){

                if((offset_count<=eu+(2*limit)) && (offset_count>=eu-(2*limit))){

                    if(offset_count != eu){
                        pagination.data.push({"btn_length":btn_length,"offset_count":offset_count,"load":true});
                    }
                    else{
                        pagination.data.push({"btn_length":btn_length,"offset_count":offset_count,"load":false});
                    }
                
                }
                 btn_length=btn_length+1;

            }
            if(current < total_count){
                pagination.next = next;
                pagination.nexttab = "show";
            }
            else{
                pagination.nexttab = "hide";
            }
            pagination.end = last_final_val;

        }

        return pagination;
    }
    

// encryptData(data) {

//     try {
//       return CryptoJS.AES.encrypt(JSON.stringify(data), this.encryptSecretKey).toString();
//     } catch (e) {
//       console.log(e);
//     }
//   }

//   decryptData(data) {

//     try {
//       const bytes = CryptoJS.AES.decrypt(data, this.encryptSecretKey);
//       if (bytes.toString()) {
//         return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
//       }
//       return data;
//     } catch (e) {
//       console.log(e);
//     }
// }


}
  