import { Component, OnInit } from '@angular/core';
import { ServerService } from '../services/server.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
declare var iziToast: any;
import * as Feather from 'feather-icons';
import Swal from 'sweetalert2'
@Component({
  selector: 'app-cms-usernew',
  templateUrl: './cms-usernew.component.html',
  styleUrls: ['./cms-usernew.component.css']
})
export class CmsUsernewComponent implements OnInit {
  cms_userid: any;
  page = "add";
  menu_list: any = [];
  permissions: any = [];
  domain;
  userdata: any = {};

  allcheck = false;
  uncheck = 0;
  deletearr: any = [];
  sendidsarray: any = [];
  sendid;
  constructor(private route: ActivatedRoute, private router: Router, private serverService: ServerService) {

    this.route.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation().extras.state) {

        this.page = this.router.getCurrentNavigation().extras.state.page;

        this.cms_userid = this.router.getCurrentNavigation().extras.state.user_id;

        console.log(this.page)
        if (this.page == "edit") {


          this.cmsuser_details()

        }

      }
    });
    if (this.page == "add") {
      this.cmsuser_details()

    }

  }

  ngOnInit(): void {
    this.userdata.username == '';
    this.userdata.phone_no == '';
    // this.cmsuser_details()

  }


  back() {
    this.router.navigate(['/cmsuser']);
  }
  ngAfterViewInit() {
    Feather.replace();
  }
  cmsuser_details() {
    Swal.fire('please Wait')
    Swal.showLoading()
    this.menu_list = [];
    let datas: any = new Object();
    let datasend: any = new Object();
    let access_token: any = localStorage.getItem('at');
    let customer_id: any = localStorage.getItem('en');


    datas.action = "cms_user_details";
    datas.customer_id = customer_id;



    datasend.access_token = access_token;
    datasend.operation = "curlDatas";
    datasend.moduleType = "login";
    datasend.api_type = "web";
    datasend.element_data = datas;
    this.serverService.sendServer(datasend).subscribe((response: any) => {
      console.log(response)
      if (response.status == "true") {
        Swal.close()

        this.domain = response.Domain;

        this.menu_list = response.Menu_list
        // var menu_list = response.Menu_list;


        // if(this.allcheck == true){

        // this.checkallbox()

        // }else{

        // if(this.uncheck == 1) {
        // this.uncheckallbox()
        // }else{

        // if(this.menu_list.length != 0){
        // if(this.deletearr.length != 0){
        // for (var i = 0; i < this.menu_list.length; i++) {
        //    var ismatch = false;
        //   for (var j = 0; j < this.deletearr.length; j++) {
        //    if(this.menu_list[i].value ==  this.deletearr[j]){
        //      ismatch = true;
        //      this.menu_list[i].add = true;
        //      break;  

        //    }
        //  }
        //  if (!ismatch){
        // this.menu_list[i].add = false//  checkbox status false

        // } 

        // }
        // }
        // else{

        for (var i = 0; i < this.menu_list.length; i++) {
          this.menu_list[i].add = false;
        }
        if (this.page == "edit") {
          this.cmsuser_edit()
        }



        // }


        // }

        // }
        // }

        //      var filtered = this.menu_list.filter(function (el) {
        //   return el != null;
        // });
        // console.log(filtered)
        // var menu_list = menu_list.filter(Boolean);


        // for (var i = 0; i < response.Menu_list.length; i++) {
        //   if(response.Menu_list[i] != null){
        //   this.menu_list.push({'check':false,'name':response.Menu_list[i].name,'value':response.Menu_list[i].value})

        //   }
        // this.menu_list[i].check = false; 

      }
      else {
        Swal.close()
      }
      console.log(this.menu_list)



      // }

    },
      (error) => {
        console.log(error);
      });
  }



  multislt(val) {

    if (val.value == 'All') {
      this.checkall(val)
      // return false;
    } else {
      this.uncheck = 0;
      console.log(val)

      var adding = false

      if (val.add == false) {

        if (this.deletearr.length != 0) {
          for (var i = 0; i < this.deletearr.length; i++) {
            if (val.value !== this.deletearr[i]) {

              adding = true
            }
          }
          if (adding) {
            this.deletearr.push(val.value)
          }
        }
        else {
          this.deletearr.push(val.value)

        }

      }
      else {


        for (var j = 0; j < this.deletearr.length; j++) {

          if (val.value == this.deletearr[j]) {

            this.deletearr.splice(this.deletearr.indexOf(this.deletearr[j]), 1);

          }
        }


      }

      console.log(this.deletearr)

    }

  }


  cmsuser_edit() {

    let datas: any = new Object();
    let datasend: any = new Object();
    let access_token: any = localStorage.getItem('at');
    let customer_id: any = localStorage.getItem('en');


    datas.action = "cms_user_edit";
    datas.customer_id = customer_id;
    datas.cms_user_id = this.cms_userid;



    datasend.access_token = access_token;
    datasend.operation = "curlDatas";
    datasend.moduleType = "login";
    datasend.api_type = "web";
    datasend.element_data = datas;
    this.serverService.sendServer(datasend).subscribe((response: any) => {
      console.log(response)
      if (response.status == "true") {
        this.permissions = response.Menu_list
        this.userdata.username = response.cms_user_name;
        this.userdata.phone_no = response.cms_phone_no;
        for (var i = 0; i < this.menu_list.length; i++) {
          var ismatchss = false;
          for (var j = 0; j < this.permissions.length; j++) {

            if (this.menu_list[i].value == this.permissions[j].value) {
              ismatchss = true;
              this.menu_list[i].add = true;
              this.deletearr.push(this.menu_list[i].value)
              break;
            }
          }
          if (!ismatchss) {
            this.menu_list[i].add = false;//  checkbox status false

          }

        }


      }
      console.log(this.menu_list)

      // }

    },
      (error) => {
        console.log(error);
      });
  }







  checkall(val) {
    this.deletearr = [];

    if (val.add == false) {
      for (var i = 0; i < this.menu_list.length; i++) {
        this.menu_list[i].add = true;
        this.deletearr.push(this.menu_list[i].value)

      }
    } else {
      for (var i = 0; i < this.menu_list.length; i++) {
        this.menu_list[i].add = false;
        // this.deletearr.push(this.menu_list[i])

      }
    }

    console.log(this.menu_list);
    console.log(this.deletearr)
  }



  submits() {
    console.log(this.userdata.username)


    if (this.page == 'add') {
      console.log('if add condition');
      if (this.userdata.username == '' || this.userdata.username == undefined) {

        // console.log("error")
        iziToast.error({
          message: "Please Enter Username",
          position: 'topRight'
        });
        return false
      }

      this.cms_user_validation()
    } else {
      console.log('else condition statement');
      this.updatess()
    }

  }



  cmsuser_insert() {
    console.log('insert into')
    Swal.fire('please Wait')
    Swal.showLoading()
    console.log(this.deletearr)
    this.sendidsarray = [];
    
    console.log(this.deletearr.length)
    for (var i = 0; i < this.deletearr.length; i++) {
      //    if(this.deletearr[i] == "All"){
      // this.deletearr.splice(this.deletearr.indexOf(this.deletearr[i]), 1 );
      //  // this.sendidsarray.push(this.deletearr[i])


      //    }
      //    else{
      if (this.deletearr[i] == "101") {
        this.sendidsarray.push("102")
        this.sendidsarray.push(this.deletearr[i])
   
        // this.sendidsarray.push(this.deletearr[i])

      }
      else {

        this.sendidsarray.push(this.deletearr[i])

      }

    }


    // }

    for (var j = 0; j < this.sendidsarray.length; j++) {
      if (this.sendidsarray[j] == "All") {
        this.sendidsarray.splice(this.sendidsarray.indexOf(this.deletearr[j]), 1);
      }
    }


    console.log(this.sendidsarray)
    this.sendid = this.sendidsarray.join(',');
    console.log(this.sendid)


    let datas: any = new Object();
    let datasend: any = new Object();
    let access_token: any = localStorage.getItem('at');
    let customer_id: any = localStorage.getItem('en');


    datas.action = "cms_user_insert";
    datas.customer_id = customer_id;
    datas.cms_user_name = this.userdata.username;
    datas.log_userName = this.domain;
    datas.cus_permission = this.sendid;



    datasend.access_token = access_token;
    datasend.operation = "curlDatas";
    datasend.moduleType = "login";
    datasend.api_type = "web";
    datasend.element_data = datas;
    this.serverService.sendServer(datasend).subscribe((response: any) => {
      console.log(response)
      if (response && response.status == "true") {
        Swal.close()
        iziToast.success({
          message: "User Created Successfully",
          position: 'topRight'
        });
        this.back();
      } else {
        Swal.close()
        iziToast.error({
          message: "Please try again later",
          position: 'topRight'
        });
      }

    },
      (error) => {
        console.log(error);
      });
  }



  cms_user_validation() {
    // this.cmsuser_insert()
    let datas: any = new Object();
    let datasend: any = new Object();
    let access_token: any = localStorage.getItem('at');
    let customer_id: any = localStorage.getItem('en');


    datas.action = "cms_user_validation";
    datas.customer_id = customer_id;
    datas.cms_user_name = this.userdata.username;
    datas.domain = this.domain;

    datasend.access_token = access_token;
    datasend.operation = "curlDatas";
    datasend.moduleType = "login";
    datasend.api_type = "web";
    datasend.element_data = datas;
    this.serverService.sendServer(datasend).subscribe((response: any) => {
      console.log(response);
      console.log(response.status);
      if (response.status == 'true') {
        //   iziToast.success({
        //     message: "Added Successfully",
        //     position: 'topRight'
        // });
        this.cmsuser_insert()
      } else {
        iziToast.error({
          message: response.Message,
          position: 'topRight'
        });
      }

    },
      (error) => {
        console.log(error);
      });
  }


  updatess() {
    Swal.fire('please Wait')
    Swal.showLoading()
    console.log(this.userdata.username)
    console.log(this.deletearr)
    this.sendidsarray = [];

    for (var i = 0; i < this.deletearr.length; i++) {

      if (this.deletearr[i] == "101") {
        this.sendidsarray.push("102")
        this.sendidsarray.push(this.deletearr[i])



      }
      else {
        this.sendidsarray.push(this.deletearr[i])

      }

    }


    for (var j = 0; j < this.sendidsarray.length; j++) {
      if (this.sendidsarray[j] == "All") {
        this.sendidsarray.splice(this.sendidsarray.indexOf(this.deletearr[j]), 1);
      }
    }


    console.log(this.sendidsarray)
    this.sendid = this.sendidsarray.join(',');
    console.log(this.sendid)


    let datas: any = new Object();
    let datasend: any = new Object();
    let access_token: any = localStorage.getItem('at');
    let customer_id: any = localStorage.getItem('en');


    datas.action = "cms_user_update";
    datas.customer_id = customer_id;
    datas.cms_user_name = this.userdata.username;
    datas.log_userName = this.domain;
    datas.cus_permission = this.sendid;
    datas.cms_user_id = this.cms_userid;

    datas.cms_phone_no = this.userdata.phone_no;


    datasend.access_token = access_token;
    datasend.operation = "curlDatas";
    datasend.moduleType = "login";
    datasend.api_type = "web";
    datasend.element_data = datas;
    this.serverService.sendServer(datasend).subscribe((response: any) => {
      console.log(response)
      if (response.status == "true") {
        Swal.close()
        iziToast.success({

          message: "User Updated Successfully",
          position: 'topRight'
        });

      } else {
        Swal.close()
        iziToast.error({
          message: "Please try again later",
          position: 'topRight'
        });
      }

    },
      (error) => {
        console.log(error);
      });
  }









}
