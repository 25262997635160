import { Component, OnInit } from '@angular/core';
import { ServerService } from '../services/server.service';
import { Router } from '@angular/router';
import * as Feather from 'feather-icons';
declare var iziToast:any;
@Component({
  selector: 'app-checkpage',
  templateUrl: './checkpage.component.html',
  styleUrls: ['./checkpage.component.css']
})
export class CheckpageComponent implements OnInit {

  constructor(private router:Router,private serverService: ServerService) { }

  ngOnInit(): void {
  }


globalpayment(val){

  let datas:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
   
datas.action="global_payment";
datas.customer_id = customer_id;
datas.from_site = "FromCMS";
datas.gateway = val;
datas.amount = "10";
datas.currency = "sgd";
datas.name = "Jayakumar";
datas.description = "Invoice From Cal4care";
datas.success_url = "https://erp.cal4care.com/cms_new/test_success.php";
datas.cancel_url = "https://erp.cal4care.com/cms_new/test_success.php";

 datasend.access_token=access_token;
  datasend.operation="global_payment_curl";
  datasend.moduleType="payment";
  datasend.api_type="web";
  datasend.element_data = datas;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){
     // this.allextensions = response.CMS_extension
   
          }
      
      }, 
      (error)=>{
          console.log(error);
      });
}


}
