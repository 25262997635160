import { Component, OnInit } from '@angular/core';
import { ServerService } from '../services/server.service';

@Component({
  selector: 'app-referral',
  templateUrl: './referral.component.html',
  styleUrls: ['./referral.component.css']
})
export class ReferralComponent implements OnInit {

  constructor(private serverServices:ServerService) { }
  referal_datas;
  ngOnInit(): void {
    this.referal_list()
  }
referal_list(){
  let ref_data:any = new Object();
  let api_data:any = new Object();
  api_data.operation = "referral";
  api_data.moduleType = "referral";
  api_data.api_type = "web";
  ref_data.action = "get_referal";
  ref_data.customer_id = "218437";
  api_data.element_data = ref_data;
  console.log(api_data);
  this.serverServices.sendServer(api_data).subscribe((res:any)=>{
    if(res.result.status==true){
      this.referal_datas = res.result.data;
    }
  })
}
}
