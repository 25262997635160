import { Component, OnInit } from '@angular/core';
import { ServerService } from '../services/server.service';
import * as Feather from 'feather-icons';
import Swal from 'sweetalert2'
declare var iziToast:any;
import { Router,ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-costcenteredit',
  templateUrl: './costcenteredit.component.html',
  styleUrls: ['./costcenteredit.component.css']
})
export class CostcentereditComponent implements OnInit {
username:any=[];
selectedusername:any=[];
usernameleft:any=[];
usernameright:any=[];
allvalue:any=[];
groupname;
sendnames;
page ="add";
  constructor(private route:ActivatedRoute,private router:Router,private serverService: ServerService) {

 this.route.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation().extras.state) {

     this.page=this.router.getCurrentNavigation().extras.state.page;



if(this.page == "edit"){
     this.editcost_sender()

}else{
   this.adddropdown()

}
   
      }
    });
 if(this.page == "add"){
   this.adddropdown()
   }
 }

  ngOnInit(): void {
 
  }

finishFunction(){

}

moveleftall(){
this.username = [];
this.selectedusername =[];
this.username = this.allvalue
}

moverightall(){
this.selectedusername =[];
this.username = [];
this.selectedusername = this.allvalue
}

moveleft(){

for (var i = 0; i < this.usernameright.length; i++) {
  this.username.push(this.usernameright[i])
}



for (var i = 0; i <= this.selectedusername.length; i++) {
   var ismatch = false;
  for (var j = 0; j <= this.usernameright.length; j++) {
   if(this.selectedusername[i] ==  this.usernameright[j]){
     ismatch = true;
     
    this.selectedusername.splice(this.selectedusername.indexOf(this.selectedusername[i]), 1 );
  

   }
 }
 if (!ismatch){
   
      
      } 

}

}

moveright(){

for (var i = 0; i < this.usernameleft.length; i++) {
  this.selectedusername.push(this.usernameleft[i])
}



for (var i = 0; i <= this.username.length; i++) {
   var ismatch = false;
  for (var j = 0; j <= this.usernameleft.length; j++) {
   if(this.username[i] ==  this.usernameleft[j]){
     ismatch = true;

     
    this.username.splice(this.username.indexOf(this.username[i]), 1 );
 

   }
 }
 if (!ismatch){
   
      
      } 

}

}

editcost_sender(){

  let datas:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
    let senderid: any=localStorage.getItem('cos');
   
   
datas.action="edit_cost_sender";
datas.customer_id = customer_id;
datas.cost_sender_id = senderid;



 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="costsender";
  datasend.api_type="web";
  datasend.element_data = datas;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
  if(response.status=="true"){
          // this.username = response.options;
          for (var i = 0; i < response.options.length; i++) {
             this.username.push(response.options[i].value)
             this.allvalue.push(response.options[i].value)
          }

             for (var i = 0; i < response.selected_options.length; i++) {
             this.selectedusername.push(response.selected_options[i].value)
             // this.allvalue.push(response.options[i].value)
          }
          // this.selectedusername = response.selected_options;
          console.log(this.username)
  
     this.groupname=response.mapping_name;
          }
        else{
    // iziToast.error({
    //       message: "Please try again later",
    //       position: 'topRight'
    //   });
 
        }
 


      
      }, 
      (error)=>{
          console.log(error);
      });

}

adddropdown(){

  let datas:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
   
datas.action="add_dropdown";
datas.customer_id = customer_id;



 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="costsender";
  datasend.api_type="web";
  datasend.element_data = datas;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){
          // this.username = response.options;
          for (var i = 0; i < response.options.length; i++) {
             this.username.push(response.options[i].value)
             this.allvalue.push(response.options[i].value)
          }
          console.log(this.username)
  

          }
        else{
    // iziToast.error({
    //       message: "Please try again later",
    //       position: 'topRight'
    //   });
 
        }
 


      
      }, 
      (error)=>{
          console.log(error);
      });

}

add_cost_sender(){


  if(this.groupname == "" || undefined){
   iziToast.error({
          message: "Please Enter Group Name",
          position: 'topRight'
      });
return false;
}


this.sendnames = "";
  let datas:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
   this.sendnames=this.selectedusername.join(',');



datas.action="add_cost_sender";
datas.customer_id = customer_id;
datas.mapping_name = this.groupname;
datas.from_display_name = this.sendnames;


 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="costsender";
  datasend.api_type="web";
  datasend.element_data = datas;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){
         iziToast.success({
          message: "Cost Center Added Successfully",
          position: 'topRight'
      });

   this.router.navigate(['/costcenter']);

          }
        else{
    iziToast.error({
          message: "Please try again later",
          position: 'topRight'
      });
 
        }
 


      
      }, 
      (error)=>{
          console.log(error);
      });

}


updatecost_sender(){
  if(this.groupname == "" || undefined){
   iziToast.error({
          message: "Please Enter Group Name",
          position: 'topRight'
      });
return false;
}

this.sendnames = "";

  let datas:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   let senderid: any=localStorage.getItem('cos')
     this.sendnames=this.selectedusername.join(',');

datas.action="update_cost_sender";
datas.customer_id = customer_id;
datas.mapping_name = this.groupname;
datas.from_display_name = this.sendnames;
datas.cost_sender_id = senderid;

 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="costsender";
  datasend.api_type="web";
  datasend.element_data = datas;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){
         iziToast.success({
          message: "Cost Center Updated Successfully",
          position: 'topRight'
      });

   this.router.navigate(['/costcenter']);
          }
        else{
    iziToast.error({
          message: "Please try again later",
          position: 'topRight'
      });
 
        }
 


      
      }, 
      (error)=>{
          console.log(error);
      });

}


}
