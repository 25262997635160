







			<section class="section">
<ul class="breadcrumb breadcrumb-style ">
<li class="breadcrumb-item">
<h4 class="page-title m-b-0">Check Payment</h4>
</li>
<li class="breadcrumb-item">
<a routerLink="/dashboard">
<i data-feather="home"></i></a>
</li>
<li class="breadcrumb-item active">Check Payment</li>
</ul>
<div class="section-body">
<div class="row">
<div class="col-12">
<div class="card card-primary">
<div class="card-header">
<h4>Check Payment</h4>
<div class="card-header-action">
<a data-toggle="collapse" class="btn btn-icon btn-info" href="#tableCollapse">
<i class="fas fa-minus"></i></a>
</div>
</div>
<div class="collapse show" id="tableCollapse">
<div class="card-body">


<div class="form-group row mb-4">
                  <div class="col-sm-12 col-md-7">
                  <button type="button"  class="btn btn-primary" (click)="globalpayment('stripe')">
        <i class="fas fa-plus-circle"></i>
                  Add stripe</button>

                   <button type="button"  class="btn btn-primary" (click)="globalpayment('paypal')">
        <i class="fas fa-plus-circle"></i>
                  Add paypal</button>
                  </div>
               </div>






</div>
</div>
</div>
</div>
</div>
</div>
</section>
