<section class="section">
<ul class="breadcrumb breadcrumb-style ">
<li class="breadcrumb-item">
<h4 class="page-title m-b-0">Licence</h4>
</li>
<li class="breadcrumb-item">
<a routerLink="/dashboard">
<i data-feather="home"></i></a>
</li>
<li class="breadcrumb-item active">Licence Details</li>
</ul>
<div class="section-body">
<div class="row">
<div class="col-12">
<div class="card card-primary">
<div class="card-header">
<h4>Licence Details</h4>
<div class="card-header-action">
<a  data-toggle="collapse" class="btn btn-icon btn-info" href="#tableCollapse">
<i class="fas fa-minus"></i></a>
</div>
</div>
<div class="collapse show" id="tableCollapse">
<div class="card-body">
<div class="table-responsive">
<table class="table table-striped" >
  <!-- id="tableExport" -->

  <thead>
                <tr>
                  <td colspan="20" class="search-text-tbl">
                    <div class="input-group"><input type="text" class="form-control" placeholder="Search Licence" (input)="getsearch($event.target.value)"  (keyup.enter)="get_license_list({'search_text':agent_search.value})" #agent_search>
                      <div class="input-group-append">
                        <button class="btn btn-primary" (click)="get_license_list({'search_text':agent_search.value})"><i class="fas fa-search"></i></button>
                      </div>
                    </div>
                  </td>
                </tr>
              </thead> 


  
<thead>
<tr>
<th>S.no</th>
<th>LIC Code</th>
<th>Issue Date</th>
<th>Product</th>
<th>Licence Key</th>
<th>Prim Lic</th>
<th>Key Exp</th>
<th>Email</th>
<th>PO Number</th>
<th>Action</th>
</tr>
</thead>
<tbody>


<tr  *ngFor ="let l of licensedetail;let i = index;">
<td>{{offset_count+i+1}}</td>
<td>{{l.Licensecode}}</td>
<td>{{l.Issuedate}}</td>
<td>{{l.Product}}</td>
<td>{{l.Licensekey}}</td>
<td>{{l.Primarylicense}}</td>
<td>{{l.Keyexp}}</td>
<td>{{l.ActivationEmail}}</td>
<td></td>
<td class="action-btn-group">

<a (click)="doc(l.Billid)" target="_blank" data-toggle="tooltip" title="Invoice" class="btn btn-danger">
<i class="far fa-file-pdf"></i></a>
<!-- <a href="{{l.invoice_attach[0]}}" target="_blank" data-toggle="tooltip" title="Invoice" class="btn btn-danger">
<i class="far fa-file-pdf"></i></a> -->



<a  *ngIf="l.license_attach != ''" href="{{l.license_attach}}" target="_blank" data-toggle="tooltip" title="License" class="btn btn-warning">
<i class="fas fa-key"></i>
</a>
</td>
</tr>



<!-- <tr>
<td>2</td>
<td>LIC-4015</td>
<td>03-08-20</td>
<td>3CX Phone System License</td>
<td>EOLV-NMIZ-7OCA-ID4U</td>
<td>32Enterprise</td>
<td>28-09-20</td>
<td>amornrat@twoaplus.com</td>
<td class="action-btn-group">
<a href="javascript:;" data-toggle="tooltip" title="Invoice" class="btn btn-danger">
<i class="far fa-file-pdf"></i></a>
<a href="javascript:;" data-toggle="tooltip" title="License" class="btn btn-warning">
<i class="fas fa-key"></i>
</a>
</td>
</tr> -->



</tbody>

<tr *ngIf="recordNotFound == true">
                 <td colspan="12">No Record Found</td>
               </tr>

</table>
</div>

<div class="card-footer text-right" *ngIf="recordNotFound == false">
  <!--  -->
              <nav class="d-inline-block">
                <ul class="pagination mb-0" *ngIf="paginationData.info == 'show'">
<!--  -->

                  <li class="page-item" *ngIf="paginationData.backtab =='show'">
                    <a class="page-link" href="javascript:void(0)" (click)="get_license_list({'search_text':agent_search.value,'offset':paginationData.back})"><i class="fas fa-angle-double-left"></i></a>
                  </li>

                  <li class="page-item">
                    <a [class]="paginationData.backtab=='show' ? 'page-link' :'page-link disabled'" href="javascript:void(0)" (click)="paginationData.backtab=='show' ? get_license_list({'search_text':agent_search.value,'offset':paginationData.start}) : false"><i class="fas fa-angle-left"></i></a>
                  </li>
                  
                  <li [class]="pagination.load ==false ? 'page-item active' :'page-item'" *ngFor="let pagination of paginationData.data;let i=index">
                    <a class="page-link" href="javascript:void(0)" (click)=" pagination.load ==true ? get_license_list({'search_text':agent_search.value,'offset':pagination.offset_count}) : false">{{pagination.btn_length}} <span class="sr-only" *ngIf="pagination.load ==false">(current)</span></a>
                  </li>

                  <li class="page-item">
                    <a [class]="paginationData.nexttab =='show' ? 'page-link' : 'page-link disabled'" href="javascript:void(0)" (click)="paginationData.nexttab =='show' ? get_license_list({'search_text':agent_search.value,'offset':paginationData.end}) : false"><i class="fas fa-angle-right"></i></a>
                  </li>

                  <li class="page-item" *ngIf="paginationData.nexttab =='show'">
                    <a class="page-link" href="javascript:void(0)" (click)="get_license_list({'search_text':agent_search.value,'offset':paginationData.next})"><i class="fas fa-angle-double-right"></i></a>
                  </li>


                </ul>
              </nav>
            </div>




</div>
</div>
</div>
</div>
</div>
</div>
</section>