<section class="section payment-section">
    <h4 class="page-title m-b-0">Payment Details</h4>
    <div class="detail-section">
        <div class="row">
            <div class="col-12 col-lg-6">
                <div class="detail-left-section" *ngIf='datalist != ""'>
                    <p><label>Customer Name :</label><span *ngIf='datalist.Customer_data.customerName != ""'>{{datalist.Customer_data.customerName}}</span></p>
<p><label>Address :</label><span *ngIf='datalist.Customer_data.customerAddress1 != ""'>{{datalist.Customer_data.customerAddress1}},</span> <span *ngIf='datalist.Customer_data.customerAddress2 != ""'>{{datalist.Customer_data.customerAddress2}},</span><span *ngIf='datalist.Customer_data.city != ""'>{{datalist.Customer_data.city}},</span><span *ngIf='datalist.Customer_data.state != ""'>{{datalist.Customer_data.state}},</span><span *ngIf='datalist.Customer_data.country != ""'> {{datalist.Customer_data.country}}</span></p>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <div class="detail-right-section"  *ngIf='datalist != ""'>
                    <p><label>Order Date :</label><span>{{datalist.order_date}}</span></p>
                    <p><label>3CX reseller Id :</label><span>{{datalist.Customer_3cx_data.Resellerid}}</span></p>
                </div>
            </div>
        </div>
    </div>
    <div class="section-body"  *ngIf='datalist != ""'>
        <div class="row">
            <div class="col-12">
                <div class="card card-primary">
                    <div class="card-header">
                        <h4>Payment Details</h4> 
                        <div class="card-header-action">
                            <a  data-toggle="collapse"  class="btn btn-icon btn-info" href="#tableCollapse">
                            <i class="fas fa-minus"></i></a>
                        </div>
                    </div>
                    <div class="collapse show" id="tableCollapse">
                        <div class="card-body">
                            <table class="table table-striped" >
                                <thead>
<tr>
    <!-- <th style="text-align: center;">Action</th> -->

<th>Code</th>
<th>Description</th>
<th>Price({{datalist.Customer_data.currency_name}})</th>
<th>Qty</th>
<th>Discount({{datalist.Customer_data.currency_name}})</th>
<th>Amount ({{datalist.Customer_data.currency_name}})</th>
</tr>
</thead>
                                <tbody>

<tr *ngFor="let l of cartdata;let i = index;">

<!-- <td class="action-btn-group " style="text-align: center;">
<a href="javascript:;" class="btn btn-danger" (click)="removecart(l)"><i class="fas fa-trash-alt"></i></a>
</td>  -->
<td  style="text-align: center !important;">{{l.edition_key}}</td>
<td>{{l.product_desc}}</td>
<td style="text-align: right !important;">{{l.actual_price}}</td>
<td style="text-align: center !important;">{{l.quantity}}</td>
<td style="text-align: center !important;">{{l.discount}}({{l.customer_discount}}%)</td>
<td style="text-align: right !important;"><b>{{l.sub_total}}</b></td>


</tr>






                                    <tr>
                                        <td colspan="5"  style="text-align: right !important"><strong style="font-weight:500">Net Total</strong></td>
                                        <td style="text-align: right !important;"> {{datalist.total_price_all}}</td>
                                        <!-- <input type="hidden" value="0.00" name="total_price_all_hidden" id="total_price_all_hidden"> -->
                                    </tr>
                                    <tr>
                                        <td colspan="5" style="text-align: right !important"><strong style="font-weight:500" *ngIf='discount_percent != "0"'>Discount Price({{discount_percent}}%)</strong>
                                        <strong style="font-weight:500"  *ngIf='discount_percent == "0"'>Discount Price</strong>
                                        </td>

                                        <td style="text-align: right !important;">{{discount_price}}</td>
                                        <!-- <input type="hidden" value="0.00" name="total_price_all_hidden" id="total_price_all_hidden"> -->
                                    </tr>
                                    <tr>
                            <td colspan="5" style="text-align: right !important"><strong style="font-weight:500">Tax Value<span *ngIf='datalist.total_tax_value != "0.00"'>({{taxpercent}}%)</span></strong></td>
                                        <td style="text-align: right !important;">{{datalist.total_tax_value}}</td>
                                        <!-- <input type="hidden" value="0.00" name="total_price_all_hidden" id="total_price_all_hidden"> -->
                                    </tr>
                                    <tr>
                                        <td colspan="5" style="text-align: right !important"><strong style="font-weight:500">Transaction Charge</strong></td>
                                        <td style="text-align: right !important;">{{transactioncharge}}</td>
                                        <!-- <input type="hidden" value="0.00" name="total_price_all_hidden" id="total_price_all_hidden"> -->
                                    </tr>
                                    <tr>
                                        <td colspan="5" style="text-align: right !important"><strong style="font-weight:500">Grand Total</strong></td>
                                        <td style="text-align: right !important;">{{total_amount}}</td>
                                        <!-- <input type="hidden" value="0.00" name="total_price_all_hidden" id="total_price_all_hidden"> -->
                                    </tr>
                                </tbody>
                            </table>
                            <div class="row">
                                <div class="col-md-3">
                                   <h6 class="pt-2" style="font-size:14px">Payment Terms</h6>
                                </div>	
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control" id="payment_terms" name="payment_terms" value="{{datalist.Customer_data.terms_condition}}" readonly>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-3">
                                   <h6 class="pt-2" style="font-size:14px">Coupon Code</h6>
                                </div>	
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control" id="coupon-code" name="coupon_code"  [(ngModel)]="couponcode" >
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <input type="button" name="verify_coupon" id="verify_coupon"  value="Verify" class="verify_coupon btn btn-warning" (click)="couponcode_verify()">
                                 </div>	
                                 <h6 class="couponmessage">{{couponcodemessage}}</h6>
                            </div>
                            <div class="row">
                                <div class="col-12 col-lg-12">
                                    <div class="payment-card-section">
                                        <div class="card">
                                            <div class="card-head">
                                                <h4>Choose payment method</h4>
                                            </div>
                                            <div *ngFor="let l of paymentarray" >
                                                <div *ngIf="l.show" class="payment_card">
                                            <label    >
                                                <input  class="mrg-right5" type="radio" [(ngModel)]="selectpayment" (click)="transactioncharges(l.value)"  name="payment_type" id="payment_type_paypal_checkout" value="{{l.value}}" >{{l.name}}
                                        <img *ngIf="l.showvalue == 'paypal_credit_debit'" src="assets/img/cards/paypal_2.png">
                                        <img *ngIf="l.showvalue == 'ocbc_sg_payment'"  src="assets/img/cards/ocbc.png">
                                         <img *ngIf="l.showvalue == 'ocbc_my_payment'"  src="assets/img/cards/ocbc.png">
                                        <img *ngIf="l.showvalue == 'stripe_pay'"  src="assets/img/cards/powered-by-stripe.png">
                                        <img *ngIf="l.showvalue == 'payoffline'"  src="assets/img/cards/offline23.png">
                                            </label>
                                        </div>
                                        </div>


                                     <!--    <label class="payment_card">
                        <input type="radio" name="payment_type" id="payment_type_paypal_checkout" value="payment_type_ocbc_sg_payment" > Credit Card / Debit Card
                                                <img src="assets/img/cards/ocbc.png">
                                            </label> -->
                                           <!--  <label class="payment_card">
                                                <input type="radio" name="payment_type" id="payment_type_paypal_checkout" value="payment_type_stripe_pay" > Stripe
                                                <img src="assets/img/cards/powered-by-stripe.png">
                                            </label> -->
                                            <input type="button" size="10" class="btn btn-warning" value="Reset"  id="reset_button" name="reset_button" (click)="getvalue()">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="offline_information_data" *ngIf="datalist.show_offline_payment == 0">
                                <div class="row">
                                    <div class="col-12 col-lg-6">
                                        <div class="offline_info_left_data">
                                            <p><label>Available credit value :</label><span class="credit_info">{{datalist.available_credit_value}}</span></p>
                                            <p><label>Outstanding invoice value :</label><span class="credit_info">{{datalist.outstanding_invoice_value}}</span></p>
                                            <p><label>Max allowed value :</label><span class="credit_info">{{datalist.max_allowed_value}}</span></p>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-6">
                                        <div class="offline_info_right_data">
                                            <p><label>Avilable credit terms :</label><span class="terms_data">{{datalist.available_credit_terms}}</span></p>
                                            <p><label>Max allowed terms :</label><span class="terms_data">{{datalist.max_allowed_terms}}</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p  *ngIf="datalist.show_offline_payment == 0" class="payment-detail-bottom-section">You don't have access for the offline payment option, Reason because the sum of outstanding invoice total price and current order price is high compared to your total credit value and there is an outstanding due for days.</p>
                            <div class="submit-btn-group text-center mb-2">
                                <a     class="btn btn-warning back-btn" routerLink="/shopping-cart">Back</a>
                                <a    class="btn btn-success" (click)="submitpaymentangular()">Submit</a>
                            </div>

<!-- Stripe Payment Gateway -->
  <form method='post' name='stripePay_frm' id='stripePay_frm'  action='https://erp.cal4care.com/erp/payment_transaction.php' >
     <!-- <a    class="btn btn-success" (click)="submitpaymentangular()">Sasdasubmit</a> -->
                                <input  name='transaction_data' value='stripe_pay' type='hidden'>
                                <input name='currency_code' value='{{datalist.Customer_data.currency_name}}' type='hidden'>
                                <input type='hidden' name='order_id'  class='invoice_id' value='{{invoice_id}}'>
                                <input type='hidden' name='credit_data' class='credit_data' value='{{credit_data}}'>
                                <input type='hidden' name='payment_from' value='portal_license_key'>
                                </form> 
<!-- Stripe Payment Gateway end -->

<!-- Paypal Payment Gateway --> 


<!-- {"transaction_data":"paypal_payment","credit_data":"","business":"","item_number":"1","no_shipping":"0","currency_code":"SGD","custom":"36515","first_name":"DCARE TECHNOLOGIES PTE LTD","last_name":"","address1":"","city":"","state":"","zip":"","country":"Singapore","H_PhoneNumber":"","return":"","cancel_return":"","payment_from":"portal_license_key"} ***
 -->


<form method='post' name='payPaylPayment' id='payPaylPayment' action='https://erp.cal4care.com/erp/payment_transaction.php'  >
                <input  name='transaction_data' value='paypal_payment' type='hidden'>
                                <input type='hidden' name='credit_data' class='credit_data' value='{{credit_data}}'>
                <input name='business' value='' type='hidden'>
                <input name='item_number' value='{{item_number}}' type='hidden'>            
                <input name='no_shipping' value='0' type='hidden'>
                <input name='currency_code' value='{{datalist.Customer_data.currency_name}}' type='hidden'>
                <input type='hidden' name='custom' id='custom' class='invoice_id' value='{{invoice_id}}'>
                <input type='hidden' name='first_name' value='{{datalist.Customer_data.customerName}}'>
                <input type='hidden' name='last_name' value=''>
                <input type='hidden' name='address1' value='{{datalist.Customer_data.customerAddress1}}'>
                <input type='hidden' name='city' value='{{datalist.Customer_data.city}}'>
                <input type='hidden' name='state' value='{{datalist.Customer_data.state}}'>
                <input type='hidden' name='zip' value='{{datalist.Customer_data.zipCode}}'>
                <input type='hidden' name='country' value='{{datalist.Customer_data.country}}'>
                <input type='hidden' name='H_PhoneNumber' value='{{datalist.Customer_data.mobilePhone}}'>
                <input name='return' value='' type='hidden'>
                <input name='cancel_return' value='' type='hidden'>
                                <input type='hidden' name='payment_from' value='portal_license_key'>
                </form>  


<!-- Paypal Payment Gateway  End-->

<!-- OCBC SG Payment Gateway  -->
                      <form method='post' name='ocbc_sg' id='ocbc_sg' action='https://erp.cal4care.com/erp/payment_transaction.php' >
                                <input  name='transaction_data' value='ocbc_sg_payment' type='hidden'>
                                <input name='currency_code' value='{{datalist.Customer_data.currency_name}}' type='hidden'>
                                <input type='hidden' name='order_id'  class='invoice_id' value='{{invoice_id}}'>
                                <input type='hidden' name='credit_data' class='credit_data' value='{{credit_data}}'>
                                <input type='hidden' name='payment_from' value='portal_license_key'>
                                </form> 


<!-- OCBC SG Payment Gateway End  -->

<!-- OCBC MY Payment Gateway End  -->


                              <form method='post' name='ocbc_my' id='ocbc_my' action='https://erp.cal4care.com/erp/payment_transaction.php' >
                                <input  name='transaction_data' value='ocbc_my_payment' type='hidden' >
                                <input name='currency_code' value='{{datalist.Customer_data.currency_name}}' type='hidden'>
                                <input type='hidden' name='order_id'  class='invoice_id' value='{{invoice_id}}'>
                                <input type='hidden' name='credit_data' class='credit_data' value='{{credit_data}}'>
                                <input type='hidden' name='payment_from' value='portal_license_key'>
                                </form>






<!-- Offline Payment  -->

    <form method='post' name='licenseKeyGenerate' id='licenseKeyGenerate' action='https://erp.cal4care.com/cms_new/license_key_generate.php' >
                                 <input type='hidden' name='billing_id'  id='license_billing_id' class='invoice_id' value='{{invoice_id}}'>
                                 <input type='hidden' name='credit_data' class='credit_data' value='{{credit_data}}'>
                                <input type='hidden' name='action' value='license_key_generate'>
                                <input  type='hidden' name='payment_order' value='no'>
                                <input type='hidden' name='payment_from' value='portal_license_key'>
                              

                                </form>  
<!-- Offline Payment  End-->

                        </div>
                    </div>        
                </div>
            </div>
        </div>
    </div>                
</section>

<!-- Paypal Payment Gateway -->

<!--  <form method='post' name='payPaylPayment' id='payPaylPayment' action='https://erp.cal4care.com/erp/payment_transaction.php'  *ngIf="show">
                <input  name='transaction_data' value='paypal_payment' type='hidden'>
                                <input type='hidden' name='credit_data' class='credit_data' value=''>
                <input name='business' value='$paypal_mail_id' type='hidden'>
                <input name='item_number' value='$item_number' type='hidden'>            
                <input name='no_shipping' value='0' type='hidden'>
                <input name='currency_code' value='$customer_currency' type='hidden'>
                <input type='hidden' name='custom' id='custom' class='invoice_id' value=''>
                <input type='hidden' name='first_name' value='$customerName'>
                <input type='hidden' name='last_name' value=''>
                <input type='hidden' name='address1' value='$customerAddress1'>
                <input type='hidden' name='city' value='$city'>
                <input type='hidden' name='state' value='$state'>
                <input type='hidden' name='zip' value='$zipCode'>
                <input type='hidden' name='country' value='$country'>
                <input type='hidden' name='H_PhoneNumber' value='$mobilePhone'>
                <input name='return' value='$success_url' type='hidden'>
                <input name='cancel_return' value='$cancel_url' type='hidden'>
                                <input type='hidden' name='payment_from' value='portal_license_key'>
                </form>  -->

<!-- Paypal Payment Gateway  End-->



<!-- Stripe Payment Gateway -->
               <!--   <form method='post' name='stripePay_frm' id='stripePay_frm'  action='https://erp.cal4care.com/erp/payment_transaction_test.php' *ngIf="show">
                                <input  name='transaction_data' value='stripe_pay' type='hidden'>
                                <input name='currency_code' value='{{datalist.Customer_data.currency_name}}' type='hidden'>
                                <input type='hidden' name='order_id'  class='invoice_id' value=''>
                                <input type='hidden' name='credit_data' class='credit_data' value=''>
                                <input type='hidden' name='payment_from' value='portal_license_key'>
                                </form>  -->
<!-- Stripe Payment Gateway end -->


<!-- OCBC SG Payment Gateway  -->
                 <!--        <form method='post' name='ocbc_sg' id='ocbc_sg' action='https://erp.cal4care.com/erp/payment_transaction.php' *ngIf="show">
                                <input  name='transaction_data' value='ocbc_sg_payment' type='hidden'>
                                <input name='currency_code' value='$customer_currency' type='hidden'>
                                <input type='hidden' name='order_id'  class='invoice_id' value=''>
                                <input type='hidden' name='credit_data' class='credit_data' value=''>
                                <input type='hidden' name='payment_from' value='portal_license_key'>
                                </form> -->


<!-- OCBC SG Payment Gateway End  -->

                          <!--       <form method='post' name='ocbc_my' id='ocbc_my' action='https://erp.cal4care.com/erp/payment_transaction.php' *ngIf="show">
                                <input  name='transaction_data' value='ocbc_my_payment' type='hidden' >
                                <input name='currency_code' value='$customer_currency' type='hidden'>
                                <input type='hidden' name='order_id'  class='invoice_id' value=''>
                                <input type='hidden' name='credit_data' class='credit_data' value=''>
                                <input type='hidden' name='payment_from' value='portal_license_key'>
                                </form>
 -->

                      <!--  <form method='post' name='licenseKeyGenerate' id='licenseKeyGenerate' *ngIf="show">
                                 <input type='hidden' name='billing_id'  id='license_billing_id' class='invoice_id' value='{{transactioncharge}}'>
                                 <input type='hidden' name='credit_data' class='credit_data' value=''>
                                <input type='hidden' name='action' value='license_key_generate'>
                                <input  type='hidden' name='payment_order' value='no'>
                                <input type='hidden' name='payment_from' value='portal_license_key'>
                              

                                </form>  -->