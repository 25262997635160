<div class="loader"></div>



<div id="app">
	<div *ngIf="templateAuthView == false">
<div class="main-wrapper main-wrapper-1" >
<div class="navbar-bg"></div>


<app-menu></app-menu>



<div class="main-content">
	  <router-outlet (activate)="onActivate($event)"></router-outlet>
<!-- <router-outlet></router-outlet> -->
</div>

<div class="settingSidebar">
<a href="javascript:void(0)" class="settingPanelToggle" style="position: absolute;top: 0%;"> <i class="fa fa-spin fa-cog"></i>
</a>
<div class="settingSidebar-body ps-container ps-theme-default">
<div class=" fade show active">
<div class="setting-panel-header">Notifications
</div>

<div *ngIf="notifydata.length == 0" class="notifyimg">
	<img src="assets/img/nonotify.jpg" alt="">
	
</div>
<div *ngIf="notifydata.length != 0">
 <div class="p-15 border-bottom notification-item" *ngFor="let l of notifydata;">
	<span class="notification-item-icon bg-primary text-white pointer"  (click)="updatenotify(l)"> 
		<i class="fas fa-ticket-alt" *ngIf='l.type == "support"'></i>
		<i class="far fa-file-alt" *ngIf='l.type == "invoice"'></i>
		<i class="fas fa-sync" *ngIf='l.type == "renewal"'></i>
		<i class="far fa-money-bill-alt" *ngIf='l.type == "payment_made" && l.type == "payment_add"'></i>
		<i class="far fa-handshake" *ngIf='l.type == "referral"'></i>
		<i class="fas fa-rocket" *ngIf='l.type == "app"'></i>
	</span>
	<span class="notification-msg pointer"  (click)="updatenotify(l)"><b>{{l.type}}</b>
		<br>{{l.message}}
		<!-- <span class="time">2 MinAgo</span> -->
	</span>
</div>
</div>
<!-- <div class="p-15 border-bottom notification-item">
	<span class="notification-item-icon bg-success text-white"> 
		<i class="far fa-file-alt"></i>
	</span>
	<span class="notification-msg"><b>Invoice</b> New Invoice Generated by <b>AK</b>
		<span class="time">1 Day Ago</span>
	</span>
</div> -->

<!-- <div class="p-15 border-bottom notification-item">
	<span class="notification-item-icon bg-success text-white"> 
		<i class="far fa-file-alt"></i>
	</span>
	<span class="notification-msg"><b>Invoice</b> New Invoice Generated by <b>NV</b>
		<span class="time">2 HrsAgo</span>
	</span>
</div>

<div class="p-15 border-bottom notification-item">
	<span class="notification-item-icon bg-primary text-white"> 
		<i class="fas fa-sync"></i>
	</span>
	<span class="notification-msg"><b>Renewal</b> New renewal requeast from <b>NV</b>
		<span class="time">2 MinAgo</span>
	</span>
</div>

<div class="p-15 border-bottom notification-item">
	<span class="notification-item-icon bg-danger text-white"> 
		<i class="far fa-money-bill-alt"></i>
	</span>
	<span class="notification-msg"><b>Payment</b> New Payment Made by <b>Acer Computer</b>
		<span class="time">2 MinAgo</span>
	</span>
</div>

<div class="p-15 border-bottom notification-item">
	<span class="notification-item-icon bg-warning text-white"> 
		<i class="far fa-handshake"></i>
	</span>
	<span class="notification-msg"><b>Referral</b> New Referral from <b>Acer Computer</b>
		<span class="time">2 MinAgo</span>
	</span>
</div> -->


</div>
</div>
</div>

<app-footer></app-footer>
</div>
<!-- <app-footer-helpdesk></app-footer-helpdesk> -->
<app-deal-register-form></app-deal-register-form>

</div>

 <div  class="main-body-auth"  *ngIf="templateAuthView == true">
            <router-outlet (activate)="onActivate($event)"></router-outlet>
            <!--  -->
        </div>


</div>

<!-- <div id="app">
	
</div> -->

