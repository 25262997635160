<div class="container">
    <div class="row">
       <div class="col-md-6 mx-auto mt-5">
          <div class="payment" *ngIf="show_success">
             <div class="payment_header">
                <div class="check"><i class="fa fa-check" aria-hidden="true"></i></div>
             </div>
             <div class="content">
                <h1>Order Placed Successfully !</h1>
                <p>Your Invoice id {{invoice_no}}</p>
                <a class="success-color" routerLink="/dashboard">Go to Home</a>
             </div>
             
          </div>
 
          <div class="payment" *ngIf="show_failed">
             <div class="payment_header_failed">
                <div class="check"><i class="fa fa-times" aria-hidden="true"></i></div>
             </div>
             <div class="content">
                <h1>Order Placed Cancelled !</h1>
                <p style="margin-top: 20px;"></p>
                <a routerLink="/dashboard">Go to Home</a>
             </div>
             
          </div>
       </div>
    </div>
 </div>