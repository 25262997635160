<footer class="main-footer">
<div class="footer-left" style="display: flex;">
<!-- <div class="footer-support-call">
<a href="javascript:;" id="helpdesk-toggle"><img src="assets/img/custom-images/footer-support-icon.png"> Call Us</a>

</div>
<div class="footer-support-calls">

<a style="color:#fff;" (click)="openForm()"><i class="fas fa-qrcode" style="margin-right: 10px;"></i>Get qr</a>
</div> -->

</div>
<!-- <div class="footer-center copyrights pt-2">
Copyright &copy; {{this.year}} <a href="https://cal4care.com/" target="_blank"><b>Cal4care</b></a> <div class="bullet"></div> All Rights Reservered  -->

<!-- <button class="open-button" (click)="openForm()">Chat</button> -->
<!-- </div> -->
<div class="footer-center copyrights pt-2">
  <b>© 2018 AATROX COMMUNICATIONS | All Rights Reserved</b>
</div>

<!-- <div class="footer-right">
<div class="mobile-app-scan">
<a  (click)="openForm2()"><span class="footer-icon">
        <img alt="image" class="" src="assets/img/custom-images/app-store.png">
       
      </span>
    </a>
<a  (click)="openForm2()"><span class="footer-icon">
        <img alt="image" class="" src="assets/img/custom-images/play-store.png">
       
      </span>
    </a>
  </div>
</div> -->
<!-- <div class="footer-support-call"> -->

<!-- 
   <a href="javascript:;" (click)="getandroid()"><span class="footer-icon">
        <img alt="image" class="fitimagefooter" src="assets/img/custom-images/play-store.png">
       
      </span>
    </a>
 <a href="javascript:;" (click)="getios()"><span class="footer-icon">
     <img alt="image"   class="fitimagefooter" src="assets/img/custom-images/app-store.png">
 
   </span>
 </a> -->

<!-- </div> -->


<!-- <div class="footer-right">
 <li class="alignfooter mr-2">
      <a href="javascript:;" (click)="getandroid()"><span class="footer-icon">
        <img alt="image" class="fitimagefooter" src="assets/img/custom-images/play-store.png">
       
      </span>
    </a>
  </li>
  <li class="alignfooter">
   
</li>
</div> -->

<!-- </div> -->

<div class="chat-popup" id="myForm">
  <form  class="form-containers">
<div class="heads">
<div class="row">
  <div class="col-sm-10 col-md-10">
  <h6 class="aligncenter">QR Code</h6>
  </div>
  <div class="col-sm-2 col-md-2">
    <i class="fas fa-times pointer" (click)="closeForm()"></i>
  </div>
  </div>
</div>
<h6 class="popup-inner-title">Scan QR Code To <br />Login App</h6>
<qr-code [value]="qrdatauser" [size]="widths" [level]="level" style="display: flex;justify-content: center;"></qr-code>
  </form>
</div>



<div class="chat-popup2" id="myForm2">
  <form  class="form-containers2">
<div class="heads2">
<div class="row">
  <div class="col-sm-10 col-md-10">
  <h6 class="aligncenter">Scan To Get Apps</h6>
  </div>

 <div class="col-sm-1 col-md-1 close-popup">
    <i class="fas fa-times pointer" style="margin-right: 30px;" (click)="closeForm2()"></i>
  </div>

  <div class="col-sm-1 col-md-1">
    <!-- <i class="fas fa-times pointer" (click)="closeForm()"></i> -->
  </div>
  </div>




</div>
 <ul class="tabs nav nav-tabs">
                    <li [ngClass]=" {'active-tab': tab==1 }" (click)=" tab = 1 " class="width50 aligncenter pointer line50"><span >IOS</span></li>
                    <li [ngClass]=" {'active-tab': tab==2 }" (click)=" tab = 2 " class="width50 aligncenter pointer line50"><span >ANDROID</span></li>
                </ul>
<!-- <h6 style="margin: 30px 18px 30px 20px;">Scan QR Code To Login App</h6> -->
  <div  *ngIf="tab==1">
   <qr-code [value]="qrdata2" [size]="widthios" [level]="level2" style="display: flex;justify-content: center;"></qr-code>
 </div>
    <div  *ngIf="tab==2">
   <qr-code [value]="qrdata" [size]="widthandroid" [level]="level" style="display: flex;justify-content: center;"></qr-code>
 </div>
  </form>
</div>


<!-- <div class="chat-popup" id="myForm">
  <form  class="form-containers">
<div class="heads">
<div class="row">
  <div class="col-sm-10 col-md-10">
  <h6 class="aligncenter">Scan To Get Andriod App</h6>
  </div>
  <div class="col-sm-2 col-md-2">
    <i class="fas fa-times pointer" (click)="closeForm()"></i>
  </div>
  </div>
</div>
<h6 style="margin: 30px 18px 30px 20px;">Scan QR Code To Login App</h6>

   <qr-code [value]="qrdata2" [size]="width" [level]="level" style="display: flex;justify-content: center;"></qr-code>
   
  </form>
</div> -->




</footer>













<!-- <div class="modal fade bd-example-modal-lg" id="androidapp" aria-modal="true" >
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="myLargeModalLabel">Scan To Get Andriod App</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="text-align: center;">
        <div class="row">
          <div class="col-sm-12">
            <div class="card-body" >
              <qr-code [value]="qrdata2" [size]="width" [level]="level" style="display: flex;justify-content: center;"></qr-code>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>

<div class="modal fade bd-example-modal-lg" id="iosapp" aria-modal="true" >
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="myLargeModalLabel">Scan To Get IOS App</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="text-align: center;">
        <div class="row">
          <div class="col-sm-12">
            <div class="card-body" >
             <qr-code [value]="qrdata" [size]="width" [level]="level" style="display: flex;justify-content: center;"></qr-code>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
 -->





<!--   <div class="inner-chat-search-container">
<span class="inner-search-icon"><i class="fas fa-search"></i></span>
<input type="text" name="search">
</div> -->

