import { Component,HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ServerService } from './services/server.service';
declare var $:any;
import Swal from 'sweetalert2'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
    // @HostListener('window:unload', [ '$event' ])
  // unloadHandler(event) {
    // console.log("closed")
    // alert("sdhfj")
  // }
    // @HostListener('window:beforeunload', [ '$event' ])
  // beforeUnloadHandler(event) {
    // console.log("csdjhcfsdjhf")
    // localStorage.clear()
   // return false;
    // alert("sdhfj")
  // }

	  title = 'cms-dev';
	  public file_path: string = "";
	   templateAuthView = false;

     userperms:any=[];
     pn:any=[]
     notifydata:any=[];
      // cookieValue = 'UNKNOWN';
constructor(private router:Router,private cookieService: CookieService,private serverService: ServerService) {
// $(window).bind('beforeunload', function(){
//    localStorage.clear();
//   return 'Are you sure you want to leave?';
 
   
// });
// window.onbeforeunload = function (e) {
//     window.onunload = function () {
//      if(localStorage.getItem('enable') == '0'){
//             window.localStorage.isMySessionActive = "false";
//    localStorage.clear();
// }
//     }
//     return undefined;
// };

// window.onload = function () {
//             window.localStorage.isMySessionActive = "true";
// };
// window.onunload = () => {
//    // Clear the local storage
//    window.localStorage.clear()
// }
// window.addEventListener("beforeunload", function (e) {
//   var confirmationMessage = "\o/";

//   (e || window.event).returnValue = confirmationMessage;
//   alert("ansjdh") //Gecko + IE
//   return confirmationMessage;                            //Webkit, Safari, Chrome
// });

// window.unload = () => {
//   // localStorage.removeItem('isAuth');
//     localStorage.clear()

// }
// var inFormOrLink;
// $('a').live('click', function() { inFormOrLink = true; });
// $('form').bind('submit', function() { inFormOrLink = true; });

// $(window).bind('beforeunload', function(eventObject) {
//     var returnValue = undefined;
//     if (! inFormOrLink) {
//         returnValue = "Do you really want to close?";
//     }
//     eventObject.returnValue = returnValue;
//     return returnValue;
// }); 
 setTimeout (() => {
   
 this.router.navigate(['/logout']);
      }, 1.08e+7);
  }

 ngOnInit(): void {
    // this.cookieService.set( 'Test', 'Hello World' );
    //     this.cookieService.set( 'sdsa', 'Hello World' );
    //     document.cookie = name + "=" + Aes.Ctr.encrypt(value + expires, yourPassword, 256) + "; path=/";
    // this.cookieValue = this.cookieService.get('Test');
  }

//  onActivate(event) {
// this.file_path=this.router.url;
//  if (this.router.url == '/login') {
//    this.templateAuthView = true;
//    localStorage.clear()
//    return false;

//  }

        
//         console.log(this.router.url);
//         if (localStorage.getItem('at')) {
//              // this.router.navigate(['/dashboard']);
//             this.templateAuthView=false;
//             this.permissions()
//             this.notificationlist()
//             }

//               else if(this.router.url == '/forgotpwd'){
//                 this.router.navigate(['/forgotpwd']);
//             this.templateAuthView=true;
                
//             }            
//         else{
         
//             this.router.navigate(['/login']); 
//             this.templateAuthView=true;

//         }

//     }

onActivate(event) {
  this.file_path = this.router.url;
  if (this.router.url == '/login' || this.router.url == '/login_cmsnew' || this.router.url == '/loginapierp') {
      this.templateAuthView = true;
      localStorage.clear();
      return false;
  }
  // console.log(this.router.url);
  if (localStorage.getItem('at')) {
      // this.router.navigate(['/dashboard']);
      this.templateAuthView = false;
      this.aatrox_dashboard();
      // this.permissions();
      // this.notificationlist();
  }
  else if (this.router.url == '/forgotpwd') {
      this.router.navigate(['/forgotpwd']);
      this.templateAuthView = true;
  }
  else if (this.router.url == '/login_cmsnew') {
      this.router.navigate(['/login_cmsnew']);
      this.templateAuthView = true;
  }
  else if (this.router.url == '/loginapierp') {
      this.router.navigate(['/loginapierp']);
      this.templateAuthView = true;
  }
  else {
      this.router.navigate(['/login']);
      this.templateAuthView = true;
  }
}

aatrox_dashboard(){
  let data:any =new Object();
  let datas:any = new Object();
     let access_token: any=localStorage.getItem('at');
     let customer_id: any=localStorage.getItem('en');
  datas.operation = "curlDatas";
  datas.moduleType = "login";
  datas.api_type = "web";
  datas.access_token = access_token;
  data.action = "contact_flag";
  data.customer_id = customer_id;
  data.customer_state = "";
  data.cms_user_id = "";
  datas.element_data = data;
  this.serverService.sendServer(datas).subscribe((res:any)=>{
    if(res.status == "true"){
      localStorage.setItem('currency_symbol',res.currency_symbol);
    }else{
    }
  })
}
// permissions(){




// this.pn =[];
// this.userperms =[];

// // this.serverService.invoice=0;
// // this.serverService.prepaidnote=0;
// // this.serverService.creditnote = 0;
// // this.serverService.license = 0;
// // this.serverService.projects = 0;
// // this.serverService.phones =0;
// // this.serverService.leads =0;
// // this.serverService.reseller =0;
// // this.serverService.callhistory =0;
// // this.serverService.cxbuy =0;
// // this.serverService.iddpricelist =0;
// // this.serverService.call4tellicense =0;
// // this.serverService.supportticket =0;
// // this.serverService.users =0;
// // this.serverService.callbalance =0;
// // this.serverService.users =0;
// // this.serverService.referral =0;
// // this.serverService.licencerenewal =0;
// // this.serverService.dealregistration =0;
// // this.serverService.callhistorypbx ="0";
// // this.serverService.callhistoryvspbx ="0";
// // this.serverService.gccfirewall =0;
// // this.serverService.notifycount =0;
// // this.serverService.callhistoryvspbx ="0";



//   let data:any =new Object();
//  let datasend:any = new Object();
//     let access_token: any=localStorage.getItem('at');
//     let customer_id: any=localStorage.getItem('en');
   
   
// data.action="contact_flag";
// data.customer_id=customer_id;
// data.customer_state= localStorage.getItem('customer_state');
// data.cms_user_id= localStorage.getItem('cms_user_id');

//  datasend.access_token=access_token;
//   datasend.operation="curlDatas";
//   datasend.moduleType="login";
//   datasend.api_type="web";
  
//   datasend.element_data = data;
//       this.serverService.sendServer(datasend).subscribe((response: any) => {
// console.log(response)
//         if(response.status=="true"){


// this.userperms = response.customer_details.cus_permission.split(',');
// this.serverService.callhistorypbx =  response.customer_details.cms_username_dispaly;
// this.serverService.callhistoryvspbx =  response.customer_details.cms_vs_pbx_dispaly;
//     console.log(this.userperms)
//     for (var i = 0; i < this.userperms.length; i++) {

// this.pn.push(btoa(this.userperms[i]))

//     }


// localStorage.setItem('pn',this.pn); 


//      for (var i = 0; i < this.userperms.length; i++) {
    
//       if (this.userperms[i] == '101'){
//           this.serverService.referral = 1;
//         this.serverService.invoice = 1;
//       }
//       if (this.userperms[i] == '102'){
//         this.serverService.prepaidnote = 1;
//          this.serverService.creditnote = 1;   
//       }
//       if (this.userperms[i] == '103')
//         this.serverService.license = 1;
//       if (this.userperms[i] == '104')
//         this.serverService.projects = 1;
//       if (this.userperms[i] == '105')
//         this.serverService.phones = 1;
//       if (this.userperms[i] == '107')
//         this.serverService.leads = 1;
//       if (this.userperms[i] == '131')
//         this.serverService.reseller = 1;
//       if (this.userperms[i] == '109')
//         this.serverService.callhistory = 1;    
//       if (this.userperms[i] == '118')
//         this.serverService.cxbuy = 1;
//       if (this.userperms[i] == '134')
//         this.serverService.iddpricelist = 1;
//       if (this.userperms[i] == '138')
//         this.serverService.call4tellicense = 1;
//       if (this.userperms[i] == '157')
//         this.serverService.supportticket = 1;
//       if (this.userperms[i] == '110')
//         this.serverService.users = 1;
//       if (this.userperms[i] == '136')
//         this.serverService.callbalance = 1;
//       if (this.userperms[i] == '158')
//         this.serverService.licencerenewal = 1;
//       if (this.userperms[i] == '159')
//         this.serverService.dealregistration = 1;
//       if (this.userperms[i] == '160')
//         this.serverService.gccfirewall = 1;
//     }

//    console.log(this.serverService.invoice)
//     console.log(this.serverService.users)
//     console.log(this.serverService.leads)
//     console.log(this.serverService.license)

//           }
//         else{
 
//         }      
//       }, 
//       (error)=>{
//           console.log(error);
//       });

// }


// notificationlist(){

//      // Swal.fire('Loading')
// // Swal.showLoading()
//   let datas:any =new Object();
//  let datasend:any = new Object();
//     let access_token: any=localStorage.getItem('at');
//     let customer_id: any=localStorage.getItem('en');
   
   
// datas.action="notification_list";
// datas.customer_id = customer_id;


//  datasend.access_token=access_token;
//   datasend.operation="curlDatas";
//   datasend.moduleType="notification";
//   datasend.api_type="web";
//   datasend.element_data = datas;
//       this.serverService.sendServer(datasend).subscribe((response: any) => {
// console.log(response)
//         if(response.status=="true"){
//           // Swal.close()
//      this.serverService.notifycount = response.count;
   
// localStorage.setItem('nt', btoa(response.count));

//      this.notifydata = response.list;
//    // for (var i = 0; i < this.notifydata.length; i++) {
//    //    if(this.notifydata[i].type == "referral")
//    //      this.notifydata[i].image = "assets/img/notification/referral.png"
//    //    if(this.notifydata[i].type == "payment_add")
//    //      this.notifydata[i].image ="assets/img/notification/payment_add.png"
//    //    if(this.notifydata[i].type == "renewal")
//    //      this.notifydata[i].image ="assets/img/notification/renewal.png"
//    //    if(this.notifydata[i].type == "invoice")
//    //      this.notifydata[i].image ="assets/img/notification/invoice.png"
//    //    if(this.notifydata[i].type == "payment_made")
//    //      this.notifydata[i].image ="assets/img/notification/payment_made.png"
//    //    if(this.notifydata[i].type == "support")
//    //      this.notifydata[i].image ="assets/img/notification/support.png"
//    // }



//           }
//         else{
//           // Swal.close()
//         this.notifydata =[];
 
//         }

//     }, 
//       (error)=>{
//           console.log(error);
//       });

// }

updatenotify(val){
 
    Swal.fire('Opening')
    Swal.showLoading()
  let data:any =new Object();
 let datasend:any = new Object();
 let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
    
   
    data.action="notification_update";
    data.customer_id=customer_id;
    data.id=val.id;
    
    datasend.access_token=access_token;
    datasend.operation="curlDatas";
    datasend.moduleType="notification";
    datasend.api_type="web";
    datasend.element_data = data;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){
           Swal.close()

    if(val.type == "referral"){
      this.router.navigate(['/referral']);

    }
    else if(val.type == "payment_add"){
      this.router.navigate(['/buy3cx']);
    }
     else if(val.type == "renewal"){
      console.log("rtgrgrthr")
      this.router.navigate(['/renewal']);
    }
     else if(val.type == "invoice"){
      this.router.navigate(['/invoice']);
    }
     else if(val.type == "payment_made"){
      this.router.navigate(['/buy3cx']);
    }
     else if(val.type == "support"){
      this.router.navigate(['/support-ticket']);
    }
    else{
      this.router.navigate(['/dashboard']);
    }
       
          }
        else{
           Swal.close()
        }      
      }, 
      (error)=>{
          console.log(error);
      });

}


}


