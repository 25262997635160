<section class="section">
    <div class="loader" *ngIf="loads"></div> 
   <div class="main-bg">
       <div class="card card-primary"  *ngIf="!sendotps && !forgots">
           <div class="ml-auto mr-auto mb-2 text-center img-section">
             <img src="assets/img/aatrox.jpg" height="85">
           </div>
           <div class="">
               <ul class="tabs nav nav-tabs">
                   <li [ngClass]=" {'active-tab': tab==1 }"  class="width50 aligncenter pointer line50"><span (click)=" tab = 1 ">CMS MOBILE</span></li>
                   <li [ngClass]=" {'active-tab': tab==2 }"  class="width50 aligncenter pointer line50"><span (click)=" tab = 2 ">PASSWORD LOGIN</span></li>
               </ul>
               <div class="">
                   <div class="tab-panel" id="tabPanel2" *ngIf="tab==2" >
                       <div class="card-body">
                           <form method="POST" [formGroup]="loginForm" (ngSubmit)="userLogin()"  class="needs-validation login-form" novalidate="">
                               <div class="form-group">
                                   <label for="user_name">User Name</label>
                                   <input id="user_name" type="user_name" class="form-control" name="user_name" tabindex="1" formControlName="username" required autofocus>
                                   <div class="error-message message-small" *ngIf="!loginForm.get('username').valid && loginForm.get('username').touched"  class="invalid-feedback">
                                       Please enter your email
                                   </div>
                               </div>
                               <div class="form-group">
                                   <div class="float-right">
                                       <a (click)="forgotpass()" class="text-small pointer">Forgot Password?</a>
                                   </div>
                                   <div class="d-block">
                                       <label for="password" class="control-label">Password</label>
                                   </div>
                                   <input id="password" type="password" class="form-control" name="password" tabindex="2" formControlName="password" required>
                                   <div class="error-message message-small" *ngIf="!loginForm.get('password').valid && loginForm.get('password').touched"  class="invalid-feedback">
                                       Please enter your password
                                   </div>
                               </div>
                               <div class="form-group">
                                   <button type="submit" class="btn btn-primary btn-lg btn-block" tabindex="4">
                                     Login
                                   </button>
                               </div>
                           </form>                   
                       </div>
                   </div>
                   <div class="tab-panel" id="tabPanel1" *ngIf="tab==1">
                       <div *ngIf="!expired" >
                           <qr-code [value]="qrdata" [size]="width" [level]="level" style="display: flex;justify-content: center;"></qr-code>
                           <h5 class="aligncenter">SCAN WITH CMS MOBILE</h5>
                           <p class="aligncenter">No more remembering passwords</p>
                       </div>
                       <div *ngIf="expired" class="qr-scanner-section">
                           <img src="assets/img/custom-images/qrimage.png" style="opacity: 0.1;margin: 0 auto;
                           display: inherit;">
                             <button type="button"  class="btn btn-danger btnchn" (click)="subscribes('1')">
                             <i class="fas fa-sync"></i>Refresh QR Code</button>
                             <h6 class="exptext">QR CODE HAS EXPIRED</h6>
                       </div>
                       <!-- <h4 class="designh4">Don't have CMS Mobile ? <span class="pointer clr">Download now!</span></h4> -->
<div class="mobile-app-scan" style="text-align: center;">
<a  href="https://apps.apple.com/us/app/cal4care-cms-customer/id1521608417" target="_blank"><span class="footer-icon">
       <img alt="image" class="" src="assets/img/custom-images/app-store.png" style="height: 36px !important">
      
     </span>
   </a>




<a  href="https://play.google.com/store/apps/details?id=com.erpcustomer" target="_blank"><span class="footer-icon">
       <img alt="image" class="" src="assets/img/custom-images/play-store.png" style="height: 36px !important">
      
     </span>
   </a>
 </div>



                   </div>   
               </div>
          </div>
       </div>
       <div class="card card-primary" *ngIf="sendotps && !forgots">
           <div class="card-header">
               <h4>OTP</h4>
               <div class="text-muted text-center pointer" (click)="backToLogin()">
                 Back to Login?
               </div>
           </div>
           <div class="card-body">
               <div class="ml-auto mr-auto mb-2 text-center">
                   <img src="assets/img/aatrox.jpg" height="85">
               </div>
               <form method="POST" [formGroup]="otpform" (ngSubmit)="otpsubmit()"  class="needs-validation login-form" novalidate="">
                   <div class="form-group">
                       <div class="float-right">
                           <a (click)="userLogins()" class="text-small">Resend OTP?</a>
                       </div>
                       <div class="d-block">
                           <label for="otp" class="control-label">OTP</label>
                       </div>
                       <input id="auth_code" type="auth_code" class="form-control" name="auth_code" tabindex="2" formControlName="auth_code" required>
                       <div class="error-message message-small" *ngIf="!otpform.get('auth_code').valid && otpform.get('auth_code').touched"  class="invalid-feedback">
                         Please enter your OTP
                       </div>
                   </div>
                   <div class="form-group">
                       <button type="submit" class="btn btn-primary btn-lg btn-block" tabindex="4">
                         Submit
                       </button>
                   </div>
               </form>
           </div>
       </div>
       <div class="card card-primary" *ngIf="forgots">
           <div class="card-header">
               <h4>Forgot Password</h4>
               <div class="text-muted text-center pointer" (click)="backToLogin()">
                 Back to Login?
               </div>
           </div>
           <div class="card-body">
               <div class="ml-auto mr-auto mb-2 text-center">
                   <img src="assets/img/aatrox.jpg" height="85">
               </div>
               <form method="POST" [formGroup]="forgotform" (ngSubmit)="forgotsubmit()"  class="needs-validation login-form" novalidate="">
                   <div class="form-group">
                       <div class="d-block">
                           <label for="otp" class="control-label">Email</label>
                       </div>
                       <input id="auth_code" type="auth_code" class="form-control" name="auth_code" tabindex="2" formControlName="forgotemail" required>
                       <div class="error-message message-small" *ngIf="!forgotform.get('forgotemail').valid && forgotform.get('forgotemail').touched"  class="invalid-feedback">
                         Please enter your Email
                       </div>
                   </div>
                   <div class="form-group">
                       <button type="submit" class="btn btn-primary btn-lg btn-block" tabindex="4">
                         Submit
                       </button>
                   </div>
               </form>
           </div>
       </div> 
   </div>
</section>