import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule,FormsModule }  from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { QRCodeModule } from 'angular2-qrcode';
import { CookieService } from 'ngx-cookie-service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenuComponent } from './menu/menu.component';
import { FooterComponent } from './footer/footer.component';
import { FooterHelpdeskComponent } from './footer-helpdesk/footer-helpdesk.component';
import { ProfileDetailsComponent } from './profile-details/profile-details.component';
import { LicenseComponent } from './license/license.component';
import { Buy3cxComponent } from './buy3cx/buy3cx.component';
import { SupportTicketDashboardComponent } from './support-ticket-dashboard/support-ticket-dashboard.component';
import { DealRegisterFormComponent } from './deal-register-form/deal-register-form.component';
import { LoginComponent } from './login/login.component';
import { ForgotPwdComponent } from './forgot-pwd/forgot-pwd.component';
import { ShoppingCartComponent } from './shopping-cart/shopping-cart.component';
import { CmsUsernewComponent } from './cms-usernew/cms-usernew.component';
import { ExtensionsComponent } from './extensions/extensions.component';
import { NewextensionComponent } from './newextension/newextension.component';
import { EditorModule } from '@progress/kendo-angular-editor';
import * as GoQrcode from "go-qrcode";
import { DragDropDirective } from './drag-drop.directive';
import { ReadmoreComponent } from './readmore/readmore.component';
import {RoundProgressModule} from 'angular-svg-round-progressbar';
import { ShoppingcartdetailsComponent } from './shoppingcartdetails/shoppingcartdetails.component';
import { NotificationComponent } from './notification/notification.component';
import { CostcenterComponent } from './costcenter/costcenter.component';
import { CostcentereditComponent } from './costcenteredit/costcenteredit.component';
import { PaysuccessComponent } from './paysuccess/paysuccess.component';
// import {Pipe,PipeTransform} from "@angular/core";
// import { SafePipeModule } from 'safe-pipe';

import { CheckpageComponent } from './checkpage/checkpage.component';
import { LogincmsComponent } from './logincms/logincms.component';
import { OcbcSuccessComponent } from './ocbc-success/ocbc-success.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatChipsModule} from '@angular/material/chips';
import {MatInputModule} from '@angular/material/input';
import { MatFormFieldModule } from "@angular/material/form-field";
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import { CreditsPaymentComponent } from './credits-payment/credits-payment.component';
import { PaysuccessTopupComponent } from './paysuccess-topup/paysuccess-topup.component';
import { ReferralComponent } from './referral/referral.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { DashboardComponent } from './dashboard/dashboard.component';
// import  {  PdfViewerModule  }  from  'ng2-pdf-viewer';
@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    FooterComponent,
    FooterHelpdeskComponent,
    ProfileDetailsComponent,
    LicenseComponent,
    Buy3cxComponent,
    SupportTicketDashboardComponent,
    DealRegisterFormComponent,
    LoginComponent,
    ForgotPwdComponent,
    ShoppingCartComponent,
    CmsUsernewComponent,
    ExtensionsComponent,
    NewextensionComponent,
    DragDropDirective,
    ReadmoreComponent,
    ShoppingcartdetailsComponent,
    NotificationComponent,
    CostcenterComponent,
    CostcentereditComponent,
    PaysuccessComponent,
    CheckpageComponent,
    LogincmsComponent,
    OcbcSuccessComponent,
    CreditsPaymentComponent,
    PaysuccessTopupComponent,
    ReferralComponent,
    InvoiceComponent,
    DashboardComponent,
       // SafePipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
     ReactiveFormsModule,
     HttpClientModule,
    FormsModule,
    QRCodeModule,
    EditorModule,
    RoundProgressModule,
    BrowserAnimationsModule,
    MatChipsModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    MatIconModule
    // SafePipe
    // SafePipeModule ,
      // PdfViewerModule
  ],
  providers: [CookieService],
  bootstrap: [AppComponent]
})
export class AppModule { }
