<nav class="navbar navbar-expand-lg main-navbar sticky">
	<div class="loader" *ngIf="loads"></div>

	<div class="form-inline mr-auto">
		<ul class="navbar-nav mr-3">
			<li><a href="javascript:void(0)" data-toggle="sidebar" class="nav-link nav-link-lg
collapse-btn" (click)="fullScreenBtn()"> <i data-feather="menu"></i></a></li>
			<li><a href="javascript:void(0)" class="nav-link nav-link-lg fullscreen-btn">
					<i data-feather="maximize"></i>
				</a></li>
		</ul>
	</div>
	<ul class="navbar-nav navbar-right header-btn-group btn-group">

		<li class="dropdown dropdown-list-toggle">
			<a href="#" data-toggle="dropdown" class="nav-link  btn light-green-bg-clr btn-icon icon-left">
				<i class="far fa-money-bill-alt"></i> Currency ({{this.currency_symbol}})</a>
			<div class="dropdown-menu dropdown-list dropdown-menu-right pullDown">
				<div class="dropdown-header">
					<!----------------------------
Currency Table
--------------------------->
					<table cellpadding="2" cellspacing="1" border="0" class="tablestyle" bgcolor="#000099"
						style="font-size:10px;">
						<tbody>
							<tr>
								<td class="pricing-table-header" colspan="8" align="center">Currency Price on
									{{curdate}} </td>
							</tr>
							<tr>
								<td class="pricing-table-title"></td>
								<td class="pricing-table-title">SGD</td>
								<td class="pricing-table-title">USD</td>
								<td class="pricing-table-title">INR</td>
								<td class="pricing-table-title">MYR</td>
								<td class="pricing-table-title">EUR</td>
								<td class="pricing-table-title">JPY</td>
								<td class="pricing-table-title">THB</td>
							</tr>
							<tr>
								<td class="pricing-table-title">SGD</td>
								<td class="pricing-table-row" *ngIf="cur.SGD">{{cur.SGD.SGD}}</td>
								<td class="pricing-table-row" *ngIf="cur.SGD">{{cur.SGD.USD}}</td>
								<td class="pricing-table-row" *ngIf="cur.SGD">{{cur.SGD.INR}}</td>
								<td class="pricing-table-row" *ngIf="cur.SGD">{{cur.SGD.MYR}}</td>
								<td class="pricing-table-row" *ngIf="cur.SGD">{{cur.SGD.EUR}}</td>
								<td class="pricing-table-row" *ngIf="cur.SGD">{{cur.SGD.JPY}}</td>
								<td class="pricing-table-row" *ngIf="cur.SGD">{{cur.SGD.THB}}</td>
							</tr>
							<tr>
								<td class="pricing-table-title">USD</td>
								<td class="pricing-table-row" *ngIf="cur.USD">{{cur.USD.SGD}}</td>
								<td class="pricing-table-row" *ngIf="cur.USD">{{cur.USD.USD}}</td>
								<td class="pricing-table-row" *ngIf="cur.USD">{{cur.USD.INR}}</td>
								<td class="pricing-table-row" *ngIf="cur.USD">{{cur.USD.MYR}}</td>
								<td class="pricing-table-row" *ngIf="cur.USD">{{cur.USD.EUR}}</td>
								<td class="pricing-table-row" *ngIf="cur.USD">{{cur.USD.JPY}}</td>
								<td class="pricing-table-row" *ngIf="cur.USD">{{cur.USD.THB}}</td>
							</tr>
							<tr>
								<td class="pricing-table-title">INR</td>
								<td class="pricing-table-row" *ngIf="cur.INR">{{cur.INR.SGD}}</td>
								<td class="pricing-table-row" *ngIf="cur.INR">{{cur.INR.USD}}</td>
								<td class="pricing-table-row" *ngIf="cur.INR">{{cur.INR.INR}}</td>
								<td class="pricing-table-row" *ngIf="cur.INR">{{cur.INR.MYR}}</td>
								<td class="pricing-table-row" *ngIf="cur.INR">{{cur.INR.EUR}}</td>
								<td class="pricing-table-row" *ngIf="cur.INR">{{cur.INR.JPY}}</td>
								<td class="pricing-table-row" *ngIf="cur.INR">{{cur.INR.THB}}</td>
							</tr>
							<tr>
								<td class="pricing-table-title">MYR</td>
								<td class="pricing-table-row" *ngIf="cur.MYR">{{cur.MYR.SGD}}</td>
								<td class="pricing-table-row" *ngIf="cur.MYR">{{cur.MYR.USD}}</td>
								<td class="pricing-table-row" *ngIf="cur.MYR">{{cur.MYR.INR}}</td>
								<td class="pricing-table-row" *ngIf="cur.MYR">{{cur.MYR.MYR}}</td>
								<td class="pricing-table-row" *ngIf="cur.MYR">{{cur.MYR.EUR}}</td>
								<td class="pricing-table-row" *ngIf="cur.MYR">{{cur.MYR.JPY}}</td>
								<td class="pricing-table-row" *ngIf="cur.MYR">{{cur.MYR.THB}}</td>
							</tr>
							<tr>
								<td class="pricing-table-title">EUR</td>
								<td class="pricing-table-row" *ngIf="cur.EUR">{{cur.EUR.SGD}}</td>
								<td class="pricing-table-row" *ngIf="cur.EUR">{{cur.EUR.USD}}</td>
								<td class="pricing-table-row" *ngIf="cur.EUR">{{cur.EUR.INR}}</td>
								<td class="pricing-table-row" *ngIf="cur.EUR">{{cur.EUR.MYR}}</td>
								<td class="pricing-table-row" *ngIf="cur.EUR">{{cur.EUR.EUR}}</td>
								<td class="pricing-table-row" *ngIf="cur.EUR">{{cur.EUR.JPY}}</td>
								<td class="pricing-table-row" *ngIf="cur.EUR">{{cur.EUR.THB}}</td>
							</tr>
							<tr>
								<td class="pricing-table-title">JPY</td>
								<td class="pricing-table-row" *ngIf="cur.JPY">{{cur.JPY.SGD}}</td>
								<td class="pricing-table-row" *ngIf="cur.JPY">{{cur.JPY.USD}}</td>
								<td class="pricing-table-row" *ngIf="cur.JPY">{{cur.JPY.INR}}</td>
								<td class="pricing-table-row" *ngIf="cur.JPY">{{cur.JPY.MYR}}</td>
								<td class="pricing-table-row" *ngIf="cur.JPY">{{cur.JPY.EUR}}</td>
								<td class="pricing-table-row" *ngIf="cur.JPY">{{cur.JPY.JPY}}</td>
								<td class="pricing-table-row" *ngIf="cur.JPY">{{cur.JPY.THB}}</td>
							</tr>
							<tr>
								<td class="pricing-table-title">THB</td>
								<td class="pricing-table-row" *ngIf="cur.THB">{{cur.THB.SGD}}</td>
								<td class="pricing-table-row" *ngIf="cur.THB">{{cur.THB.USD}}</td>
								<td class="pricing-table-row" *ngIf="cur.THB">{{cur.THB.INR}}</td>
								<td class="pricing-table-row" *ngIf="cur.THB">{{cur.THB.MYR}}</td>
								<td class="pricing-table-row" *ngIf="cur.THB">{{cur.THB.EUR}}</td>
								<td class="pricing-table-row" *ngIf="cur.THB">{{cur.THB.JPY}}</td>
								<td class="pricing-table-row" *ngIf="cur.THB">{{cur.THB.THB}}</td>
							</tr>
						</tbody>
					</table>
					<!----------------------------
Currency Table End
--------------------------->
				</div>
			</div>
		</li>

		<li class="dropdown dropdown-list-toggle">
			<a href="#" data-toggle="dropdown" class="nav-link  btn btn-success btn-icon icon-left"
				*ngIf='countryflagdata != ""'>
				<img src="{{countryflagdata.primary_data.flag_data}}"> {{countryflagdata.primary_data.country}}:
				{{countryflagdata.primary_data.phone_number}}</a>
			<div class="dropdown-menu dropdown-list dropdown-menu-right pullDown">
				<div class="dropdown-header">
					<!----------------------------
Country Contact Table
--------------------------->
					<div class="row" *ngFor="let l of countryflagdata.secondary_data">
						<div class="col-12">
							<div class="card mb-2 no-shadow country-contact-details">
								<div class="card-body no-padding text-center">
									<ul class="country-contact-list">
										<li><img src="{{l.flag_data}}"></li>
										<li class="country-list-name">{{l.country}}</li>
										<li><a href="tel:+60327123106">{{l.phone_number}}</a></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<!----------------------------
Country Contact Table End
--------------------------->
				</div>
			</div>
		</li>

		<li class="dropdown dropdown-list-toggle">
			<a href="#" data-toggle="dropdown" class="nav-link  btn btn-success btn-icon icon-left"><i
					class="far fa-envelope"></i> AATROX COMMUNICATION</a>
			<!-- <a href="#" data-toggle="dropdown"
class="nav-link  btn btn-success btn-icon icon-left"><i class="far fa-envelope"></i>  Cal4Care SG</a> -->
			<div class="dropdown-menu dropdown-list dropdown-menu-right pullDown">
				<div class="dropdown-header">
					<!----------------------------
Contact Table
--------------------------->
					<div class="row">

						<div class="col-12">
							<div class="card mb-2 no-shadow country-contact-details">
								<div class="card-body no-padding text-center">
									<ul class="country-contact-list">
										<li><img src="assets/img/custom-images/mail-icon.svg"></li>
										<li class="country-list-name"><a
												href="mailto:sales@aatroxcommunications.com.au">sales@aatroxcommunications.com.au</a>
										</li>
									</ul>
								</div>
							</div>
						</div>

						<!-- <div class="col-12">
<div class="card mb-2 no-shadow country-contact-details">
<div class="card-body no-padding text-center">
<ul class="country-contact-list">
<li><img src="assets/img/custom-images/faq-icon.svg"></li>
<li class="country-list-name"><a href="https://www.helpdesk.guru/" target="_blank">Help Desk</a></li>




</ul>
</div>
</div>
</div> -->

					</div>
					<!----------------------------
Contact Table End
--------------------------->
				</div>
			</div>
		</li>
		<li>
			<!-- data-toggle="modal" data-target=".dealRegisterModal"  -->
			<!-- <a  (click)="gooldcms()" style="background-color: #2a3f54;" class="btn  btn-icon icon-left">
<i class="fab fa-superpowers"></i> Switch Old CMS
</a> -->


		</li>



	</ul>

</nav>

<!-----------------------------
Sidebar Menu
------------------------------>
<div class="main-sidebar sidebar-style-2">
	<aside id="sidebar-wrapper">
		<div class="sidebar-user sidebar-logo-section">
			<div class="sidebar-user-picture">
				<img alt="image" src="assets/img/aatrox.jpg">
			</div>
			<!-- <div class="sidebar-user-details">
<div class="user-name">{{serverService.cn}}</div>
<div class="user-role">Administrator</div>
<div class="sidebar-userpic-btn">
<a routerLink="/profile-details" data-toggle="tooltip" title="Profile">
<i data-feather="user"></i>
</a>
<a routerLink="/support-ticket-dashboard" data-toggle="tooltip" title="Support Ticket">
<i data-feather="headphones"></i>
</a>
<a routerLink="/deal-registration" data-toggle="tooltip" title="Deal Registration Details">
<i data-feather="thumbs-up"></i>
</a>
<a routerLink="/logout"     data-toggle="tooltip" title="Log Out">
<i data-feather="log-out"></i>
</a>
</div>
</div> -->
		</div>

		<div class="header-action">
			<ul>
				<li>
					<a routerLink="/profile-details" data-toggle="tooltip" title="Profile" class="clrbk">
						<i data-feather="user"></i>
					</a>
				</li>
				<li>
					<a routerLink="/logout" data-toggle="tooltip" title="Log Out" class="clrbk">
						<i data-feather="log-out"></i>
					</a>
				</li>
			</ul>
		</div>

		<ul class="sidebar-menu">
			<div class="sidebar-user" style="padding: 0px !important">
				<div class="sidebar-user-details">
					<div *ngIf="this.profile!='' && this.profile!='null' && this.profile!=null" class="menu_profile">
						<div class="profile_menu">
							<img alt="image" class="new" src="{{this.profile}}" />
						</div>
					</div>
					<div *ngIf="this.profile=='' || this.profile=='null' || this.profile==null" class="menu_profile">
						<div class="profile_menu">
							<img alt="image" class="new"
								src="https://erp.cal4care.com/cms_new/assets/images/user.jpg" />
						</div>
					</div>
					<div class="user-name clrwhite">{{serverService.cn}}</div>
					<div class="user-role clrwhite">Administrator</div>

					<!-- <div class="sidebar-userpic-btn" style="padding:4px 10px !important;background-color: #fff">
<a routerLink="/profile-details" data-toggle="tooltip" title="Profile" class="clrbk">
<i data-feather="user"></i>
</a>
<a routerLink="/support-ticket-dashboard" data-toggle="tooltip" title="Support Ticket" class="clrbk">
<i data-feather="headphones"></i>
</a>
<a routerLink="/deal-registration" data-toggle="tooltip" title="Deal Registration Details" class="clrbk">
<i data-feather="thumbs-up"></i>
</a>
<a routerLink="/logout"   data-toggle="tooltip" title="Log Out" class="clrbk">
<i data-feather="log-out"></i>
</a>
</div> -->

				</div>
			</div>
			<li class="menu-header">Main</li>

			<li routerLinkActive="active">
				<a class="nav-link" routerLink="/dashboard"><i class="fa fa-home"></i> <span>Dashboard</span></a>
			</li>
			<li routerLinkActive="active">
				<a class="nav-link" routerLink="/referal"><i class="fa fa-certificate"></i> <span>Referaal</span></a>
			</li>

			<li class="menu-header">Apps</li>




			<li routerLinkActive="active" *ngIf="serverService.invoice == 1">
				<a class="nav-link" routerLink="/invoice"><i class="fa fa-sticky-note"></i> <span>Invoice</span></a>
			</li>

			<li routerLinkActive="active" *ngIf="serverService.license == 1">
				<a class="nav-link" routerLink="/license"><i class="fa fa-certificate"></i> <span>Licence</span></a>
			</li>


			<li routerLinkActive="active" *ngIf="serverService.cxbuy == 1">
				<a class="nav-link" routerLink="/buy3cx"><i class="fa fa-shopping-cart"></i> <span>Buy 3CX</span></a>
			</li>



			<li>
				<a class="nav-link" routerLink="/logout"><i class="fas fa-sign-out-alt"></i> <span>Logout</span></a>
			</li>

		</ul>
	</aside>
</div>





<div class="full-width-modal" id="full-width-modal">

	<div class="modal fade in" role="dialog" id="notifymodal">
		<div class="modal-dialog">


			<div class="modal-content">


				<div class="modal-header">
					<button type="button" class="close" data-dismiss="modal">×</button>
					<h4 class="modal-title"><span id="modal_header_id">Notification</span></h4>
				</div>
				<div class="modal-body">
					<span id="modal_content_id">
						<style>
							.other-service-single img {
								max-width: 160px;
								overflow: hidden;
								max-height: 105px;
								width: 100%;
								height: 100%;
								transform: scaleX(1);
								transition: all 1s;
								object-fit: contain;
								float: left;
							}
						</style>
					</span>

					<div class="other-service white-bg">
						<div class="">

							<div class="col-md-12 col-xs-12" *ngFor="let l of notifydata;let i = index;">
								<div class="other-service-single-item">
									<h4><a target="_blank" style="text-transform: capitalize;"><img src="{{l.image}}"
												onerror="this.src='assets/img/notification/invoice.png'" width="35"
												style="margin-right: 10px !important">{{l.type}}</a></h4>
									<div class="other-service-single text-left">
										<!-- <figure>
							<img src="{{l.image}}">
						</figure> -->
										<div class="other-service-description">
											<div>{{l.message}}

											</div>

										</div>
										<span class="read-more-btn pointer"><img src='assets/img/notification/read.jpg'
												style="width: 90%" (click)="read(l)"></span>

										<!-- <div style="float:left"> <p><strong>Posted on :</strong>{{l.created_dt}}</p></div> -->
									</div>
								</div>


							</div>


						</div>
					</div>
				</div>

			</div>
		</div>
	</div>


</div>



<!-----------------------------
Sidebar Menu End
------------------------------->