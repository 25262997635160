<section class="section">
	<!-- <div class="loader"></div> -->
<ul class="breadcrumb breadcrumb-style ">
<li class="breadcrumb-item">
<h4 class="page-title m-b-0">Dashboard</h4>
</li>
<li class="breadcrumb-item">
<a routerLink="/dashboard">
<i data-feather="home"></i></a>
</li>
<li class="breadcrumb-item active">Dashboard</li>
</ul>
<div class="section-body">

<!------------licence count------------>
<div style="display: flex;">
<div class="col-xl-2 col-lg-4 col-sm-6 col-12">
	<div class="card clr-card yellow-bg-clr card-head" routerLink="/referal">
	<div class="card-statistic card-display">
	
	<div class="card-header-details fl">
	<h6 class="m-b-5 text-left text">Referral</h6>
	<h4 class="text-left"><span></span>
	</h4>
	</div>
	<div class="banner-img">
		<img src="assets/img/custom-images/dashboard/referral-icon.svg" alt="Referral">
		</div>
	</div>
	</div>
	</div>
<div class="col-xl-2 col-lg-4 col-sm-6 col-12">
	<div class="card clr-card ping-bg-clr card-head" routerLink="/invoice">
	<div class="card-statistic card-display">
	
	<div class="card-header-details fl">
	<h6 class="m-b-5 text-left text">Invoice</h6>
	<h4 class="text-left" *ngIf="this.invoice_amount != '0.00'"><span >{{this.invoice_amount}}</span>
	</h4>
	<h4 class="text-left" *ngIf="this.invoice_amount == '0.00'"><span >0</span>
	</h4>
	</div>
	<div class="banner-img">
		<img src="assets/img/custom-images/dashboard/invoice-icon.svg" alt="Invoice">
		</div>
	</div>
	</div>
	</div>
<div class="col-xl-2 col-lg-4 col-sm-6 col-12">
	<div class="card clr-card vio-clr-bg card-head" routerLink="/license">
	<div class="card-statistic card-display">
	
	<div class="card-header-details fl">
	<h6 class="m-b-5 text-left text">Licence</h6>
	<h4 class="text-left"><span class="liclr text">{{this.license_count}}</span>
	</h4>
	</div>
	<div class="banner-img">
		<img src="assets/img/custom-images/dashboard/license.svg" alt="Licence">
		</div>
	</div>
	</div>
	</div>
	</div>
<!------------------------------------->
<!-- Referral -->
<div class="row">
<div class="col-md-12 col-lg-12 col-xl-12">
<div class="card card-success">
<div class="card-header">
<h4>Referral Amount Details in ({{this.currency}})</h4>
<div class="card-header-action">
<a  data-toggle="collapse" class="btn btn-icon btn-success" href="#referralAmount-collapse">
<i class="fas fa-minus"></i></a>
</div>
</div>
<div class="collapse show" id="referralAmount-collapse">
<div class="card-body">
<div class="tableBody" id="client-details">
<div class="table-responsive scrollhide" >
<table class="table table-hover grad-table green-grad">
<thead>
<tr class="tablebkgr" >
<th *ngFor="let ref of ref_data;" style="border-right: 1px solid #f5f5f5">{{ref.year}} <br />{{ref.month}}</th>
</tr>
</thead>
<tbody>
<tr >
<td *ngFor="let ref of ref_data;" style="border-right: 1px solid #f5f5f5"><b>{{ref.amount}}</b></td>
</tr>
</tbody>
</table>
</div>
</div>
</div>
</div>
</div>
</div>

<!-- <div class="col-md-12 col-lg-12 col-xl-12">
<div class="card card-purple">
<div class="card-header">
<h4>Payout Details in () - Unpaid Amount-</h4>
<div class="card-header-action">
<a  data-toggle="collapse" class="btn btn-icon btn-danger" href="#payoutDetails-collapse">
<i class="fas fa-minus"></i></a>
</div>
</div>
<div class="collapse show" id="payoutDetails-collapse">
<div class="card-body">
<div class="tableBody">
<div class="table-responsive scrollhide">
<table class="table table-hover grad-table red-grad">
<thead>
<tr class="tablebkred">
<th style="border-right: 1px solid #f5f5f5"> <br /></th>
</tr>
</thead>
<tbody>
<tr>
<td   style="border-right: 1px solid #f5f5f5"><b></b></td>
</tr>
</tbody>
</table>
</div>
</div>
</div>
</div>
</div>
</div> -->


<!-- <div class="col-md-6 col-lg-6 col-xl-6">
<div class="card card-success">
<div class="card-header">
<h4>Reseller Dashboard</h4>
<div class="card-header-action">
<a  data-toggle="collapse" class="btn btn-icon btn-success" href="#def_Currcollapse">
<i class="fas fa-minus"></i></a>
</div>
</div>
<div class="collapse show" id="def_Currcollapse">
<div class="card-body">
<div class="tableBody" id="client-details">
<div class="table-responsive scrollhide" >
<table class="table table-hover grad-table ">
<thead>
<tr class="tablebkgr" >
<th style="border: 1px solid #fff;background: #76d777">Currency</th>
<th style="border: 1px solid #fff;background: #76d777">Paid</th>
<th style="border: 1px solid #fff;background: #ff7b73">Unpaid</th>
<th style="border: 1px solid #fff;background: #ff7b73">Def_Curr</th>

</tr>
</thead>
<tbody>
<tr >
<td   style="border: 1px solid #fff" [style.background]="" ></td>
<td   style="border: 1px solid #fff;background:#8bdb9f6b;"></td>
<td   style="border: 1px solid #fff;background:#ff97917d;"></td>
<td   style="border: 1px solid #fff;background:#ff97917d;"></td>

</tr>
</tbody>
</table>






</div>
</div>
</div>
</div>
</div>
</div> -->




<!-- <div class="col-md-6 col-lg-6 col-xl-6">
<div class="card card-success">
<div class="card-header">
<h4>Year</h4>
<div class="card-header-action">
<a  data-toggle="collapse" class="btn btn-icon btn-success" href="#yearcollapse">
<i class="fas fa-minus"></i></a>
</div>
</div>
<div class="collapse show" id="yearcollapse">
<div class="card-body">
<div class="tableBody" id="client-details">
<div class="table-responsive scrollhide" >



		<table class="table table-hover grad-table ">

<thead>
<tr class="tablebkgr" >
<th style="border: 1px solid #fff;background: #76d777">Year</th>
<th style="border: 1px solid #fff;background: #76d777">Paid</th>
<th style="border: 1px solid #fff;background: #ff7b73">Unpaid</th>


</tr>
</thead>
<tbody>
<tr >
<td   style="border: 1px solid #fff;" [style.background]="" ></td>
<td   style="border: 1px solid #fff;background:#8bdb9f6b;"></td>
<td   style="border: 1px solid #fff;background:#ff97917d;"></td>

</tr>
</tbody>
</table>




</div>
</div>
</div>
</div>
</div>
</div> -->













<!-- <div class="col-12 col-sm-12 col-lg-12">
<div class="card card-primary">
<div class="card-header">
<h4>Bank Details</h4>
<div class="card-header-action">
<a data-toggle="collapse" class="btn btn-icon btn-info" href="#bankDetails-collapse">
<i class="fas fa-minus"></i></a>
</div>
</div>
<div class="collapse show" id="bankDetails-collapse">
<div class="card-body">
<div class="page-content custom-tab note-has-grid">
<ul class="nav nav-pills p-3 mb-3 rounded-pill align-items-center">
<li class="nav-item">
<a   [ngClass]=" {'activetab':selectbank == 'loc'}" (click)="selctedbnk('loc')"
class="tabStartClick nav-link rounded-pill note-link d-flex align-items-center px-2 px-md-3 mr-0 mr-md-2 pointer"
id="note-local-bank"> <i class="fas fa-piggy-bank"></i><span class="d-md-block">Local Bank</span></a>
</li>
<li class="nav-item" >
<a  [ngClass]=" {'activetab':selectbank == 'inter'}" (click)="selctedbnk('inter')"
class="nav-link rounded-pill note-link d-flex align-items-center px-2 px-md-3 mr-0 mr-md-2 pointer"
id="note-international-bank"> <i class="fas fa-dollar-sign"></i><span class="d-md-block">International</span></a>
</li>
<li class="nav-item" >
<a  [ngClass]=" {'activetab':selectbank == 'paypal'}" (click)="selctedbnk('paypal')"
class="nav-link rounded-pill note-link d-flex align-items-center px-2 px-md-3 mr-0 mr-md-2 pointer"
id="note-paypal"> <i class="fab fa-paypal"></i><span class="d-md-block">Paypal</span></a>
</li>
</ul>
<div class="tab-content bg-transparent">
<div id="note-full-container" class="note-has-grid row">

<div class="col-md-12 single-note-item note-local-bank" *ngIf="selectbank == 'loc'">
<div class="note-card">
<div class="note-content">
<div class="card">
<div class="card-header">
<h4>Local Bank</h4>
</div>
<div class="card-body">
<div class="table-responsive">
<table class="table success-color-table color-table table-striped table-hover">
<thead>
<tr>
<th>S.No</th>
<th>Bank Name</th>
<th>Bank Code</th>
<th>Branch Code</th>
<th>Account Number</th>
<th>Name</th>
</tr>
</thead>
<tbody>
<tr *ngFor="let l of locals;let i = index;">
<td>{{i+1}}</td>
<td>{{l.loc_bank_name}}</td>
<td>{{l.loc_bank_code}}</td>
<td>{{l.loc_bank_branch_code}}</td>
<td>{{l.loc_acc_number}}</td>
<td>{{l.loc_payee_name}}</td>
<td class="action-btn-group">
</td>
</tr>
</tbody>
</table>
</div>
</div>
</div>
</div>
</div>
</div>
<div class="col-md-12 single-note-item note-international-bank" *ngIf="selectbank == 'inter'"> 
<div class="note-card">
<div class="note-content">
<div class="card">
<div class="card-header">
<h4>International</h4>
</div>
<div class="card-body">
<div class="table-responsive">
<table class="table primary-color-table color-table table-striped table-hover">
<thead>
<tr>
<th>S.No</th>
<th>Shift Code</th>
<th>Bank Name</th>
<th>Bank Address</th>
<th>Account Number</th>
<th>Name</th>
<th>Address</th>
</tr>
</thead>
<tbody>
<tr>
<td>1</td>
<td>9042343</td>
<td>HDFC Bank</td>
<td>Aljunied Street</td>
<td>28520022020</td>
<td>TwoaPlus</td>
<td>Novelty Techpoint, Singapore 536212</td>
</tr>
</tbody>
</table>
</div>
</div>
</div>
</div>
</div>
</div>
<div class="col-md-12 single-note-item note-paypal"  *ngIf="selectbank == 'paypal'">
<div class="note-card">
<div class="note-content">
<div class="card">
<div class="card-header">
<h4>Paypal</h4>
</div>
<div class="card-body">
<div class="table-responsive">
<table class="table ping-color-table color-table table-striped table-hover">
<thead>
<tr>
<th>S.No</th>
<th>Paypal Email</th>
</tr>
</thead>
<tbody>
<tr *ngFor="let l of paypal;let i = index;">
<td>{{i+1}}</td>
<td>{{l.paypal_email}}</td>
</tr>
</tbody>
</table>
</div>
</div>
</div>
</div>
</div>
</div>

</div>
</div>
</div>
</div>
</div>
</div>
</div> -->
</div>
</div>
</section>